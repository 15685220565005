import { getQuery } from "./get_query";

export class DtiQuery {
  static summary = `query {
  summary {
    version
    target
    target_cleaned
    license_info {
      name
      api
      scans_max
      full_mode
      binary_edge_ip_score
      id
      user_id
      user_role
      scans_done
      expires_on
    }
    email_total
    domain_specific_emails
    other_emails
    breach_source_total
    breach_total
    breach_chart { name total }
    breach_chart_risk { breach_total_high breach_total_medium breach_total_low }
    cve_total
    cve_unique_total
    cve_high_total
    cve_medium_total
    cve_low_total
    cve_high_unique_total
    cve_medium_unique_total
    cve_low_unique_total
    cve_counter { critical high medium low }
    cve_unique_counter { critical high medium low }
    impact2 { availability_total confidentiality_total integrity_total availability_unique_total confidentiality_unique_total integrity_unique_total }
    impact3 { availability_total confidentiality_total integrity_total availability_unique_total confidentiality_unique_total integrity_unique_total }
    impact_overall { availability_total confidentiality_total integrity_total availability_unique_total confidentiality_unique_total integrity_unique_total }
    impact_availability_total
    impact_confidentiality_total
    impact_integrity_total
    impact_availability_unique_total
    impact_confidentiality_unique_total
    impact_integrity_unique_total
    impact3_availability_total
    impact3_confidentiality_total
    impact3_integrity_total
    impact3_availability_unique_total
    impact3_confidentiality_unique_total
    impact3_integrity_unique_total
    total_ip_found
    total_subdomain_found
    ransomware_attack_index
    epss
  }
}`;

  static breaches(first = 20, skip = 0, { sort_by = "time", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              breach_data {
                items(${query}) {
                  source {
                    data
                    date
                    description
                    features
                    fullname
                    logo
                    name
                    year
                  }
                  total
                  risk
                }
                total
              }
            }`;
  }

  // DENTRO VULNERABILITIES DETAILS ABBIAMO IL CAMPO NUOVO EPSS (a livello di exploit_impact3)
  static services(first = 20, skip = 0, { sort_by = "time", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              ip_data {
                items(${query}) {
                  ip
                  vulnerabilities_list_count
                  subdomains
                  ports_list
                  vulnerabilities_list
                  cpe_list
                  ports_details
                  vulnerabilities_details
                  data {
                    region_code
                    tags
                    isp
                    area_code
                    domains
                    hostnames
                    postal_code
                    dma_code
                    country_code
                    org
                    asn
                    city
                    latitude
                    longitude
                    isp
                    last_update
                    country_code3
                    country_name
                    ip_str
                    os
                  }
                }
                total
              }
            }`;
  }

  static subdomains(first = 20, skip = 0, { sort_by = "time", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              subdomain_data {
                items(${query}) {
                  ip_list
                  subdomain
                  technologies {
                      app
                      ver
                      type
                  }
                }
                total
              }
            }`;
  }

  static typosquatting(first = 20, skip = 0, { sort_by = "time", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              typosquatting {
                items(${query}) {
                  data
                  dns_a
                  dns_mx
                  dns_ns
                  domain
                  similarity
                  domain_unicode
                }
                total
              }
            }`;
  }

  static cve(cve_query = "cve_ordered_high", first = 20, skip = 0, { sort_by = "time", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              ${cve_query} {
                items(${query}) {
                  cve cvss ip
                }
                total
              }
            }`;
  }

  static cveDetals(first = 20, skip = 0, { sort_by = "cvss", order = -1, search = null, sorting = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search, sorting: sorting })
    return `query {
      cve_details {
        items(${query}) {
          id
          summary
          cve
          cvss_time
          last_modified
          cvss
          cvss2
          cvss3
          cwes
          rating
          impact { availability confidentiality integrity }
          impact2 { availability confidentiality integrity }
          impact3 { availability confidentiality integrity }
          impactScore
          exploitabilityScore
          cvss_vector
          ip
        }
        total
      }
    }`;
  }
}
