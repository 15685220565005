import { ENV_VARS } from "@/env_vars";
import { BaseService } from "./BaseService";
import { Routes } from "./RouterService/Routes";

export class TargetService extends BaseService {
  constructor() {
    super();
    this.BASE_URL = ENV_VARS.VUE_APP_BACKEND_BASE_URL;
    this.BASE_API = Routes.TARGETS;
  }
}

/**
 * @api {get} https://backend.platform.swascan.com/profiles/targets/ Get Targets list
 * @apiVersion 1.0.0
 * @apiName TargetsList
 * @apiGroup Targets
 *
 * @apiSampleRequest https://backend.beta.swascan.com/profiles/targets/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} [engine]      Optional api filter (example: engine=dti/1.0)
 * @apiParam {Number} [page]        page
 * @apiParam {Number} [page_size]   Number of records to return
 *
 * @apiSuccess {Number} count       Total targets count.
 * @apiSuccess {String} next
 * @apiSuccess {String} prev
 * @apiSuccess {Object[]} results     Targets list
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *          "count": 1,
 *          "next": null,
 *          "prev": null,
 *          "results": [{
 *              "id": "27236159-5085-4c79-99a2-d1c742da467b",
 *              "user_id": "11c13870-6e4f-4cb2-9a50-331f97415689",
 *               "created_at": "2021-04-20T14:10:37.692215Z",
 *               "last_modified": "2021-04-20T14:10:37.692242Z",
 *               "name": "Test DTI",
 *               "target": "test.dti",
 *               "engine": "/dti/1.0",
 *               "business": "Construction"
 *           }]
 *     }
 */
/**
 * @api {post} https://backend.platform.swascan.com/profiles/targets/ Create a new target
 * @apiVersion 1.0.0
 * @apiName CreateTarget
 * @apiGroup Targets
 *
 * @apiSampleRequest https://backend.beta.swascan.com/profiles/targets/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 * @apiHeader {String} Content-Type     application/json
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A",
 *          "Content-Type": "application/json"
 *      }
 *
 * @apiParam {String} name               Target name
 * @apiParam {String} engine             api engine (example dti/1.0)
 * @apiParam {String} target             Target url
 * @apiParam {String} business           Business category
 * @apiParamExample {json} Body-Example:
 *      {
 *          "name": "Test",
 *          "target": "test.it",
 *          "engine": "/dti/1.0",
 *          "business": "Utilities (water; gas; electricity)"
 *     }
 *
 * @apiSuccess {String} id              Target id
 * @apiSuccess {String} name            Target name
 * @apiSuccess {String} engine          api engine (example dti/1.0)
 * @apiSuccess {String} target          Target url
 * @apiSuccess {String} user_id         User id
 * @apiSuccess {String} business        Business category
 * @apiSuccess {String} created_at      Creation date
 * @apiSuccess {String} last_modified   Last update date
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *          "id": "768612d8-b6cc-4227-bca0-8cbc00102572",
 *          "user_id": "11c13870-6e4f-4cb2-9a50-331f97415689",
 *          "created_at": "2021-04-22T19:34:13.175705Z",
 *          "last_modified": "2021-04-22T19:34:13.175732Z",
 *          "name": "Test",
 *          "target": "test.it",
 *          "engine": "/dti/1.0",
 *          "business": "Utilities (water; gas; electricity)"
 *     }
 */
/**
 * @api {put} https://backend.platform.swascan.com/profiles/targets/<TARGET_ID>/ Edit existing target
 * @apiVersion 1.0.0
 * @apiName UpdateTarget
 * @apiGroup Targets
 *
 * @apiSampleRequest https://backend.beta.swascan.com/profiles/targets/TARGET_ID/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 * @apiHeader {String} Content-Type     application/json
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A",
 *          "Content-Type": "application/json"
 *      }
 *
 * @apiParam {String} [id]               Target id
 * @apiParam {String} name               Target name
 * @apiParam {String} engine             api engine (example dti/1.0)
 * @apiParam {String} target             Target url
 * @apiParam {String} business           Business category
 * @apiParamExample {json} Body-Example:
 *      {
 *          "id": "768612d8-b6cc-4227-bca0-8cbc00102572",
 *          "name": "Test",
 *          "target": "test.it",
 *          "engine": "/dti/1.0",
 *          "business": "Utilities (water; gas; electricity)"
 *     }
 *
 * @apiSuccess {String} id              Target id
 * @apiSuccess {String} name            Target name
 * @apiSuccess {String} engine          api engine (example dti/1.0)
 * @apiSuccess {String} target          Target url
 * @apiSuccess {String} user_id         User id
 * @apiSuccess {String} business        Business category
 * @apiSuccess {String} created_at      Creation date
 * @apiSuccess {String} last_modified   Last update date
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *          "id": "768612d8-b6cc-4227-bca0-8cbc00102572",
 *          "user_id": "11c13870-6e4f-4cb2-9a50-331f97415689",
 *          "created_at": "2021-04-22T19:34:13.175705Z",
 *          "last_modified": "2021-04-22T19:41:04.025704Z",
 *          "name": "Test",
 *          "target": "test.it",
 *          "engine": "/dti/1.0",
 *          "business": "Utilities (water; gas; electricity)"
 *     }
 */
/**
 * @api {delete} https://backend.platform.swascan.com/profiles/targets/<TARGET_ID>/ Delete target
 * @apiVersion 1.0.0
 * @apiName TargetDelete
 * @apiGroup Targets
 *
 * @apiSampleRequest https://backend.beta.swascan.com/profiles/targets/697824af-db89-466c-bf07-4e387acd88ea/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 */
