import { getQuery } from "./get_query";

export class DtiLiteQuery {
    static summary = `query {
    summary {
      email_total
      breach_total
      breach_source_total
      cve_total
      cve_high_total
      cve_medium_total
      cve_low_total
      cve_unique_total
      cve_high_unique_total
      cve_medium_unique_total
      cve_low_unique_total
      impact_availability_total
      impact_confidentiality_total
      impact_integrity_total
      impact_availability_unique_total
      impact_confidentiality_unique_total
      impact_integrity_unique_total
      impact3_availability_total
      impact3_confidentiality_total
      impact3_integrity_total
      impact3_availability_unique_total
      impact3_confidentiality_unique_total
      impact3_integrity_unique_total
      total_ip_found
      total_subdomain_found
      breach_chart_risk {
          breach_total_high
          breach_total_medium
          breach_total_low
      }
      target
      target_cleaned
      license_info {
          name
          api
          scans_max
          full_mode
          binary_edge_ip_score
          id
          user_id
          user_role
          scans_done
          expires_on
      }
    }
  }`;
}
