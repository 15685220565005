<template>
    <div class="question p-col-12">
        <h4>
            {{ `(${questionItem.id})` }} <span v-html="questionText"></span> 
            <small class="cursor-pointer text-primary"
                v-if="questionHelp"
                @click="showHelper">
                <i class="pi pi-question-circle"></i>
            </small>
        </h4>

        <div v-if="isMultipleList">
            <div v-for="response in responses"
                :key="`response-${response.index}`"
                class="flex align-items-start p-mt-1">
                <Checkbox
                    v-model="value"
                    :inputId="response.index.toString()"
                    :name="`response-${response.index}`"
                    :value="response.index"
                    :disabled="updating" />
                <label :for="response.index.toString()"
                    class="p-ml-2">{{ localizedText(response) }}</label>
            </div>
        </div>
        <div v-else>
            <div v-for="response in responses"
                :key="`response-${response.index}`"
                class="flex align-items-start p-mt-1">
                <RadioButton
                    v-model="value"
                    :inputId="response.index.toString()"
                    :name="`response-${response.index}`"
                    :value="response.index"
                    :disabled="updating" />
                <label :for="response.index.toString()"
                    class="p-ml-2">{{ localizedText(response) }}</label>
            </div>
        </div>

        <div class="p-mt-2"
            v-if="hasNote">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="note">Note</label>
                            <Textarea
                                v-model="note"
                                id="note"
                                :placeholder="''"
                                rows="5"
                                cols="30"
                                :autoResize="true"
                                :disabled="updating" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="p-my-4" />
    </div>
</template>

<script>
import { useStore } from "@/store";

export default {
    name: "Question",
    setup() {
        return {
            state: useStore().state,
            setState: useStore().setState,
        };
    },
    created() {
    },
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
        question: {
            type: Object,
            default: function () {
                return {}
            },
        },
        index: {
            type: Number,
            default: null,
        },
        updating: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            privacy: false,
        };
    },
    mounted() {
        // this.$nextTick(() => {
        //     if (this.isEmptyObject(this.answer)) {
        //         if (this.isMultipleList) {
        //             this.value = [this.defaultValue]
        //         } else {
        //             this.value = this.defaultValue
        //         }
        //     }
        // })
    },
    updated() {},
    watch: {
    },
    computed: {
        questionItem () {
            return this.question.question
        },
        answer: {
            get () {
                return this.question.answer
            },
            set (value) {
                this.$emit('onValueChange', this.question, value)
            }
        },
        isMultipleList () {
            return this.questionItem.min !== this.questionItem.max
        },
        questionText () {
            return this.localizedText(this.questionItem.text)
        },
        questionHelp () {
            return this.localizedText(this.questionItem.help)
        },
        responses () {
            return this.questionItem.responses
        },
        defaultValue () {
            return this.questionItem.default
        },
        hasNote () {
            return this.questionItem.note
        },
        value: {
            get () {
                if (this.isEmptyObject(this.answer)) {
                    if (this.isMultipleList) return []
                    return null
                }
                if (this.isMultipleList) return this.answer.values
                return this.answer.values[0]
            },
            set (value) {
                this.answer = {
                    id: this.questionItem.id,
                    note: this.note,
                    values: (this.isMultipleList ? Object.values(value) : [value]),
                }
            }
        },
        note: {
            get () {
                if (this.isEmptyObject(this.answer)) {
                    return null
                }
                return this.answer.note
            },
            set (value) {
                this.answer = {
                    id: this.questionItem.id,
                    note: value,
                    values: (this.isMultipleList ? this.value : [this.value]),
                }
            }
        }
    },
    methods: {
        showHelper () {
            this.$swal({
                title: null,
                html: this.questionHelp,
                icon: 'info',
                showConfirmButton: true,
                showDenyButton: false,
            })
        },
        localizedText (obj) {
            try {
                return obj[this.$i18n.locale]
            } catch (e) {
                return obj
            }
        },
        isEmptyObject (obj) {
            return obj === null || (Object.keys(obj).length === 0 && obj.constructor === Object)
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
