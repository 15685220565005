<template>
    <div :class="className">
        <div class="card p-shadow-7 full-height flex-column">
            <div class="swa-header" v-if="title">
                <h3>{{ title }}</h3>
            </div>
            <h4 class="p-text-center text-container p-mb-4">
                {{ $t("pages.report.upgrade_to_full") }}
            </h4>

            <Button
                label="Primary"
                @click="contactUS()"
                style="align-self: center"
            >
                {{ $t("general.contact_us") }}
            </Button>
        </div>
    </div>
</template>

<script>
import { ENV_VARS } from "@/env_vars";

export default {
    name: "UpgradeComponent",
    props: {
        title: {
            type: String,
            default: null,
        },
        className: {
            type: String,
            default: () => "p-col-12",
        },
    },
    methods: {
        contactUS() {
            location.href = ENV_VARS.VUE_APP_OLD_PLATFORM + "accounts/profile/";
        },
    },
};
</script>

<style lang="scss" scoped>
.text-container {
    margin-bottom: 0px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>