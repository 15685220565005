export const BUSINESS_CATEGORIES = [
    "Agriculture",
    "Basic Metal Production",
    "Chemical industries",
    "Clothing",
    "Commerce",
    "Construction",
    "Culture",
    "Drink",
    "Education",
    "Financial services",
    "Food",
    "Footwear",
    "Forestry",
    "Health services",
    "Hotels",
    "Mechanical and electrical engineering",
    "Media",
    "Oil and gas production",
    "Postal and telecommunications services",
    "Professional services",
    "Public service",
    "Shipping",
    "Textiles",
    "Tourism",
    "Transport",
    "Utilities (water; gas; electricity)",
];
