import {ENV_VARS} from "@/env_vars";
import axios, {AxiosHeaders, AxiosRequestConfig} from "axios";
import {KEYUTIL, KJUR} from "jsrsasign";
import jwt_decode from "jwt-decode";
import {i18n} from "@/i18n";
import {setAuthenticated} from "@/store";

export const TOKEN_REFRESH_VALIDITY = 2 * 24 * 60 * 60;

const IAM = `${ENV_VARS.VUE_APP_IAM_BASE_URL}/realms/${ENV_VARS.VUE_APP_IAM_REALM}/protocol/openid-connect/token`;
const IAM_LOGOUT = `${ENV_VARS.VUE_APP_IAM_BASE_URL}/realms/${ENV_VARS.VUE_APP_IAM_REALM}/protocol/openid-connect/logout`;
export class RouterService {
  //* HEADERS
  static headers = {};
  static decoded;
  static router;
  static timer;
  static inited;
  static keycloak;

  static init(token, inited = true) {
    try {
      RouterService.decoded = jwt_decode(token);
      RouterService.logExpiration();
      if (RouterService.timer) {
        clearInterval(RouterService.timer);
      }
      RouterService.startRefreshTimeout();
      RouterService.inited = inited;
    } catch (error) {
      RouterService.inited = inited;
    }
  }

  static startRefreshTimeout() {
    const time = localStorage.getItem("$swascan$expires_in") || "300";
    RouterService.timer = setInterval(() => RouterService.refreshToken(), parseInt(time / 2) * 1000);
  }

  static logExpiration() {
    const exp = RouterService.decoded && RouterService.decoded.exp ? RouterService.decoded.exp : 0;
    const expire = localStorage.getItem("$swascan$expires_in") || "0";
    const timeSkew = parseInt(expire, 10);
    const duration = Math.round(exp + timeSkew - new Date().getTime() / 1000) + " seconds";
    // console.log(duration);
  }

  static setHeader(key, value) {
    this.headers[key] = value;
  }

  //* TOKEN
  static isTokenExpired() {
    if (RouterService.keycloak) {
      return RouterService.keycloak.isTokenExpired();
    }

    const exp = RouterService.decoded && RouterService.decoded.exp ? RouterService.decoded.exp : 0;
    const expire = localStorage.getItem("$swascan$expires_in") || "0";
    const timeSkew = parseInt(expire, 10);

    return Math.round(exp + timeSkew - new Date().getTime() / 1000) <= 0;
  }

  static async verifySign(idToken) {
    try {
      const rsKey =
        process.env.VUE_APP_MODE === "kube"
          ? await axios.get(`/assets/data/rs256_pub.key`, {
              responseType: "text",
            })
          : { data: ENV_VARS.VUE_APP_PUBLIC_KEY };

      const pubKey = KEYUTIL.getKey(rsKey.data);
      return KJUR.jws.JWS.verify(idToken, pubKey, ["RS256"]);
    } catch (error) {
      return false;
    }
  }

  static async refreshToken(inited = true) {
    try {
      if (RouterService.keycloak) {
        const refreshed = await RouterService.keycloak.updateToken(TOKEN_REFRESH_VALIDITY);
        if (refreshed) {
          localStorage.setItem("$swascan$refreshToken", RouterService.keycloak.refreshToken);
        } else {
          RouterService.logExpiration();
        }
      } else {
        const refreshToken = localStorage.getItem("$swascan$refreshToken");
        const params = new URLSearchParams();
        params.append("client_id", ENV_VARS.VUE_APP_IAM_CLIENT_ID);
        params.append("scope", "openid");
        params.append("grant_type", "refresh_token");
        params.append("refresh_token", refreshToken);
        const response = await axios.request({
          url: IAM,
          method: "post",
          data: params,
          timeout: 30000,
          responseType: "json",
        });

        if (response && response.data) {
          const isValid = await RouterService.verifySign(response.data.id_token);

          if (isValid) {
            localStorage.setItem("$swascan$refreshToken", response.data.refresh_token);
            localStorage.setItem("$swascan$token", response.data.access_token);
            localStorage.setItem("$swascan$expires_in", response.data.expires_in);
            localStorage.setItem("$swascan$refresh_expires_in", response.data.refresh_expires_in);
            RouterService.init(response.data.access_token, inited);
            setAuthenticated(true);
          } else {
            setAuthenticated(false);
            RouterService.inited = inited;
            RouterService.decoded = null;
            RouterService.router.replace({ path: "/" });
            console.error("Invalid public key");
            throw new Error("Invalid public key");
          }

          return { ...response.data, auth: isValid };
        } else {
          RouterService.logExpiration();
          RouterService.inited = inited;
          RouterService.decoded = null;
          RouterService.router.replace({ path: "/" });
          setAuthenticated(false);

          throw new Error("Login error");
        }
      }
    } catch (error) {
      RouterService.inited = inited;
      RouterService.router.replace({ path: "/" });
      setAuthenticated(false);

      throw error;
    }
  }

  static async login(username, password) {
    const params = new URLSearchParams();
    params.append("client_id", ENV_VARS.VUE_APP_IAM_CLIENT_ID);
    params.append("scope", "openid");
    params.append("grant_type", "password");
    params.append("username", username);
    params.append("password", password);

    const response = await axios.request({
      url: IAM,
      method: "post",
      data: params,
      timeout: 30000,
      responseType: "json",
    });
    if (response && response.data) {
      const isValid = await RouterService.verifySign(response.data.id_token);

      if (isValid) {
        localStorage.setItem("$swascan$refreshToken", response.data.refresh_token);
        localStorage.setItem("$swascan$token", response.data.access_token);
        localStorage.setItem("$swascan$expires_in", response.data.expires_in);
        localStorage.setItem("$swascan$refresh_expires_in", response.data.refresh_expires_in);
        RouterService.init(response.data.access_token);
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
        console.error("Invalid public key");
        throw new Error("Invalid public key");
      }
    } else {
      RouterService.logExpiration();
      setAuthenticated(false);
    }

    return response.data;
  }

  //* COMMONS
  static getBaseUrl(baseUrl, api) {
    return baseUrl ? baseUrl + api : ENV_VARS.VUE_APP_BACKEND_BASE_URL + api;
  }

  static getHeaders(reqHeaders) {
    const token = RouterService.keycloak ? RouterService.keycloak.token : localStorage.getItem("$swascan$token");
    const headers = {
      ...this.headers,
      Authorization: "Bearer " + token,
    };

    if (reqHeaders) {
      for (const key in reqHeaders) {
        headers[key] = reqHeaders[key];
      }
    }

    return headers;
  }

  //* METHOD
  static async get(api, args) {
    try {
      const token = RouterService.keycloak ? RouterService.keycloak.token : localStorage.getItem("$swascan$token");
      if (!token) return;
      if (RouterService.isTokenExpired()) {
        await RouterService.refreshToken();
      } else {
        RouterService.logExpiration();
      }
    } catch (error) {
      throw new Error("Error refreshing keycloak token");
    }

    if (!args) args = {};
    const url = RouterService.getBaseUrl(args.baseUrl, api);
    const headers = RouterService.getHeaders(args.headers);

    try {
      return await axios.request({
        url,
        method: "get",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        data: {},
        params: args && args.params,
        timeout: (args && args.timeout) || 30000,
        responseType: (args && args.responseType) || "json",
        onDownloadProgress: args.onDownloadProgress,
      });
    } catch (e) {
      console.error(e);
      console.error(e.response.status === 401);
      if (e.response && e.response.status === 401) {
        await RouterService.refreshToken();
        setTimeout(async () => {
          return await axios.request({
            url,
            method: "get",
            headers: {
              ...headers,
              "Content-Type": "application/json",
            },
            data: {},
            params: args && args.params,
            timeout: (args && args.timeout) || 30000,
            responseType: (args && args.responseType) || "json",
          });
        }, 300);
      } else if (e.response && e.response.status === 403) {
        throw new Error(i18n.global.t("expired_license"));
      } else {
        throw e;
      }
    }
  }

  static async post(api, body, args) {
    try {
      const token = RouterService.keycloak ? RouterService.keycloak.token : localStorage.getItem("$swascan$token");
      if (!token) return;
      if (RouterService.isTokenExpired()) {
        await RouterService.refreshToken();
      } else {
        RouterService.logExpiration();
      }
    } catch (error) {
      throw new Error("Error refreshing keycloak token");
    }

    if (!args) args = {};
    const url = RouterService.getBaseUrl(args.baseUrl, api);
    const headers = RouterService.getHeaders(args.headers);

    try {
      return await axios.request({
        url,
        method: "post",
        headers: headers,
        params: args && args.params,
        data: body,
        timeout: (args && args.timeout) || 30000,
        responseType: (args && args.responseType) || "json",
      });
    } catch (e) {
      console.warn(e);
      if (e.response && e.response.status === 401) {
        await RouterService.refreshToken();
        setTimeout(async () => {
          return await axios.request({
            url,
            method: "post",
            headers: headers,
            params: args && args.params,
            data: body,
            timeout: (args && args.timeout) || 30000,
            responseType: (args && args.responseType) || "json",
          });
        }, 300);
      } else if (e.response && e.response.status === 403) {
        throw new Error(i18n.global.t("expired_license"));
      } else {
        throw e;
      }
    }
  }

  static async put(api, body, args) {
    try {
      const token = RouterService.keycloak ? RouterService.keycloak.token : localStorage.getItem("$swascan$token");
      if (!token) return;
      if (RouterService.isTokenExpired()) {
        await RouterService.refreshToken();
      }
    } catch (error) {
      throw new Error("Error refreshing keycloak token");
    }

    if (!args) args = {};
    const url = RouterService.getBaseUrl(args.baseUrl, api);
    const headers = RouterService.getHeaders(args.headers);

    try {
      return await axios.request({
        url,
        method: "put",
        headers: headers,
        params: args && args.params,
        data: body,
        timeout: (args && args.timeout) || 30000,
        responseType: (args && args.responseType) || "json",
      });
    } catch (e) {
      console.warn(e);
      if (e.response && e.response.status === 401) {
        await RouterService.refreshToken();
        setTimeout(async () => {
          return await axios.request({
            url,
            method: "put",
            headers: headers,
            params: args && args.params,
            data: body,
            timeout: (args && args.timeout) || 30000,
            responseType: (args && args.responseType) || "json",
          });
        }, 300);
      } else if (e.response && e.response.status === 403) {
        throw new Error(i18n.global.t("expired_license"));
      } else {
        throw e;
      }
    }
  }

  static async delete(api, args) {
    try {
      const token = RouterService.keycloak ? RouterService.keycloak.token : localStorage.getItem("$swascan$token");
      if (!token) return;
      if (RouterService.isTokenExpired()) {
        await RouterService.refreshToken();
      }
    } catch (error) {
      throw new Error("Error refreshing keycloak token");
    }

    if (!args) args = {};
    const url = RouterService.getBaseUrl(args.baseUrl, api);
    const headers = RouterService.getHeaders(args.headers);

    try {
      return await axios.request({
        url,
        method: "delete",
        headers: headers,
        params: args && args.params,
        data: args && args.data,
        timeout: (args && args.timeout) || 30000,
        responseType: (args && args.responseType) || "json",
      });
    } catch (e) {
      console.warn(e);
      if (e.response && e.response.status === 401) {
        await RouterService.refreshToken();
        setTimeout(async () => {
          return await axios.request({
            url,
            method: "delete",
            headers: headers,
            params: args && args.params,
            timeout: (args && args.timeout) || 30000,
            responseType: (args && args.responseType) || "json",
          });
        }, 300);
      } else if (e.response && e.response.status === 403) {
        throw new Error(i18n.global.t("expired_license"));
      } else {
        throw e;
      }
    }
  }

  static async patch(api, body, args) {
    try {
      const token = RouterService.keycloak ? RouterService.keycloak.token : localStorage.getItem("$swascan$token");
      if (!token) return;
      if (RouterService.isTokenExpired()) {
        await RouterService.refreshToken();
      }
    } catch (error) {
      throw new Error("Error refreshing keycloak token");
    }

    if (!args) args = {};
    const url = RouterService.getBaseUrl(args.baseUrl, api);
    const headers = RouterService.getHeaders(args.headers);

    try {
      return await axios.patch(url, body, {
        headers,
        timeout: (args && args.timeout) || 30000,
        responseType: (args && args.responseType) || "json",
        params: args && args.params,
      });
    } catch (e) {
      console.warn(e);
      if (e.response && e.response.status === 401) {
        await RouterService.refreshToken();
        setTimeout(async () => {
          return await axios.patch(url, body, {
            headers,
            timeout: (args && args.timeout) || 30000,
            responseType: (args && args.responseType) || "json",
            params: args && args.params,
          });
        }, 300);
      } else if (e.response && e.response.status === 403) {
        throw new Error(i18n.global.t("expired_license"));
      } else {
        throw e;
      }
    }
  }

  static request({ method, api, body, args }) {
    switch (method.toUpperCase()) {
      case "GET":
        return RouterService.get(api, args);
      case "POST":
        return RouterService.post(api, body, args);
      case "PUT":
        return RouterService.put(api, body, args);
      case "DELETE":
        return RouterService.delete(api, args);
      case "PATCH":
        return RouterService.patch(api, body, args);
      default:
        return;
    }
  }

  static async logout() {
    // try {
    //     const refreshToken = localStorage.getItem("$swascan$refreshToken");
    //     const params = new URLSearchParams();
    //     params.append("client_id", ENV_VARS.VUE_APP_IAM_CLIENT_ID);
    //     params.append("refresh_token", refreshToken);
    //     const response = await axios.request({
    //         url: IAM_LOGOUT,
    //         method: "post",
    //         headers: { "content-type": "application/x-www-form-urlencoded" },
    //         data: params,
    //         timeout: 30000,
    //         responseType: "json",
    //     });
    // } catch (error) {
    //     console.warn(error);
    // }
    RouterService.decoded = null;
    if (RouterService.timer) {
      clearInterval(RouterService.timer);
    }
    localStorage.clear();
    if (RouterService.keycloak) {
      if (window.forceKeycloak) {
        const redirectUrl = window.location.origin + "?forceKeycloak=true";
        RouterService.keycloak.logout({
          redirectUri: redirectUrl,
        });
      } else {
        RouterService.keycloak.logout();
      }
    }
  }
}
