import { getQuery } from "./get_query";

export class NscanQuery {
  static summary = `query {
                      summary {
                        severity_range { name min max }
                        hosts_count
                        vulns
                        critical
                        high
                        medium
                        low
                        confidentiality { N P C }
                        integrity { N P C }
                        availability { N P C }
                        scan_start
                        scan_end
                        result_count {
                          full
                          filtered
                          debug { full filtered }
                          hole { full filtered }
                          info { full filtered }
                          log { full filtered }
                          warning { full filtered }
                          false_positive { full filtered }
                        }
                        license_info {
                          name
                          api
                          scans_max
                          full_mode
                          binary_edge_ip_score
                          id
                          user_id
                          user_role
                          scans_done
                          expires_on
                        }
                        domains
                        vuln_summary { target CRITICAL HIGH MEDIUM LOW }
                        top_3_vuln {
                          top_1 { target LOW MEDIUM HIGH CRITICAL }
                          top_2 { target LOW MEDIUM HIGH CRITICAL }
                          top_3 { target LOW MEDIUM HIGH CRITICAL }
                        }
                        target_down
                      }
                    }`;

  static oldSummary = `query {
                        summary {
                            vulns
                            critical
                            high
                            medium
                            low
                            scan_start
                        }
                      }`;

  static hosts(first = 20, skip = 0, { sort_by = "time", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              hosts {
                items(${query}) {
                  ip
                  asset { asset_id }
                  start
                  end
                  port_count { page }
                  result_count {
                    page
                    hole { page }
                    warning { page }
                    info { page }
                    log { page }
                    false_positive { page }
                  }
                  detail {
                    name
                    value
                    source { type name description }
                  }
                  open_ports
                  ports {
                    protocol
                    portid
                    state { state reason reason_ttl }
                    service { name product extrainfo ostype method conf cpe tunnel version }
                    script {
                      id
                      output
                      table { elem { key } }
                    }
                  }
                  hostnames
                  os {
                    portused
                    osmatch
                  }
                  guess_os { 
                    ostypes { ostype index }
                    osprobes { os_name accuracy }
                  }
                  index
                }
                total
              }
            }`;
  }

  static vulnerabilities(first = 20, skip = 0, { sort_by = "time", order = -1, search = null, exactly = false } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search, exactly });
    return `query {
              vulnerabilities {
                items(${query}) {
                  id
                  name
                  owner { name }
                  modification_time
                  comment
                  creation_time
                  host { asset { asset_id } hostname value }
                  port
                  nvt {
                      oid
                      type
                      name
                      family
                      cvss_base
                      solution { type text }
                      refs { ref { type id link } }
                  }
                  tags {
                      cvss_base_vector
                      summary
                      insight
                      affected
                      impact
                      solution
                      vuldetect
                      solution_type
                  }
                  scan_nvt_version
                  threat
                  severity
                  qod { value type }
                  description
                  original_threat
                  original_severity
                }
                total
              }
            }`;
  }
}
