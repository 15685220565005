<template>
    <div class="p-grid p-formgrid">
        <div class="p-field p-col-12">
            <div class="p-p-4">
                <div class="p-d-flex p-mb-3">
                    <Skeleton
                        shape="circle"
                        size="4rem"
                        class="p-mr-2"
                    ></Skeleton>
                    <div>
                        <Skeleton width="10rem" class="p-mb-2"></Skeleton>
                        <Skeleton width="5rem" class="p-mb-2"></Skeleton>
                        <Skeleton height=".5rem"></Skeleton>
                    </div>
                </div>
                <Skeleton width="100%" height="150px"></Skeleton>
                <div class="p-d-flex p-jc-between p-mt-3">
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppLoader",
    props: {
        shortLines: Array,
        longLines: Array,
    },
};
</script>

<style lang="scss" scoped>
@import "./AppLoader";
</style>