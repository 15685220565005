import { inject, reactive, readonly, watch } from "vue";

import { BehaviorSubject } from "rxjs";
import { Target } from "./models";
import { distinctUntilChanged } from "rxjs/operators";

/**
 * USAGE:
 * in componente put this
 * 
 * state is readonly
 * setState update a state value
 * getValue return an editable state value
 * 
 * PROTECTED
...
import { useStore } from './store';
...
export default {
  ...
  setup() {
    return {
      state: useStore().state,
      setState: useStore().setState,
      getValue: useStore().getValue,
    };
  },
  ...
  computed: {
    state() {
      return this.store.state;
    },
  },
  ...
  methods: {
    ...
    onChangeValue() {
      this.menuClick = true;
      this.store.setState({
        counter: this.store.state.counter + 1,
      });
    },
    ...
  }
}

 * EDITABLE
...
import { useState } from './store';
...
export default {
  ...
  setup() {
    return {
        state: useStore(),
    };
  },
  ...
}

 */

const STATE = {
  newTarget: new Target(),
  addTargetItems: [
    {
      label: "Target Info",
      to: "/targets",
      command: () => {},
    },
  ],
  licenses: [],
  notificationRead: JSON.parse(sessionStorage.getItem("notificationRead") || "false"),
};

export const stateSymbol = Symbol("state");
export const useState = () => inject(stateSymbol);
export const createState = () =>
  reactive({
    ...STATE,
  });

export const storeSymbol = Symbol("store");
export const useStore = () => inject(storeSymbol);

export const createStore = () => {
  const state = reactive({
    ...STATE,
  });

  const setState = (newState) => {
    if (newState === null) return;

    for (const key in newState) {
      state[key] = newState[key];
    }
  };

  const getValue = (key) => {
    return state[key];
  };

  const subtractScanToLicense = (license, response = null) => {
    // PARSE
    let count = 1;
    if (response) {
      if (response.targets_count) {
        count = response.targets_count || 1;
      }
    }

    // CHECK
    for (const l of state.licenses) {
      if (license === l.engine) {
        l.scans_done += count;
      }
    }
  };

  return {
    setState,
    getValue,
    subtractScanToLicense,
    watchAuthentication,
    state: readonly(state),
  };
};

const authenticated = new BehaviorSubject(false);
export const isAuthenticated = () => authenticated.value;
export const setAuthenticated = (value) => authenticated.next(value);
export const watchAuthentication = () => {
  return authenticated.pipe(distinctUntilChanged());
};
