<template>
    <div class="full-width">
        <!-- / UPGRADE MESSAGE -->
        <UpgradeComponent
            v-if="!isFull && loaded"
            :title="$t('pages.report.subdomains')"
        />
        <!-- / UPGRADE MESSAGE -->

        <div v-if="isFull" class="p-col-12 p-mt-2 subdomains-container">
            <!-- SubDomains -->
            <div class="card p-shadow-7">
                <div class="swa-header">
                    <h3>{{ $t("pages.report.subdomains") }}</h3>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText
                            v-model="filters['global']"
                            :placeholder="$t('general.global_search')"
                            @change="onFilter()"
                        />
                    </span>
                </div>

                <DataTable
                    :filters="filters"
                    :lazy="true"
                    :loading="loading"
                    :paginator="true"
                    :rowHover="true"
                    :rows="records_per_page"
                    :scrollable="true"
                    :sortField="sortField"
                    :sortOrder="sortOrder"
                    :totalRecords="total_records"
                    :value="records"
                    class="p-datatable-sm p-datatable-responsive min-height-400"
                    scrollHeight="400px"
                    @page="onPageChange($event)"
                    @sort="onPageChange($event)"
                >
                    <Column
                        v-for="col of columns"
                        :key="col"
                        :field="col"
                        :header="getHeaderByCol(col)"
                        :sortable="col === 'subdomain'"
                    >
                        <template #body="slotProps">
                            <div v-if="col === 'ip_list'">
                                <span
                                    v-for="ip in slotProps.data.ip_list"
                                    :key="'col-' + ip"
                                >
                                    {{ ip }}<br />
                                </span>
                            </div>
                            <div v-else-if="col === 'technologies'">
                                <span
                                    v-for="tech in slotProps.data.technologies"
                                    :key="'tech-' + tech.app"
                                >
                                    <li
                                        v-if="renderTechLabel(tech).length > 0"
                                        class="p-mb-2"
                                    >
                                        {{ renderTechLabel(tech) }}
                                    </li>
                                </span>
                            </div>
                            <span v-else>{{ slotProps.data[`${col}`] }}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <!-- SubDomains -->
        </div>
    </div>
</template>

<script>
import { ReportService } from "../../../services";
import { DtiQuery } from "../../../pages/Reports/Report/query";

const RECORDS_PER_PAGE = 20;

export default {
    name: "Subdomains",
    reportService: null,
    created() {
        this.reportService = new ReportService();
    },
    props: {
        reportId: {
            type: String,
            default: null,
        },
        loaded: {
            type: Boolean,
            default: () => false,
        },
        isFull: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {},
    watch: {
        loaded(newVal, oldVal) {
            if (newVal && newVal !== oldVal && this.isFull) {
                this.getData();
            }
        },
    },
    data() {
        return {
            loading: true,
            records: [],
            total_records: 0,
            total_filtered: 0,
            page: 0,
            filters: {},
            sortField: "subdomain",
            sortOrder: 1,
            records_per_page: RECORDS_PER_PAGE,
            columns: ["subdomain", "ip_list", "technologies"],
        };
    },
    mounted() {
        if (this.loaded && this.isFull) {
            this.getData();
        }
    },
    updated() {},
    methods: {
        async getData() {
            this.loading = true;
            try {
                const skip = this.page * RECORDS_PER_PAGE;

                const search =
                    this.filters.global && this.filters.global.length > 0
                        ? `${this.filters.global}`
                        : "";

                const response = await this.reportService.graphql({
                    api: this.reportId,
                    method: "get",
                    args: {
                        params: {
                            query: DtiQuery.subdomains(this.page_size, skip, {
                                sort_by: this.sortField,
                                order: this.sortOrder,
                                search,
                            }),
                        },
                    },
                });

                const result = response.data.result.subdomain_data;
                this.total_records = result.total;
                this.total_filtered = result.total;
                this.records = result.items;
            } catch (error) {
                if (error.toString().indexOf("404") === -1) {
                    console.warn(error);
                }
            }
            this.loading = false;
        },
        onPageChange(event) {
            this.page = event.page !== undefined ? event.page : this.page;
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.getData();
        },
        onFilter() {
            this.onPageChange({
                page: 0,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
            });
        },
        getHeaderByCol(col) {
            if (col === "creation_date") return this.$t("general.date");
            if (col === "id") return this.$t("general.view");
            return this.$t(`general.${col}`);
        },
        renderTechLabel(tech) {
            if (tech.app && tech.ver && tech.type)
                return `${tech.app}: ${tech.ver} (${tech.type})`;
            if (tech.app && tech.ver && !tech.type)
                return `${tech.app}: ${tech.ver}`;
            if (tech.app && !tech.ver && !tech.type) return `${tech.app}`;
            if (!tech.app && tech.ver && tech.type)
                return `${tech.ver} (${tech.type})`;
            if (!tech.app && !tech.ver && tech.type) return `${tech.type}`;
            if (!tech.app && tech.ver && !tech.type) return `${tech.ver}`;

            return "";
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./Report";
</style>