import NScanIPDown from "@/components/ReportDetail/NScanReportDetail/NScanIPDown.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import moment from 'moment';
import "@sweetalert2/theme-dark/dark.min.css";
import Keycloak from "keycloak-js";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import AutoComplete from "primevue/autocomplete";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Badge from "primevue/badge";
import BlockUI from "primevue/blockui";
import Breadcrumb from "primevue/breadcrumb";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import CascadeSelect from "primevue/cascadeselect";
import Chart from "primevue/chart";
import Checkbox from "primevue/checkbox";
// import Chip from "primevue/chip";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import DynamicDialog from 'primevue/dynamicdialog';
import Fieldset from "primevue/fieldset";
import FileUpload from "primevue/fileupload";
import Galleria from "primevue/galleria";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Knob from "primevue/knob";
import Listbox from "primevue/listbox";
import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import OrderList from "primevue/orderlist";
import OrganizationChart from "primevue/organizationchart";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import PickList from "primevue/picklist";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import RadioButton from "primevue/radiobutton";
import Rating from "primevue/rating";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css";
import Ripple from "primevue/ripple";
import ScrollPanel from "primevue/scrollpanel";
import SelectButton from "primevue/selectbutton";
import Sidebar from "primevue/sidebar";
import Skeleton from "primevue/skeleton";
import Slider from "primevue/slider";
import SplitButton from "primevue/splitbutton";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Steps from "primevue/steps";
import TabMenu from "primevue/tabmenu";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import TieredMenu from "primevue/tieredmenu";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import ToggleButton from "primevue/togglebutton";
import Toolbar from "primevue/toolbar";
import Tooltip from "primevue/tooltip";
import Tree from "primevue/tree";
import TreeTable from "primevue/treetable";
import TriStateCheckbox from "primevue/tristatecheckbox";
import VueGauge from "vue-gauge";
import "prismjs/themes/prism-coy.css";
import "sweetalert2/dist/sweetalert2.min.css";
import {createApp, reactive} from "vue";
// SWEETALERT2
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue3-apexcharts";
import App from "./App.vue";
import CodeHighlight from "./AppCodeHighlight";
import AppSubMenu from "./AppSubMenu.vue";
import AppColors from "./assets/colors";
import "./assets/layout/flags/flags.css";
import "./assets/layout/layout.scss";
import "./assets/layout/theme.css";
import AppLoader from "./components/AppLoader/AppLoader.vue";
import CustomTagInput from "./components/CustomTagInput/CustomTagInput.vue";
import DevcoTerminal from "./components/DevcoTerminal/DevcoTerminal.vue";
import DevcoUpload from "./components/DevcoUpload/DevcoUpload.vue";
//import Menubar from "primevue/menubar";
import Menubar from "./components/Menubar/Menubar.vue";
import DownloadsPDFComponent from "./components/ReportDetail/DownloadsPDFComponent.vue";
import DTIReportDetailBreaches from "./components/ReportDetail/DTIReportDetail/Breaches.vue";
import DTIReportDetailCveDetail from "./components/ReportDetail/DTIReportDetail/CveDetail.vue";
import DTIReportDetailServices from "./components/ReportDetail/DTIReportDetail/Services.vue";
import DTIReportDetailSubdomains from "./components/ReportDetail/DTIReportDetail/Subdomains.vue";
import DTIReportDetailSummary from "./components/ReportDetail/DTIReportDetail/Summary.vue";
import DTIReportDetailTyposquatting from "./components/ReportDetail/DTIReportDetail/Typosquatting.vue";
import NScanHostsTable from "./components/ReportDetail/NScanReportDetail/NScanHostsTable.vue";
import NScanSummary from "./components/ReportDetail/NScanReportDetail/NScanSummary.vue";
import NScanVulnerabilitiesTable from "./components/ReportDetail/NScanReportDetail/NScanVulnerabilitiesTable.vue";
import UpgradeComponent from "./components/ReportDetail/UpgradeComponent.vue";
import ReportsTable from "./components/Reports/ReportsTable.vue";
import RounderContainer from "./components/RounderContainer/RounderContainer.vue";
import DoubleRounderContainer from "./components/DoubleRounderContainer/DoubleRounderContainer.vue";
import TargetsTable from "./components/Targets/TargetsTable.vue";
import TargetTestModal from "./components/TargetTestModal/TargetTestModal.vue";
import RansomwareChart from "./components/RansomwareChart/RansomwareChart.vue";
import DevcoTag from '@/components/DevcoTag/DevcoTag.vue';
import VpnSettings from '@/components/VpnSettings/VpnSettings.vue';
import SurveyHeader from '@/components/Survey/Header.vue';
import SurveyQuestions from '@/components/Survey/Questions.vue';
import QuestionInteger from '@/components/Survey/QuestionType/Integer.vue';
import QuestionFloat from '@/components/Survey/QuestionType/Float.vue';
import QuestionList from '@/components/Survey/QuestionType/List.vue';
import DialogService from 'primevue/dialogservice';


import {ENV_VARS} from "./env_vars";
import "./fa-icons";
import {i18n} from "./i18n";
import router from "./router";
import {RouterService, TOKEN_REFRESH_VALIDITY} from "./services";
import {createState, createStore, setAuthenticated, stateSymbol, storeSymbol} from "./store";

setTimeout(() => {
    // KC INIT
    let kcInited = false;

    const queryString = location.search;
    let standalone = false;
    let forceKeycloak = false;
    let refreshToken;
    let savedToken = localStorage.getItem("$swascan$refreshToken");
    let disableAutoRedirect = false;

    const vueAppForceKeycloak = ENV_VARS.VUE_APP_FORCE_KEYCLOAK && ENV_VARS.VUE_APP_FORCE_KEYCLOAK === "true";
    const vueAppForceStandalone = ENV_VARS.VUE_APP_FORCE_STANDALONE && ENV_VARS.VUE_APP_FORCE_STANDALONE === "true";

    if (vueAppForceStandalone) {
        standalone = true;
    }

    if (queryString) {
        const params = new URLSearchParams(queryString);
        if (params.get("useKeycloak")) {
            const param = params.get("useKeycloak");
            const value = param.charAt(param - 1) === "/" ? param.slice(0, -1) : param;
            if (value === "false") {
                standalone = true;
                sessionStorage.setItem("useKeycloak", "false");
            }
        }
        if (params.get("forceKeycloak")) {
            const param = params.get("forceKeycloak");
            const value = param.charAt(param.length - 1) === "/" ? param.slice(0, -1) : param;
            if (value === "true") {
                forceKeycloak = true;
                sessionStorage.setItem("forceKeycloak", "true");
            }
        }
        refreshToken = params.get("token");
        if (refreshToken && refreshToken.charAt(refreshToken.length - 1) === "/") {
            refreshToken = refreshToken.slice(0, -1);
        }
        localStorage.setItem("$swascan$refreshToken", refreshToken);
        // location.search = "";
        window.history.pushState({}, "Tinexta Cyber Platform", "/#/");
    }
    if (sessionStorage.getItem("useKeycloak") && sessionStorage.getItem("useKeycloak") === "false") {
        standalone = true;
        sessionStorage.setItem("useKeycloak", "false");
    }
    if (sessionStorage.getItem("forceKeycloak") && sessionStorage.getItem("forceKeycloak") === "true") {
        forceKeycloak = true;
        sessionStorage.setItem("forceKeycloak", "true");
    }

    if (savedToken) {
        if (savedToken.charAt(savedToken.length - 1) === "/") {
            savedToken = savedToken.slice(0, -1);
        }
    }

    if (vueAppForceKeycloak) {
        standalone = false;
        forceKeycloak = true;
    }

    const redirectUrl = forceKeycloak ? window.location.origin + "?forceKeycloak=true" : `${ENV_VARS.VUE_APP_OLD_PLATFORM}accounts/login/?next=/`;

    window.standalone = standalone;
    window.forceKeycloak = forceKeycloak;

    if (!forceKeycloak && !standalone && !refreshToken && !savedToken) {
        window.location.href = `${ENV_VARS.VUE_APP_OLD_PLATFORM}accounts/login/?next=/`;
    }

    const redirect = (to, from, next) => {
        setTimeout(() => {
            if (!RouterService.inited) return redirect(to, from, next);
            if (!RouterService.decoded)
                router.replace({
                    path: "/",
                }).then(() => {}, (err) => {});
            else
                router.replace({
                    path: to.path,
                }).then(() => {}, (err) => {});
        }, 100);
    };

    if (standalone) {
        router.beforeEach((to, from, next) => {
            window.scrollTo(0, 0);
            if (to.path !== "/" && !RouterService.decoded) {
                if (!RouterService.inited) {
                    return redirect(to, from, next);
                }
                if (!RouterService.decoded)
                    router.replace({
                        path: "/",
                    }).then(() => {}, (err) => {});
            } else if (RouterService.decoded && to.path === "//") {
                router.replace({
                    path: "/dashboard",
                }).then(() => {}, (err) => {});
            } else if (RouterService.decoded && to.path === "/") {
                router.replace({
                    path: "/dashboard",
                }).then(() => {}, (err) => {});
            } else {
                next();
            }
        });
    } else {
        router.beforeEach((to, from, next) => {
            window.scrollTo(0, 0);
            if (to.path.indexOf("&state") > -1) {
                let interval = setInterval(() => {
                    if (kcInited) {
                        const path = to.path.split("&state");
                        next({
                            path: path[0] === "/" || path[0] === "//" ? "/dashboard" : path[0],
                        });
                        clearInterval(interval);
                    }
                }, 100);
            } else if ((to.path === "//" || to.path === "/") && !disableAutoRedirect) {
                router.replace({
                    path: "/dashboard",
                }).then(() => {}, (err) => {});
            } else {
                next();
            }
        });
    }

    const app = createApp(App);

    app.config.globalProperties.$appColors = AppColors;
    app.config.globalProperties.$appState = reactive({
        inputStyle: "outlined",
    });
    app.config.globalProperties.$primevue = reactive({
        ripple: true,
    });

    app.use(PrimeVue);
    app.use(i18n);
    app.use(ToastService);
    app.use(router);
    app.use(VueApexCharts);
    app.use(ConfirmationService);
    app.use(VueSweetalert2);
    app.use(DialogService);

//* Editable and protected state
    app.provide(stateSymbol, createState());
    app.provide(storeSymbol, createStore());

    app.directive("tooltip", Tooltip);
    app.directive("ripple", Ripple);
    app.directive("code", CodeHighlight);

    app.component("app-submenu", AppSubMenu);
    app.component("font-awesome-icon", FontAwesomeIcon);
    app.component("Accordion", Accordion);
    app.component("AccordionTab", AccordionTab);
    app.component("AutoComplete", AutoComplete);
    app.component("Avatar", Avatar);
    app.component("AvatarGroup", AvatarGroup);
    app.component("Breadcrumb", Breadcrumb);
    app.component("Badge", Badge);
    app.component("Button", Button);
    app.component("Calendar", Calendar);
    app.component("Card", Card);
    app.component("Carousel", Carousel);
    app.component("CascadeSelect", CascadeSelect);
    app.component("Chart", Chart);
    app.component("Checkbox", Checkbox);
// app.component("Chip", Chip);
    app.component("Chips", Chips);
    app.component("ColorPicker", ColorPicker);
    app.component("Column", Column);
    app.component("ConfirmDialog", ConfirmDialog);
    app.component("ConfirmPopup", ConfirmPopup);
    app.component("ContextMenu", ContextMenu);
    app.component("DataTable", DataTable);
    app.component("DataView", DataView);
    app.component("DataViewLayoutOptions", DataViewLayoutOptions);
    app.component("Dialog", Dialog);
    app.component("Divider", Divider);
    app.component("Dropdown", Dropdown);
    app.component("DynamicDialog", DynamicDialog);
    app.component("Fieldset", Fieldset);
    app.component("FileUpload", FileUpload);
    app.component("InlineMessage", InlineMessage);
    app.component("Inplace", Inplace);
    app.component("InputMask", InputMask);
    app.component("InputNumber", InputNumber);
    app.component("InputSwitch", InputSwitch);
    app.component("InputText", InputText);
    app.component("Galleria", Galleria);
    app.component("Knob", Knob);
    app.component("Listbox", Listbox);
    app.component("MegaMenu", MegaMenu);
    app.component("Menu", Menu);
    app.component("Menubar", Menubar);
    app.component("Message", Message);
    app.component("MultiSelect", MultiSelect);
    app.component("OrderList", OrderList);
    app.component("OrganizationChart", OrganizationChart);
    app.component("OverlayPanel", OverlayPanel);
    app.component("Paginator", Paginator);
    app.component("Panel", Panel);
    app.component("PanelMenu", PanelMenu);
    app.component("Password", Password);
    app.component("PickList", PickList);
    app.component("ProgressBar", ProgressBar);
    app.component("ProgressSpinner", ProgressSpinner);
    app.component("Terminal", DevcoTerminal);
    app.component("DevcoUpload", DevcoUpload);
    app.component("BlockUI", BlockUI);
    app.component("RadioButton", RadioButton);
    app.component("RounderContainer", RounderContainer);
    app.component("DoubleRounderContainer", DoubleRounderContainer);
    app.component("Rating", Rating);
    app.component("SelectButton", SelectButton);
    app.component("Skeleton", Skeleton);
    app.component("Slider", Slider);
    app.component("Sidebar", Sidebar);
    app.component("SplitButton", SplitButton);
    app.component("Splitter", Splitter);
    app.component("SplitterPanel", SplitterPanel);
    app.component("Steps", Steps);
    app.component("TabMenu", TabMenu);
    app.component("TabView", TabView);
    app.component("TabPanel", TabPanel);
    app.component("Tag", Tag);
    app.component("DevcoTag", DevcoTag);
    app.component("Textarea", Textarea);
    app.component("TieredMenu", TieredMenu);
    app.component("Toast", Toast);
    app.component("Toolbar", Toolbar);
    app.component("ToggleButton", ToggleButton);
    app.component("Tree", Tree);
    app.component("TreeTable", TreeTable);
    app.component("TriStateCheckbox", TriStateCheckbox);
    app.component("AppLoader", AppLoader);
    app.component("TargetsTable", TargetsTable);
    app.component("TargetTestModal", TargetTestModal);
    app.component("DTIReportDetailSummary", DTIReportDetailSummary);
    app.component("DTIReportDetailCveDetail", DTIReportDetailCveDetail);
    app.component("DTIReportDetailServices", DTIReportDetailServices);
    app.component("DTIReportDetailBreaches", DTIReportDetailBreaches);
    app.component("DTIReportDetailSubdomains", DTIReportDetailSubdomains);
    app.component("DTIReportDetailTyposquatting", DTIReportDetailTyposquatting);
    app.component("ReportsTable", ReportsTable);
    app.component("NScanHostsTable", NScanHostsTable);
    app.component("NScanVulnerabilitiesTable", NScanVulnerabilitiesTable);
    app.component("NScanSummary", NScanSummary);
    app.component("UpgradeComponent", UpgradeComponent);
    app.component("ScrollPanel", ScrollPanel);
    app.component("CustomTagInput", CustomTagInput);
    app.component("DownloadsPDFComponent", DownloadsPDFComponent);
    app.component("NScanIPDown", NScanIPDown);
    app.component("vue-gauge", VueGauge);
    app.component("RansomwareChart", RansomwareChart);
    app.component('VpnSettings', VpnSettings);
    app.component('SurveyHeader', SurveyHeader);
    app.component('SurveyQuestions', SurveyQuestions);
    app.component('QuestionInteger', QuestionInteger);
    app.component('QuestionFloat', QuestionFloat);
    app.component('QuestionList', QuestionList);

    const init = async () => {
        try {
            RouterService.router = router;
            if (refreshToken) {
                const inited = savedToken !== null;
                await RouterService.refreshToken(inited);
                RouterService.inited = true;
            } else if (savedToken) {
                await RouterService.refreshToken();
            } else {
                RouterService.inited = true;
            }
        } catch (error) {
            if (savedToken && refreshToken) {
                try {
                    await RouterService.refreshToken();
                } catch (e) {
                    RouterService.inited = true;
                }
            } else {
                RouterService.inited = true;
            }
        }
        app.config.globalProperties.$i18n = i18n;
        app.mount("#app");
    };

    if (standalone) {
        init().then(() => {}, (err) => {});
    } else {
        const initOptions = {
            realm: ENV_VARS.VUE_APP_IAM_REALM || "swascan",
            url: ENV_VARS.VUE_APP_IAM_BASE_URL,
            "ssl-required": "none",
            clientId: ENV_VARS.VUE_APP_IAM_CLIENT_ID,
            onLoad: "login-required",
            checkLoginIframe: false,
            // redirectUri: "https://beta.swascan.com/"
        };

        if (!forceKeycloak) {
            delete initOptions.onLoad;
        }

        const keycloak = new Keycloak(initOptions);
        if (ENV_VARS.VUE_APP_ENV === "development") {
            window.keycloak = keycloak;
        }
        keycloak.init(initOptions).then(async (auth) => {
            try {
                if (!initOptions.onLoad) {
                    if (refreshToken) {
                        keycloak.refreshToken = refreshToken;
                        await keycloak.updateToken(TOKEN_REFRESH_VALIDITY);
                        localStorage.setItem("$swascan$refreshToken", keycloak.refreshToken);
                    } else if (savedToken) {
                        keycloak.refreshToken = savedToken;
                        await keycloak.updateToken(TOKEN_REFRESH_VALIDITY);
                        localStorage.setItem("$swascan$refreshToken", keycloak.refreshToken);
                    }
                }

                auth = await RouterService.verifySign(keycloak.idToken);
                kcInited = true;
            } catch (error) {
                if (refreshToken && savedToken) {
                    try {
                        keycloak.refreshToken = savedToken;
                        await keycloak.updateToken(TOKEN_REFRESH_VALIDITY);
                        localStorage.setItem("$swascan$refreshToken", keycloak.refreshToken);

                        auth = await RouterService.verifySign(keycloak.idToken);
                        kcInited = true;
                    } catch (error) {
                        auth = false;
                        console.warn("Error 1", error);
                        disableAutoRedirect = true;
                        router.replace({
                            path: "/",
                        }).then(() => {}, (err) => {});

                        return (window.location.href = redirectUrl);
                    }
                } else {
                    auth = false;
                    console.warn("Error 2", error);
                    disableAutoRedirect = true;
                    router.replace({
                        path: "/",
                    }).then(() => {}, (err) => {});

                    return (window.location.href = redirectUrl);
                }
            }
            RouterService.keycloak = keycloak;
            RouterService.decoded = keycloak.tokenParsed;
            localStorage.setItem("$swascan$refreshToken", keycloak.refreshToken);

            const exp = moment(new Date(keycloak.tokenParsed.exp * 1000));
            const now = moment(new Date());
            const expires_in = exp.diff(now, 'seconds');
            localStorage.setItem("$swascan$expires_in", expires_in.toString());

            RouterService.startRefreshTimeout();
            setAuthenticated(auth);
            app.config.globalProperties.$i18n = i18n;
            app.mount("#app");
        }, (err) => {
            console.error(err);
        });
    }
}, 500);
