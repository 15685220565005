export class TargetExtra {
    user;
    password;
    address;
    domain;
    config_file;
    credentials_file;

    constructor(data = {}) {
        this.user = data.user;
        this.password = data.password;
        this.address = data.address;
        this.domain = data.domain;
        this.config_file = data.config_file;
        this.credentials_file = data.credentials_file;
    }

    toJSON() {
        return {
            user: this.user,
            password: this.password,
            address: this.address,
            domain: this.domain,
            config_file: this.config_file,
            credentials_file: this.credentials_file
        };
    }
}
