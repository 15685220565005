<template>
    <div class="p-grid">
        <div class="p-col-12" v-if="loading">
            <div class="card p-shadow-7">
                <AppLoader :shortLines="[1, 2]" :longLines="[1, 2, 3, 4]" />
            </div>
        </div>
        <div class="p-col-12" v-if="!loading && isVisible">
            <div class="p-grid">
                <!-- TOTAL VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            white
                            p-shadow-24
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.vulnerabilities") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ data.vulns }}
                        </div>
                    </div>
                </div>
                <!-- TOTAL VULNERABILITIES -->

                <!-- CRITICAL VULNERABILITIES -->
                <!--<div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            danger
                            p-shadow-24
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.critical_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ data.critical }}
                        </div>
                    </div>
                </div>-->
                <!-- CRITICAL VULNERABILITIES -->

                <!-- HIGH VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            danger
                            p-shadow-7
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.high_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ data.high }}
                        </div>
                    </div>
                </div>
                <!-- HIGH VULNERABILITIES -->

                <!-- MEDIUM VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            warning
                            p-shadow-7
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.medium_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ data.medium }}
                        </div>
                    </div>
                </div>
                <!-- MEDIUM VULNERABILITIES -->

                <!-- LOW VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            primary
                            p-shadow-7
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.low_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ data.low }}
                        </div>
                    </div>
                </div>
                <!-- LOW VULNERABILITIES -->

                <!-- VULNERABILITY RISK DISTRIBUTION -->
                <div class="p-col-12 p-xl-4 p-md-6">
                    <div class="card p-shadow-7 card-rounded box-stretched">
                        <div class="p-text-center p-mb-5">
                            <strong>{{
                                $t(
                                    "pages.report.vulnerabilities_risk_distribution"
                                )
                            }}</strong>
                        </div>
                        <div class="chart-container">
                            <Chart
                                type="doughnut"
                                :height="300"
                                :data="
                                    charts.vulnerabilities_risk_distribution
                                        .data
                                "
                                :options="
                                    charts.vulnerabilities_risk_distribution
                                        .options
                                "
                            />
                        </div>
                    </div>
                </div>
                <!-- VULNERABILITY RISK DISTRIBUTION -->

                <!-- TOP 3 -->
                <div class="p-col-12 p-xl-4 p-md-6">
                    <div class="card p-shadow-7 card-rounded box-stretched">
                        <div class="p-text-center p-mb-5">
                            <strong>{{
                                $t("pages.report.top_vulnerable_host")
                            }}</strong>
                        </div>
                        <div class="chart-container">
                            <Chart
                                type="bar"
                                :height="300"
                                :data="charts.top_vulnerable_hosts.data"
                                :options="charts.top_vulnerable_hosts.options"
                            />
                        </div>
                    </div>
                </div>
                <!-- TOP 3 -->

                <!-- AVARAGE -->
                <div class="p-col-12 p-xl-4 p-md-6">
                    <div class="card p-shadow-7 card-rounded box-stretched">
                        <div class="p-text-center p-mb-5">
                            <strong>{{
                                $t(
                                    "pages.report.average_vulnerabilities_per_host"
                                )
                            }}</strong>
                        </div>
                        <div class="chart-container">
                            <Chart
                                type="bar"
                                :height="300"
                                :data="charts.average.data"
                                :options="charts.average.options"
                            />
                        </div>
                    </div>
                </div>
                <!-- AVARAGE -->

                <!-- AVARAGE -->
                <div class="p-col-12 p-xl-4 p-md-6">
                    <div class="card p-shadow-7 card-rounded box-stretched">
                        <div class="p-text-center p-mb-5">
                            <strong>{{
                                $t("pages.report.vulnerabilities_trend")
                            }}</strong>
                        </div>
                        <div class="chart-container">
                            <Chart
                                type="line"
                                :height="300"
                                :data="charts.vulnerabilities_trend.data"
                                :options="charts.vulnerabilities_trend.options"
                            />
                        </div>
                    </div>
                </div>
                <!-- AVARAGE -->

                <!-- CIA IMPACT -->
                <div class="p-col-12 p-xl-4 p-md-6">
                    <div class="card p-shadow-7 card-rounded box-stretched">
                        <div class="p-text-center p-mb-5">
                            <strong>{{
                                $t("pages.report.vulnerabilities_cia_impact")
                            }}</strong>
                        </div>
                        <div class="chart-container">
                            <Chart
                                type="bar"
                                :height="300"
                                :data="charts.cia_impact.data"
                                :options="charts.cia_impact.options"
                            />
                        </div>
                    </div>
                </div>
                <!-- CIA IMPACT -->

                <!-- CIA IMPACT LEVEL -->
                <div class="p-col-12 p-xl-4 p-md-6">
                    <div class="card p-shadow-7 card-rounded box-stretched">
                        <div class="p-text-center p-mb-5">
                            <strong>{{ $t("pages.report.cia_impact") }}</strong>
                        </div>
                        <div class="chart-container">
                            <Chart
                                type="bar"
                                :height="300"
                                :data="charts.cia_impact_level.data"
                                :options="charts.cia_impact_level.options"
                            />
                        </div>
                    </div>
                </div>
                <!-- CIA IMPACT LEVEL -->
            </div>
        </div>
    </div>
</template>

<script>
import { DateParser } from "../../../utilities";
export default {
    name: "NScanSummary",
    props: {
        loading: {
            type: Boolean,
            default: () => true,
        },
        isVisible: {
            type: Boolean,
            default: () => true,
        },
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            charts: {
                vulnerabilities_risk_distribution: {
                    data: {
                        labels: [
                            this.$t("general.critical"),
                            this.$t("general.high"),
                            this.$t("general.medium"),
                            this.$t("general.low"),
                        ],
                        datasets: [],
                    },
                    options: {
                        responsive: false,
                        legend: {
                            display: false,
                            position: "bottom",
                        },
                    },
                },
                cia_impact: {
                    data: {
                        labels: [
                            this.$t("general.confidentiality"),
                            this.$t("general.integrity"),
                            this.$t("general.availability"),
                        ],
                        datasets: [],
                    },
                    options: {
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        responsive: false,
                        legend: {
                            display: false,
                            position: "bottom",
                        },
                    },
                },
                average: {
                    data: {
                        labels: [
                            this.$t("general.total"),
                            this.$t("general.critical"),
                            this.$t("general.high"),
                            this.$t("general.medium"),
                            this.$t("general.low"),
                        ],
                        datasets: [],
                    },
                    options: {
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        responsive: false,
                        legend: {
                            display: false,
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                            xAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                        },
                    },
                },
                cia_impact_level: {
                    data: {
                        labels: [
                            this.$t("general.confidentiality"),
                            this.$t("general.integrity"),
                            this.$t("general.availability"),
                        ],
                        datasets: [],
                    },
                    options: {
                        responsive: false,
                        legend: {
                            display: false,
                            position: "bottom",
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    stacked: true,
                                },
                            ],
                            xAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    stacked: true,
                                },
                            ],
                        },
                    },
                },
                vulnerabilities_trend: {
                    data: {
                        labels: [],
                        datasets: [],
                    },
                    options: {
                        responsive: false,
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                            xAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                        },
                    },
                },
                top_vulnerable_hosts: {
                    data: {
                        labels: [],
                        datasets: [],
                    },
                    options: {
                        responsive: false,
                        legend: {
                            display: false,
                            position: "bottom",
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    stacked: true,
                                },
                            ],
                            xAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    stacked: true,
                                },
                            ],
                        },
                    },
                },
            },
        };
    },
    methods: {
        setChartsData() {
            if (this.data) {
                this.charts.vulnerabilities_risk_distribution.data.datasets = [
                    {
                        label: "",
                        backgroundColor: [
                            this.$appColors.darkRed,
                            this.$appColors.red,
                            this.$appColors.yellow,
                            this.$appColors.blue,
                        ],
                        data: [this.data.critical, this.data.high, this.data.medium, this.data.low],
                        borderColor: 'transparent',
                    },
                ];
                const hostsCount = this.data.hosts_count
                    ? parseInt(this.data.hosts_count, 10)
                    : 0;
                const totalAvarage =
                    Math.round(
                        (parseInt(this.data.vulns, 10) / hostsCount) * 100
                    ) / 100;
                const criticalAvarage =
                    Math.round(
                        (parseInt(this.data.critical, 10) / hostsCount) * 100
                    ) / 100;
                const highAvarage =
                    Math.round(
                        (parseInt(this.data.high, 10) / hostsCount) * 100
                    ) / 100;
                const mediumAvarage =
                    Math.round(
                        (parseInt(this.data.medium, 10) / hostsCount) * 100
                    ) / 100;
                const lowAvarage =
                    Math.round(
                        (parseInt(this.data.low, 10) / hostsCount) * 100
                    ) / 100;

                this.charts.average.data.datasets = [
                    {
                        backgroundColor: [
                            "#FFFFFF",
                            this.$appColors.darkRed,
                            this.$appColors.red,
                            this.$appColors.yellow,
                            this.$appColors.blue,
                        ],
                        data: [
                            totalAvarage,
                            criticalAvarage,
                            highAvarage,
                            mediumAvarage,
                            lowAvarage,
                        ],
                        borderColor: 'transparent',
                    },
                ];

                if (this.data.cia_data) {
                    this.charts.cia_impact.data.datasets = [
                        {
                            label: "",
                            backgroundColor: [
                                this.$appColors.confidentiality,
                                this.$appColors.integrity,
                                this.$appColors.availability,
                            ],
                            data: [
                                this.data.cia_data.confidentiality,
                                this.data.cia_data.integrity,
                                this.data.cia_data.availability,
                            ],
                            borderColor: 'transparent',
                        },
                    ];
                }

                const cia = ["confidentiality", "integrity", "availability"];
                const levels = ["C", "P", "N"];
                const high = [];
                const medium = [];
                const low = [];
                for (const key of cia) {
                    for (const level of levels) {
                        if (this.data[key] && this.data[key][level]) {
                            switch (level) {
                                case "C":
                                    high.push(this.data[key][level]);
                                    break;
                                case "P":
                                    medium.push(this.data[key][level]);
                                    break;
                                case "N":
                                    low.push(this.data[key][level]);
                                    break;
                            }
                        }
                    }
                }

                this.charts.cia_impact_level.data.datasets = [
                    /*{
                        label: this.$t("general.critical"),
                        backgroundColor: this.$appColors.darkRed,
                        data: critical,
                        borderColor: 'transparent',
                    },*/
                    {
                        label: this.$t("general.high"),
                        backgroundColor: this.$appColors.red,
                        data: high,
                        borderColor: 'transparent',
                    },
                    {
                        label: this.$t("general.medium"),
                        backgroundColor: this.$appColors.yellow,
                        data: medium,
                        borderColor: 'transparent',
                    },
                    {
                        label: this.$t("general.low"),
                        backgroundColor: this.$appColors.blue,
                        data: low,
                        borderColor: 'transparent',
                    },
                ];

                if (this.data.top_3_vuln) {
                    const labels = [];
                    const top3Dataset = {
                        critical: [],
                        high: [],
                        medium: [],
                        low: [],
                    };

                    for (const key in this.data.top_3_vuln) {
                        if (this.data.top_3_vuln[key].target) {
                            labels.push(this.data.top_3_vuln[key].target);
                            top3Dataset.critical.push(
                                this.data.top_3_vuln[key].CRITICAL
                            );
                            top3Dataset.high.push(
                                this.data.top_3_vuln[key].HIGH
                            );
                            top3Dataset.medium.push(
                                this.data.top_3_vuln[key].MEDIUM
                            );
                            top3Dataset.low.push(
                                this.data.top_3_vuln[key].LOW
                            );
                        }
                    }

                    this.charts.top_vulnerable_hosts.data.labels = labels;
                    this.charts.top_vulnerable_hosts.data.datasets = [
                        {
                            label: this.$t("general.critical"),
                            backgroundColor: this.$appColors.darkRed,
                            data: top3Dataset.critical,
                            borderColor: 'transparent',
                        },
                        {
                            label: this.$t("general.high"),
                            backgroundColor: this.$appColors.red,
                            data: top3Dataset.high,
                            borderColor: 'transparent',
                        },
                        {
                            label: this.$t("general.medium"),
                            backgroundColor: this.$appColors.yellow,
                            data: top3Dataset.medium,
                            borderColor: 'transparent',
                        },
                        {
                            label: this.$t("general.low"),
                            backgroundColor: this.$appColors.blue,
                            data: top3Dataset.low,
                            borderColor: 'transparent',
                        },
                    ];
                }

                if (this.data.history) {
                    const labels = [];
                    const history = {
                        total: [],
                        critical: [],
                        high: [],
                        medium: [],
                        low: [],
                    };

                    for (const row of this.data.history) {
                        labels.unshift(DateParser.parseDate(row.scan_start));
                        history.total.unshift(row.vulnerabilities);
                        history.critical.unshift(row.critical);
                        history.high.unshift(row.high);
                        history.medium.unshift(row.medium);
                        history.low.unshift(row.low);
                    }

                    labels.unshift("");
                    history.total.unshift(0);
                    history.critical.unshift(0);
                    history.high.unshift(0);
                    history.medium.unshift(0);
                    history.low.unshift(0);

                    this.charts.vulnerabilities_trend.data.labels = labels;
                    this.charts.vulnerabilities_trend.data.datasets = [
                        {
                            label: this.$t("general.total"),
                            data: history.total,
                            fill: false,
                            backgroundColor: "#FFFFFF",
                            borderColor: "#FFFFFF",
                        },
                        {
                            label: this.$t("general.critical"),
                            data: history.critical,
                            fill: false,
                            backgroundColor: this.$appColors.darkRed,
                            borderColor: this.$appColors.darkRed,
                        },
                        {
                            label: this.$t("general.high"),
                            data: history.high,
                            fill: false,
                            backgroundColor: this.$appColors.red,
                            borderColor: this.$appColors.red,
                        },
                        {
                            label: this.$t("general.medium"),
                            data: history.medium,
                            fill: false,
                            backgroundColor: this.$appColors.yellow,
                            borderColor: this.$appColors.yellow,
                        },
                        {
                            label: this.$t("general.low"),
                            data: history.low,
                            fill: false,
                            backgroundColor: this.$appColors.blue,
                            borderColor: this.$appColors.blue,
                        },
                    ];
                }
            }
        },
    },
    watch: {
        data: function (newVal, oldVal) {
            this.setChartsData();
        },
    },
    mounted() {
        this.setChartsData();
    },
};
</script>

<style lang="scss" scoped>
.chart-container {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>