<template>
    <div :class="containerClass">
        <div class="p-grid p-px-1 p-py-1">
            <div
                v-for="(tag, index) in tags"
                :key="tag"
                class="tag col-fixed p-my-1"
            >
                {{ tag }}
                <button class="delete" @click="removeTag(index)">x</button>
            </div>
            <div class="p-col">
                <input
                    v-if="!disabled"
                    class="tag-input"
                    v-model="newTag"
                    type="text"
                    :placeholder="placeholder"
                    @keydown.enter="onKeyEnter"
                    @keyup="onChange"
                    @keydown.space="onChange"
                    @paste="onPaste"
                    @focus="onFocus"
                    @blur="onBlur"
                />
            </div>
        </div>
        <div class="p-pt-1 p-pb-1" v-if="showError && errorLabel">
            <small class="error">{{ errorLabel }}</small>
        </div>
    </div>
</template>
<script>
import { ref } from "vue";
export default {
    setup() {
        const tags = ref([]);
        const newTag = ref("");
        return { tags, newTag };
    },
    props: {
        defaultTags: {
            type: Array,
            default: () => {
                return [];
            },
        },
        separators: {
            type: Array,
            default: () => [",", ";", "\n", " "],
        },
        allowDuplicate: {
            type: Boolean,
            default: () => false,
        },
        disabledStrings: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        placeholder: {
            type: String,
            default: () => "",
        },
        validation: {
            type: Function,
            default: null,
        },
        errorLabel: {
            type: String,
            default: () => null,
        },
    },
    emits: ["onTagsChange"],
    data() {
        return {
            containerClass: "tag-input p-inputtext",
            showError: false,
        };
    },
    oldValue: null,
    mounted() {
        this.tags = [...this.defaultTags];
    },
    methods: {
        addTag(item) {
            let tag = item.trim();
            if (this.separators.indexOf(tag.charAt(tag.length - 1)) > -1) {
                tag = tag.slice(0, -1);
            }
            if (tag.charAt(tag.length - 1) === "/") {
                tag = tag.slice(0, -1);
            }
            if (this.customValidation(tag)) {
                setTimeout(() => (this.showError = true), 300);
                return;
            }
            if (!this.existOrDisable(tag)) {
                this.tags.push(tag);
                this.$emit("onTagsChange", this.tags);
            }
        },
        clearNewTag() {
            this.newTag = "";
        },
        removeTag(index) {
            this.tags.splice(index, 1);
            this.$emit("onTagsChange", this.tags);
        },
        getFirstSeparator(clipboard) {
            for (const separator of this.separators) {
                if (clipboard.indexOf(separator) > -1) {
                    return separator;
                }
            }
        },
        onKeyEnter() {
            this.addTag(this.newTag);
            this.clearNewTag();
        },
        onPaste(event) {
            const clipboard = event.clipboardData.getData("text");
            const separator = this.getFirstSeparator(clipboard);
            const list = clipboard.split(separator);
            let change = false;
            let showError = false;
            for (const item of list) {
                if (item.trim().length === 0) {
                    continue;
                }
                if (this.customValidation(item.trim())) {
                    showError = true;
                    continue;
                }
                if (!this.existOrDisable(item.trim())) {
                    this.addTag(item);
                    change = true;
                }
            }
            if (change) {
                this.$emit("onTagsChange", this.tags);
                setTimeout(() => this.clearNewTag(), 10);
            }
            if (showError) {
                setTimeout(() => (this.showError = true), 300);
            }
        },
        onChange(event) {
            if (event.target.value !== this.oldValue) {
                this.showError = false;
            }
            this.oldValue = event.target.value;
            const separator = this.getFirstSeparator(this.newTag);
            if (separator) {
                const list = this.newTag.split(separator);
                if (this.customValidation(list[0].trim())) {
                    setTimeout(() => (this.showError = true), 300);
                    return;
                }
                if (!this.existOrDisable(list[0].trim())) {
                    this.addTag(list[0]);
                    this.clearNewTag();
                } else {
                    this.clearNewTag();
                }
            }
        },
        customValidation(tag) {
            if (this.validation) {
                const result = this.validation(tag);
                if (!result) {
                    return true;
                }
            }
        },
        existOrDisable(tag) {
            if (this.disabledStrings.indexOf(tag) > -1) {
                this.showError = true;
                return true;
            }
            if (this.allowDuplicate) {
                return false;
            }
            for (const item of this.tags) {
                if (tag.toLowerCase() === item.toLowerCase()) {
                    return true;
                }
            }

            return false;
        },
        onFocus() {
            this.containerClass = "tag-input p-inputtext tags-container";
        },
        onBlur() {
            this.newTag = "";
            this.containerClass = "tag-input p-inputtext";
            if (this.showError && this.tags.length > 0) {
                this.showError = false;
            }
        },
        updateTags(tags) {
            this.tags = tags
        }
    },
};
</script>

<style scoped>
.tag {
    height: 30px;
    background: #03a9f4;
    margin: 5px 2px;
    padding: 5px;
    border-radius: 4px;
    color: white;
    white-space: nowrap;
    transition: 0.1s ease background;
}
.tag-input {
    width: 100%;
    padding: 7px 5px 0px 5px;
    margin: 0 auto;
    background-color: transparent;
    border: none;
}
.tag-input:focus {
    outline: none;
    /* border-bottom: 1px solid #03a9f4; */
}
.delete {
    color: white;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}
.tags-container {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #e3f3fe;
    border: 1px solid #56a1f9;
}
.error {
    color: #cd1619;
    font-weight: bold;
}
</style>