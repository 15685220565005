<template>
    <div class="question p-col-12">
        <h4>
            {{ `(${questionItem.id})` }} <span v-html="questionText"></span> 
            <small class="cursor-pointer text-primary"
                v-if="questionHelp"
                @click="showHelper">
                <i class="pi pi-question-circle"></i>
            </small>
        </h4>
        <InputText
            v-model.number="value"
            type="number"
            :step="0.1"
            :min="min"
            :max="max"
            :disabled="updating"
            class="p-mb-2"
            style="width: 15rem" />
        <Slider
            v-model="value"
            :step="0.1"
            :min="min"
            :max="max"
            :disabled="updating" />

        <div class="p-mt-2"
            v-if="hasNote">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="note">Note</label>
                            <Textarea
                                v-model="note"
                                id="note"
                                :placeholder="''"
                                rows="5"
                                cols="30"
                                :autoResize="true"
                                :disabled="updating" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="p-my-4" />
    </div>
</template>

<script>
import { useStore } from "@/store";

export default {
    name: "Question",
    setup() {
        return {
            state: useStore().state,
            setState: useStore().setState,
        };
    },
    created() {
    },
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
        question: {
            type: Object,
            default: function () {
                return {}
            },
        },
        index: {
            type: Number,
            default: null,
        },
        updating: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
        };
    },
    mounted() {
        this.$nextTick(() => {
            if (this.isEmptyObject(this.answer)) {
                this.value = this.defaultValue
            }
        })
    },
    updated() {},
    watch: {
    },
    computed: {
        questionItem () {
            return this.question.question
        },
        answer: {
            get () {
                return this.question.answer
            },
            set (value) {
                this.$emit('onValueChange', this.question, value)
            }
        },
        questionText () {
            return this.questionItem.text[this.$i18n.locale]
        },
        questionHelp () {
            return this.questionItem.help[this.$i18n.locale]
        },
        min () {
            return this.questionItem.min
        },
        max () {
            return this.questionItem.max
        },
        defaultValue () {
            return this.questionItem.default
        },
        hasNote () {
            return this.questionItem.note
        },
        value: {
            get () {
                if (this.isEmptyObject(this.answer)) {
                    return null
                }
                return this.answer.values[0]
            },
            set (value) {
                this.answer = {
                    id: this.questionItem.id,
                    note: this.note,
                    values: [value]
                }
            }
        },
        note: {
            get () {
                if (this.isEmptyObject(this.answer)) {
                    return null
                }
                return this.answer.note
            },
            set (value) {
                this.answer = {
                    id: this.questionItem.id,
                    note: value,
                    values: [this.value]
                }
            }
        }
    },
    methods: {
        showHelper () {
            this.$swal({
                title: null,
                html: this.questionHelp,
                icon: 'info',
                showConfirmButton: true,
                showDenyButton: false,
            })
        },
        isEmptyObject (obj) {
            return obj === null || (Object.keys(obj).length === 0 && obj.constructor === Object)
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
