<template>
  <div>
    <div class="swa-header">
        <h3>{{ $tc("general.Target", 2) }} {{ isLite ? "(Lite)" : "" }}</h3>
        <div>
          <span class="p-input-icon-left p-mx-2">
              <i class="pi pi-search" />
              <InputText
                  v-model="filters['global']"
                  :placeholder="$t('general.global_search')"
                  @change="onFilter()"
              />
          </span>
          <Button
            label="Primary"
            @click="goToAddTarget(engine, state.licenses)">
            {{ $t("general.add_new") }}
          </Button>
        </div>
    </div>
    <DataTable
      :value="targets"
      class="p-datatable-sm p-datatable-responsive"
      :loading="loading"
      :lazy="true"
      :rows="page_size"
      :totalRecords="total_records"
      :paginator="paginator"
      :rowHover="true"
      :filters="filters"
      @page="onPageChange($event)"
    >
      <Column
        v-for="col of columns"
        :key="col.toString()"
        :field="col"
        :header="getHeaderByCol(col)"
      >
        <template #body="slotProps">
          <div v-if="col === 'report'">
            <Button
              :label="$t('general.show_reports')"
              class="p-mr-2 p-my-auto p-button-outlined"
              @click="onShowReports(slotProps.data)"
            >
              {{ $t("general.show_reports") }}
            </Button>
          </div>
          <div v-else-if="col === 'run_test'">
            <Button
              :disabled="slotProps.data.id === selectedTargetId"
              :loading="slotProps.data.id === selectedTargetId"
              :label="$t('general.make_new_test')"
              class="p-mr-2 p-my-auto p-button-outlined p-button-success"
              @click="handleSelectedTarget(slotProps.data)"
            >
              {{ $t("general.make_new_test") }}
            </Button>
          </div>
          <div v-else-if="col === 'created_at'">
            {{ formatDate(slotProps.data.created_at) }}
          </div>
          <div v-else-if="col === 'id'">
            <Button
              icon="pi pi-pencil"
              class="p-mr-2 p-my-auto p-button-rounded"
              @click="edit(slotProps.data)"
            ></Button>
            <Button
              icon="pi pi-trash"
              class="p-mr-2 p-my-auto p-button-rounded p-button-danger"
              @click="askForDelete(slotProps.data.id)"
            ></Button>
          </div>
          <div v-else-if="col === 'target'">
            <div class="max-height-80 with-overflow-y">
              <div v-for="item in getExtraTargets(slotProps.data)" :key="item">
                {{ item }}
              </div>
            </div>
          </div>
          <div v-else>
            {{ slotProps.data[`${col}`] }}
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { ENV_VARS } from "@/env_vars";
import { DateParser } from "@/utilities/DateParser";
import { data, methods, watch } from "../../commons/TaskFunctions";
import { targetsMethods } from "@/commons";
import { Target } from "../../models";
import { ReportService, TargetService, TaskService } from "../../services";
import { useStore } from "../../store";
import {
  BaseNewTargetRoute,
  Engine,
  ENGINE_DESCRIPTIONS,
} from "../../utilities";

export default {
  name: "TargetsTable",
  setup() {
    return {
      state: useStore().state,
      setState: useStore().setState,
      subtractScanToLicense: useStore().subtractScanToLicense,
    };
  },
  targetService: null,
  taskService: null,
  reportService: null,
  created() {
    this.targetService = new TargetService();
    this.taskService = new TaskService();
    this.reportService = new ReportService();
  },
  emits: ["onEdit", "onPageChange"],
  props: {
    engine: {
      type: String,
      default: null,
    },
    paginator: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: function () {
        return [
          "name",
          "target",
          "engine_description",
          "business",
          "created_at",
          "report",
          "run_test",
          "id",
        ];
      },
    },
    isLite: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      targetId: null,
      display: false,
      targets: null,
      loading: true,
      page: 0,
      page_size: 10,
      total_records: 0,
      filters: {},
      ...data,
    };
  },
  mounted() {
    this.getTargets();
    this.setState({
      addTargetItems: [BaseNewTargetRoute(this.engine)],
    });
  },
  updated() {},
  watch: {
    ...watch,
  },
  computed: {
    tableHead() {
      let title;
      switch (this.engine) {
        case Engine.DTI:
          title = this.$t("pages.targets.table_head_engine", {
            engine: ENGINE_DESCRIPTIONS.dti,
          });
          break;
        case Engine.WEB_APP_SCAN:
          title = this.$t("pages.targets.table_head_engine", {
            engine: ENGINE_DESCRIPTIONS.webapp,
          });
          break;
        case Engine.CODE_REVIEW:
          title = this.$t("pages.targets.table_head_engine", {
            engine: ENGINE_DESCRIPTIONS.code_review,
          });
          break;
        case Engine.NETWORK_SCAN:
          title = this.$t("pages.targets.table_head_engine", {
            engine: ENGINE_DESCRIPTIONS.nscan,
          });
          break;
        case Engine.GDPR_ASSESSMENT:
          title = this.$t("pages.targets.table_head_engine", {
            engine: ENGINE_DESCRIPTIONS.gdpr,
          });
          break;
        case Engine.ICT_ASSESSMENT:
          title = this.$t("pages.targets.table_head_engine", {
            engine: ENGINE_DESCRIPTIONS.ict,
          });
          break;
        default:
          title = this.$t("pages.targets.table_head");
          break;
      }

      return title;
    },
  },
  methods: {
    async getTargets(engine) {
      if (!engine) {
        engine = this.engine;
      }
      this.loading = true;
      try {
        let license;
        if (engine !== "all") {
          const row = this.state.licenses.find((l) => l.engine === engine);
          if (row) {
            license = row;
          } else {
            setTimeout(() => this.getTargets(), 500);

            return;
          }
        }
        const args = {
          params: {
            page: this.page + 1,
            page_size: this.page_size,
          },
        };
        if (license) {
          args.params.engine = license.api;
        }

        // SEARCH
        const search =
            this.filters.global && this.filters.global.length > 0
                ? `${this.filters.global}`
                : null;
        if (search) {
          args.params.name = search
        }

        const result = await this.targetService.get(null, args);
        if (!result) {
          return;
        }
        const targets = result.data.results.map((l) => new Target(l));
        this.targets = targets;
        this.total_records = result.data.count;
        this.$emit("onPageChange");
      } catch (error) {
        console.error(error);
        this.targets = [];
      }
      this.loading = false;
    },
    onFilter() {
      this.onPageChange({
        page: 0
      });
    },
    edit(data) {
      this.$emit("onEdit", data);
    },
    close() {
      this.$swal.close();
      this.targetId = null;
      this.setState({
        addTargetItems: [BaseNewTargetRoute(this.engine)],
      });
      //this.$router.replace(Routes.TARGETS + this.engine);
      this.$router.replace({
        name: "targets",
        params: { engine: this.engine },
      });
    },
    askForDelete(targetId) {
      this.$swal({
        title: this.$t("general.confirmation_required"),
        html: this.$t("pages.targets.delete_question"),
        showConfirmButton: true,
        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.targetId = targetId;
          this.$swal({
            title: this.$t("general.confirmation_required"),
            html: this.$t("pages.targets.delete_question"),
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              this.$swal.showLoading();
            },
            didOpen: () => {
              setTimeout(() => {
                this.deleteTarget();
              }, 500);
            },
          });
        } else {
          this.$swal.close();
        }
      });
    },
    async deleteTarget() {
      this.loading = true;
      try {
        await this.targetService.delete(this.targetId, {
          baseUrl: ENV_VARS.VUE_APP_BACKEND_BASE_URL,
          addSlash: true,
        });
        this.getTargets();
        this.askForDeleteRelatedReports();
      } catch (error) {
        console.error(error);
        this.close();
        this.loading = false;
      }
    },
    askForDeleteRelatedReports() {
      this.$swal({
        title: this.$t("general.confirmation_required"),
        html: this.$t("pages.targets.delete_related_reports"),
        showConfirmButton: true,
        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: this.$t("general.confirmation_required"),
            html: this.$t("pages.targets.delete_related_reports"),
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              this.$swal.showLoading();
            },
            didOpen: () => {
              setTimeout(() => {
                this.deleteRelatedReports();
              }, 500);
            },
          });
        } else {
          this.$swal.close();
        }
      });
    },
    async deleteRelatedReports() {
      this.loading = true;
      try {
        const response = await this.taskService.delete(null, {
          params: { target_id: this.targetId },
        });
        const tasks_ids = response.data.tasks_ids || [];
        await this.reportService.delete(null, {
          data: { reports_ids: tasks_ids },
        });
        this.loading = false;
        this.close();
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.close();
      }
    },
    getHeaderByCol(col) {
      if (col === "target") return this.$t("general.Target");
      if (col === "business")
        return this.$t("pages.new_target.business_categories");
      if (col === "report") return this.$tc("general.Report", 2);
      if (col === "id") return this.$t("general.actions");
      return this.$t(`general.${col}`);
    },
    isColSortable(col) {
      return !["report", "run_test", "id"].includes(col);
    },
    onPageChange(event) {
      this.page = event.page;
      this.getTargets();
    },
    formatDate(date) {
      return DateParser.parseDate(date);
    },
    getExtraTargets(item) {
      let targets = item.target.split(",");
      if (item.extra) {
        if ("extra_targets" in item.extra) {
          targets = [...targets, ...item.extra.extra_targets.split(",")];
        }
      }
      return targets;
    },
    ...methods,
    ...targetsMethods,
  },
};
</script>

<style lang="scss" scoped>
@import "../../pages/Targets/Targets.scss";
</style>
