import { DateParser, ENGINE_DESCRIPTIONS, parseEngineByUrl } from "../utilities";

export class Target {
    id;
    name;
    engine;
    target;
    created_at;
    last_modified;
    user_id;
    range;
    business;

    start_address;
    project_name;
    company_name;
    business_name;
    address;
    email;

    extra;
    has_vpn;

    constructor(data = {}) {
        this.id = data.id;
        this.name = data.name;
        this.engine = data.engine;
        this.target = data.target;
        this.created_at = data.created_at;
        this.last_modified = data.last_modified;
        this.user_id = data.user_id;
        this.business = data.business;
        this.extra = data.extra;
        this.has_vpn = data.has_vpn;
        // this.range = data.range || false;
    }

    get engine_abbreviation() {
        const array = parseEngineByUrl(this.engine);
        return array[1];
    }

    get engine_description() {
        const array = parseEngineByUrl(this.engine);
        return ENGINE_DESCRIPTIONS[array[1]];
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            engine: this.engine,
            target: this.target,
            created_at: this.created_at,
            last_modified: this.last_modified,
            user_id: this.user_id,
            business: this.business,
            extra: this.extra,
            // range: this.range || false,
        };
    }

    last_update() {
        if (!this.last_modified) return "";

        return DateParser.parseDate(this.last_modified);
    }

    creation_date() {
        if (!this.created_at) return "";

        return DateParser.parseDate(this.created_at);
    }
}
