import { baseOptions, baseSlide, colors } from "../constants";

export default (pptx, obj) => {
    var slide = baseSlide(pptx, colors.white, colors.blue);
    slide.addShape(pptx.ShapeType.rect, {
        fill: colors.darkBlue,
        x: 0,
        y: 3,
        w: "100%",
        h: 1.5,
    });

    slide.addImage({
        path: "assets/images/tinexta_swascan.png",
        x: "46%",
        y: "5%",
        w: 0.6,
        h: 0.9,
        type: "contain",
    });

    slide.addText("Sicurezza Predittiva", {
        ...baseOptions.title,
        x: 0,
        y: "23%",
        w: "100%",
        align: "center",
        fontSize: 30,
        color: colors.blue,
    });

    slide.addText("MISURA LA TUA ESPOSIZIONE CYBER", {
        ...baseOptions.title,
        bold: true,
        x: 0,
        y: "32%",
        w: "100%",
        align: "center",
        fontSize: 30,
        color: colors.blue,
    });

    slide.addText(obj.target, {
        ...baseOptions.title,
        x: 0,
        y: "41%",
        w: "100%",
        align: "center",
        fontSize: 30,
        color: colors.blue,
    });

    slide.addImage({
        path: "assets/images/swascan_tinexta_white.png",
        x: "47%",
        y: 3.3,
        w: 0.6,
        h: 0.9,
        type: "contain",
    });
};
