export const chartsColors = {
    red: "#cd1619",
    darkRed: "#8c182a",
    orange: "#FFB622",
    yellow: "#FFB622",
    green: "#04B87C",
    blue: "#057BC1",
    darkBlue: "#051b33",
    purple: "#9c27b0",
    grey: "#b2b2b2",
    mainbox: "rgb(48, 48, 48)",
    confidentiality: "#8d37ff",
    integrity: "#e73eff",
    availability: "#ff33ae",
    breaches: "#1955c9",
    ip: "#3a57b8",
    darkWeb: "#4169e1",
    botnet: "#5577f1",
};

export const colors = {
    darkBlue: "06215c",
    blue: "06215c",
    blueLight: "00b0f0",
    white: "FFFFFF",
    darkRed: "#8c182a",
    red: "cd1619",
    yellow: "FFB622",
    black: "000000",
    green: "04B87C",
};

export const baseOptions = {
    title: {
        x: 3.5,
        y: "8%",
        w: 5,
        h: 1,
        color: colors.white,
        fontSize: 28,
        align: "left",
        valign: "top",
        fontFace: "Segoe UI",
    },
    text: {
        x: 3.5,
        y: "17%",
        w: 5,
        h: 4.5,
        color: colors.white,
        fontSize: 9,
        align: "justify",
        valign: "top",
        fontFace: "Segoe UI",
        lineSpacing: 13,
    },
};

export const swascanSite = {
    x: "10%",
    y: "94%",
    fontSize: 8,
    color: colors.blue,
};

export const swascanMail = {
    x: "91%",
    y: "90%",
    w: 1.3,
    h: 0.1,
    rotate: 90,
    fontSize: 8,
    color: colors.blue,
};

//* Base Functions
export const convertCanvasToImage = (canvas) => {
    const image = new Image();
    image.src = canvas.toDataURL("image/png");
    return image;
};

export const baseSlide = (pptx, fillColor, color) => {
    const slide = pptx.addSlide();
    slide.bkgd = fillColor;
    slide.color = color;

    return slide;
};
