import { baseOptions, baseSlide, colors } from "../constants";

export default (pptx) => {
    var slide = baseSlide(pptx, colors.white, colors.blue);

    slide.addShape(pptx.ShapeType.rect, {
        fill: colors.darkBlue,
        x: 0,
        y: 0,
        w: "100%",
        h: 4,
    });

    //* TITLE
    slide.addText("SWASCAN", {
        ...baseOptions.title,
        bold: true,
        x: 2,
        y: "10%",
        fontSize: 24,
    });
    slide.addText("THE", {
        ...baseOptions.title,
        x: 2,
        y: "16%",
        fontSize: 24,
    });
    slide.addText("FIRST", {
        ...baseOptions.title,
        x: 2,
        y: "22%",
        fontSize: 24,
    });
    slide.addText("CLOUD", {
        ...baseOptions.title,
        x: 2,
        y: "28%",
        fontSize: 24,
    });
    slide.addText("CYBER", {
        ...baseOptions.title,
        x: 2,
        y: "34%",
        fontSize: 24,
    });
    slide.addText("SECURITY", {
        ...baseOptions.title,
        bold: true,
        x: 2,
        y: "40%",
        fontSize: 24,
    });
    slide.addText("PLATFORM", {
        ...baseOptions.title,
        bold: true,
        x: 2,
        y: "46%",
        fontSize: 24,
    });
    slide.addImage({
        path: "assets/images/swascan_tinexta_white.png",
        x: 1.2,
        y: "37%",
        w: 0.6,
        h: 0.9,
        type: "contain",
    });
    //* TITLE

    //* SEPARATOR
    slide.addShape(pptx.ShapeType.rect, {
        fill: colors.white,
        x: "50%",
        y: 0.5,
        w: 0.01,
        h: 2.5,
    });
    //* SEPARATOR

    //* RIGHT
    slide.addText("The First Cyber Security", {
        ...baseOptions.text,
        bold: true,
        x: "52%",
        y: "10%",
        fontSize: 12,
    });
    slide.addText("Testing Platform", {
        ...baseOptions.text,
        bold: true,
        x: "52%",
        y: "13%",
        fontSize: 12,
    });

    slide.addText("Cloud or On Premise", {
        ...baseOptions.text,
        bold: true,
        italic: true,
        x: "52%",
        y: "25%",
    });
    slide.addText("Platform", {
        ...baseOptions.text,
        italic: true,
        x: "52%",
        y: "27%",
    });

    slide.addText("Cyber Security", {
        ...baseOptions.text,
        x: "52%",
        y: "43%",
        fontSize: 12,
    });
    slide.addText("Competence Services", {
        ...baseOptions.text,
        bold: true,
        x: "52%",
        y: "46%",
        fontSize: 12,
    });
    //* RIGHT

    //* PARTNER
    slide.addImage({
        path: "assets/images/partner.png",
        x: "82%",
        y: "10%",
        w: 1.5,
        h: 3,
        type: "contain",
    });
    //* PARTNER

    slide.addText("Domain Threat", {
        ...baseOptions.title,
        x: 2,
        y: "80%",
        fontSize: 24,
        color: colors.red,
    });
    slide.addText("Intelligence", {
        ...baseOptions.title,
        bold: true,
        x: 2,
        y: "86%",
        fontSize: 24,
        color: colors.red,
    });
    
};