import { BaseService } from "./BaseService";
import { Routes } from "./RouterService/Routes";
import { ENV_VARS } from "@/env_vars";

export class TicketService extends BaseService {
  constructor() {
    super();
    this.BASE_URL = ENV_VARS.VUE_APP_REST_BASE_URL;
    this.BASE_API = Routes.TICKETS;
  }
}
