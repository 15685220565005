<template>
    <div class="p-grid">
        <div class="p-col-12" v-if="loading && records.length === 0 && isFull">
            <div class="card p-shadow-7">
                <AppLoader :shortLines="[1, 2]" :longLines="[1, 2, 3, 4]" />
            </div>
        </div>
        <div class="p-col-12" v-if="!loading && isVisible && isFull">
            <div class="card p-shadow-7">
                <div class="swa-header">
                    <h3>{{ $t("pages.report.host_list") }}</h3>
                </div>
                <DataTable
                    class="p-datatable-sm p-datatable-responsive"
                    expandedRowIcon="pi pi-search-minus"
                    collapsedRowIcon="pi pi-search-plus"
                    :sortField="hostSortField"
                    :sortOrder="hostSortOrder"
                    :value="records"
                    :loading="loading"
                    :rowHover="true"
                    :filters="filters"
                    :totalRecords="count"
                    :rows="page_size"
                    :paginator="true"
                    v-model:expandedRows="expandedRows"
                    @row-expand="onRowExpand"
                >
                    <Column 
                        field="index" 
                        :sortable="true"
                        :header="$t('general.ip_address')">
                        <template #body="slotProps">
                            {{ slotProps.data.ip }}
                        </template>
                    </Column>
                    <Column :header="$t('pages.report.os')">
                        <template #body="slotProps">
                            <div class="max-height-80 with-overflow-y"
                                v-if="slotProps.data.guess_os">
                                <div v-if="slotProps.data.guess_os.ostypes && slotProps.data.guess_os.ostypes.length > 0">
                                    <span>{{ $t('general.services_detection') }}</span>
                                    <ul>
                                        <li v-for="(item, index) in subList(slotProps.data.guess_os.ostypes, 3)"
                                            :key="'guess-' + index">
                                            {{ item.ostype }}
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="slotProps.data.guess_os.osprobes && slotProps.data.guess_os.osprobes.length > 0">
                                    <span>{{ $t('general.probes_detection') }}</span>
                                    <ul>
                                        <li v-for="(item, index) in subList(slotProps.data.guess_os.osprobes, 3)"
                                            :key="'probes-' + index">
                                            {{ item.os_name }}<br>{{ $t('general.accuracy') }}: {{ item.accuracy }}%
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column
                        field="open_ports"
                        :sortable="true"
                        :header="$t('pages.report.open_ports')"
                    />
                    <Column
                        :expander="true"
                        :header="$tc('general.detail', 1)"
                        headerStyle="width: 100px"
                    />

                    <template #expansion="slotProps">
                        <div class="p-grid full-width">
                            <div
                                class="p-col-12"
                                v-if="
                                    slotProps.data.ports &&
                                    slotProps.data.ports.length > 0
                                "
                            >
                                <DataTable
                                    class="
                                        p-datatable-sm p-datatable-responsive
                                    "
                                    :value="slotProps.data.ports"
                                    :rowHover="true"
                                    :totalRecords="slotProps.data.ports.length"
                                    :rows="10"
                                    :paginator="true"
                                >
                                    <template #header>
                                        <div class="table-header">
                                            <div class="swa-header">
                                                <h3>
                                                    {{ $tc("general.port", 2) }}
                                                </h3>
                                            </div>
                                        </div>
                                    </template>
                                    <Column
                                        field="protocol"
                                        :header="$t('pages.report.protocol')"
                                        headerClass="inner-head"
                                    />
                                    <Column
                                        field="portid"
                                        :header="$t('general.port')"
                                        headerClass="inner-head"
                                    />
                                    <Column
                                        field="state.state"
                                        :header="
                                            $t('pages.report.port_status.title')
                                        "
                                        headerClass="inner-head"
                                    >
                                        <template #body="nmapSlotProps">
                                            <div>
                                                {{
                                                    $t(
                                                        "pages.report.port_status." +
                                                            nmapSlotProps.data
                                                                .state.state
                                                    )
                                                }}
                                            </div>
                                        </template>
                                    </Column>
                                    <Column
                                        field="service.name"
                                        :header="$t('pages.report.service')"
                                        headerClass="inner-head"
                                    >
                                        <template #body="nmapSlotProps">
                                            <div>
                                                {{ nmapSlotProps.data.service.tunnel ? `${nmapSlotProps.data.service.name}/${nmapSlotProps.data.service.tunnel}` : `${nmapSlotProps.data.service.name}` }}
                                            </div>
                                        </template>
                                    </Column>
                                    <Column
                                        field="service.product"
                                        :header="$t('pages.report.product')"
                                        headerClass="inner-head"
                                    />
                                    <Column
                                        field="service.version"
                                        :header="$t('pages.report.version')"
                                        headerClass="inner-head"
                                    />
                                </DataTable>
                            </div>
                            <!-- <div class="p-col-12">
                                <DataTable
                                    class="
                                        p-datatable-sm p-datatable-responsive
                                    "
                                    :value="slotProps.data.detail"
                                    :rowHover="true"
                                    :totalRecords="slotProps.data.detail.length"
                                    :rows="10"
                                    :paginator="true"
                                >
                                    <template #header>
                                        <div class="table-header">
                                            <div class="swa-header">
                                                <h3>
                                                    {{
                                                        $tc("general.detail", 2)
                                                    }}
                                                </h3>
                                            </div>
                                        </div>
                                    </template>
                                    <Column
                                        field="name"
                                        :header="$t('general.name')"
                                        headerClass="inner-head"
                                    />
                                    <Column
                                        field="value"
                                        :header="$t('general.value')"
                                        headerClass="inner-head"
                                    />
                                    <Column
                                        field="source.description"
                                        :header="$t('general.description')"
                                        headerClass="inner-head"
                                    />
                                </DataTable>
                            </div> -->
                            <div
                                class="p-col-12"
                                v-if="
                                    vulnerabilities[slotProps.data.ip] &&
                                    vulnerabilities[slotProps.data.ip].count > 0
                                "
                            >
                                <NScanVulnerabilitiesTable
                                    :ip="slotProps.data.ip"
                                    :loading="
                                        vulnerabilities[slotProps.data.ip]
                                            .loading
                                    "
                                    :vulnerabilities="
                                        vulnerabilities[slotProps.data.ip]
                                    "
                                    :vulnerabilitiesSummary="
                                        vulnerabilitiesSummary[
                                            slotProps.data.ip
                                        ]
                                    "
                                    @onPageChange="onPageChange"
                                />
                            </div>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
        <!-- / UPGRADE MESSAGE -->
        <UpgradeComponent
            v-if="!isFull && !loading"
            :title="$t('pages.report.host_list')"
            className="p-col-12"
        />
        <!-- / UPGRADE MESSAGE -->
    </div>
</template>

<script>
import { DateParser } from "../../../utilities";

export default {
    name: "NScanHostsTable",
    props: {
        count: {
            type: Number,
            default: () => 0,
        },
        records: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: () => true,
        },
        isVisible: {
            type: Boolean,
            default: () => true,
        },
        vulnerabilities: {
            type: Object,
            default: () => {
                return {};
            },
        },
        vulnerabilitiesSummary: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isFull: {
            type: Boolean,
            default: () => true,
        },
    },
    expandedIp: null,
    data() {
        return {
            filters: {},
            expandedRows: [],
            expandedRowsVulns: [],
            page_size: 10,
            sortField: "severity",
            sortOrder: -1,
            page: 0,
            hostSortField: 'index',
            hostSortOrder: 1,
        };
    },
    emits: ["onRowExpand"],
    watch: {
        vulnerabilities: {
            handler(_) {},
            immediate: true,
            deep: true,
        },
        vulnerabilitiesSummary: {
            handler(_) {},
            immediate: true,
            deep: true,
        },
    },
    methods: {
        onFilterChange(event) {},
        onPageChange(event) {
            console.log("NScanHostsTable", {
                ...event,
            });
            this.$emit("onRowExpand", event);
        },
        onRowExpand(event) {
            if (!event.data.open_ports || event.data.open_ports.length === 0) {
                this.expandedRows = [];
                return;
            }
            this.$emit("onRowExpand", {
                ip: event.data.ip,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
                page: this.page,
                page_size: this.page_size,
            });
        },
        getOS(host) {
            let index = -1;
            let accuracy = 0;
            if (!host.os || !host.os.osmatch || host.os.osmatch.length === 0) {
                return "";
            }
            for (let i = 0; i < host.os.osmatch.length; i++) {
                const item = host.os.osmatch[i];
                if (item.accuracy > accuracy) {
                    index = i;
                    accuracy = item.accuracy;
                }
            }

            return index > -1 ? host.os.osmatch[index].name : "";
        },
        getDate(date) {
            return DateParser.parseDate(date);
        },
        onFilter(filter) {},
        subList(list, limit) {
            return list.slice(0, limit);
        },
    },
};
</script>
<style lang="scss" scoped>
.title {
    font-size: 18px;
    font-weight: bold;
}
</style>