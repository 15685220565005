<template>
    <!-- Reports -->
    <div class="full-width full-height">
        <div class="card p-shadow-7 full-height">
            <div class="swa-header">
                <h3>{{ title || $tc("general.Report", 2) }} {{ isLite ? '(Lite)' : '' }}</h3>
                <Button
                    label="Primary"
                    @click="goToPage(addButtonLink)"
                    v-if="addButtonLink">
                    {{ addButtonText ? addButtonText : $t("general.add_new") }}
                </Button>
            </div>
            <div v-if="reports.length === 0 && !loading">
                <h5>{{ $t("general.no_reports") }}</h5>
            </div>
            <DataTable
                v-if="reports.length > 0 || (loading && reports.length === 0)"
                :value="reports"
                class="p-datatable-sm p-datatable-responsive"
                dataKey="id"
                :sortOrder="1"
                :loading="loading"
                :rows="page_size"
                :totalRecords="total_records"
                :paginator="paginator"
                :rowHover="true"
                :filters="filters"
                :lazy="true"
                @page="onPageChange($event)"
            >
                <!--<template #header>
                    <div class="table-header">
                        <h4 class="title">&nbsp;</h4>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText
                                v-model="filters['global']"
                                :placeholder="$t('general.global_search')"
                            />
                        </span>
                    </div>
                </template>-->
                <Column
                    v-for="col of columns"
                    :key="col.toString()"
                    :field="col"
                    :header="getHeaderByCol(col)"
                >
                    <template #body="slotProps">
                        <div v-if="col === 'status'">
                            <ProgressBar
                                v-if="
                                    isRunning(slotProps.data) &&
                                    slotProps.data.percentage
                                "
                                style="height: 1.5em"
                                :value="slotProps.data.percentage"
                            />
                            <ProgressBar
                                v-if="
                                    isRunning(slotProps.data) &&
                                    !slotProps.data.percentage
                                "
                                mode="indeterminate"
                                style="height: 1.5em"
                            />
                            <Tag
                                v-if="
                                    slotProps.data.status ===
                                        Task.STATUSES.RUNNING &&
                                    slotProps.data.stop_requested
                                "
                                :severity="'warning'"
                                :value="$t('statuses.STOPPING')"
                            />
                            <Tag
                                v-if="
                                    slotProps.data.status ===
                                        Task.STATUSES.SUCCESS ||
                                    slotProps.data.status ===
                                        Task.STATUSES.FAILURE ||
                                    slotProps.data.status ===
                                        Task.STATUSES.DELETED ||
                                    slotProps.data.status ===
                                        Task.STATUSES.STOPPED
                                "
                                :severity="
                                    slotProps.data.status ===
                                    Task.STATUSES.SUCCESS
                                        ? 'success'
                                        : (slotProps.data.status === Task.STATUSES.STOPPED ? 'warning' : 'danger')
                                "
                                :value="$t('statuses.' + slotProps.data.status)"
                            />
                        </div>
                        <div v-else-if="col === 'id'">
                            <Button
                                v-if="
                                    canView(slotProps.data)
                                "
                                :icon="viewIcon(slotProps.data)"
                                class="p-mr-2 p-my-auto p-button-rounded"
                                @click="navigateTo(slotProps.data)"
                            ></Button>
                            <Button
                                v-if="
                                    canDelete(slotProps.data)
                                "
                                icon="pi pi-trash"
                                class="
                                    p-mr-2
                                    p-my-auto
                                    p-button-rounded
                                    p-button-danger
                                "
                                @click="askForDelete(slotProps.data.task_id)"
                            ></Button>
                            <Button
                                v-if="
                                    slotProps.data.status ===
                                        Task.STATUSES.RUNNING &&
                                    !slotProps.data.stop_requested
                                "
                                icon="pi pi-ban"
                                class="
                                    p-mr-2
                                    p-my-auto
                                    p-button-rounded
                                    p-button-warning
                                "
                                @click="askForStop(slotProps.data.task_id)"
                            ></Button>
                        </div>
                        <span v-else>{{ slotProps.data[`${col}`] }}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <!-- Reports -->
</template>


<script>
import { useStore } from "../../store";
import { ReportService, TaskService } from "../../services";
import { License, Task } from "../../models";
import { Engine } from '@/utilities';

export default {
    name: "ReportsTable",
    setup() {
        return {
            state: useStore().state,
            setState: useStore().setState,
        };
    },
    reportService: null,
    taskService: null,
    created() {
        this.reportService = new ReportService();
        this.taskService = new TaskService();
    },
    emits: ["onPageChange", "tableLoaded"],
    props: {
        engine: {
            type: String,
            default: null,
        },
        targetId: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: "",
        },
        useInterval: {
            type: Boolean,
            default: true,
        },
        intervalTimeout: {
            type: Number,
            default: 10000,
        },
        license: {
            type: License,
            default: null,
        },
        paginator: {
            type: Boolean,
            default: true,
        },
        columns: {
            type: Array,
            default: function () {
                return [
                    "name",
                    "creation_date",
                    "engine_description",
                    "status",
                    "id",
                ];
            },
        },
        addButtonText: {
            type: String,
            default: null,
        },
        addButtonLink: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            loading: true,
            reports: [],
            total_records: 0,
            total_filtered: 0,
            page: 0,
            page_size: 10,
            filters: {},
            interval: null,
            Task,
        };
    },
    computed: {
        isLite() {
            return this.engine.includes('lite');
        },
    },
    mounted() {
        this.getData();
        if (this.useInterval) this.setTaskInterval();
    },
    unmounted() {
        if (this.useInterval) this.clearTaskInterval();
    },
    updated() {},
    methods: {
        async getData(showLoader = true) {
            this.loading = showLoader;

            try {
                const params = {
                    page: this.page,
                    page_size: this.page_size,
                };
                if (this.license) {
                    params.api = this.license.api.slice(1);
                } else if (this.engine && this.engine !== "all") {
                    if (this.state.licenses.length === 0) {
                        setTimeout(() => {
                            this.getData();
                        }, 500);
                        return;
                    }
                    const license = this.state.licenses.find(
                        (l) => l.engine === this.engine
                    );
                    params.api = license.api.slice(1);
                }
                if (this.targetId) {
                    params.target_id = this.targetId;
                }
                const response = await this.taskService.get(null, {
                    params,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: {},
                });
                if (response) {
                    const list = [];
                    for (const task of response.data.tasks) {
                        list.push(new Task(task));
                    }
                    this.reports = list;
                    this.total_records = response.data.count || list.length;
                    this.total_filtered = response.data.count || list.length;
                    this.$emit("onPageChange");
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;

                // EMIT
                this.$emit("tableLoaded", null);
            }
        },
        clearTaskInterval() {
            if (this.interval) {
                clearInterval(this.interval);
            }
        },
        setTaskInterval() {
            this.clearTaskInterval();
            this.interval = setInterval(async () => {
                try {
                    await this.getData(false);
                } catch (error) {
                    console.error("ERROR", error);
                }
            }, this.intervalTimeout);
        },
        navigateTo(task) {
            if (task.engine_abbreviation == Engine.INCIDENT_RESPONSE) {
                this.$router.push({
                    name: 'ir-form',
                    params: { id: task.task_id },
                })
            } else if ([Engine.SURVEY_ICT, Engine.SURVEY_GDPR, Engine.SURVEY_TEST].includes(task.engine_abbreviation)) {
                const routeName = (task.status === Task.STATUSES.SUCCESS ? 'survey_report' : 'survey')
                this.$router.push({
                    name: routeName,
                    params: { survey_type: this.$route.params.survey_type, id: task.task_id },
                })
            } else {
                this.$router.push({
                    name: "report_" + task.engine_abbreviation,
                    params: { id: task.task_id },
                });
            }
        },
        getHeaderByCol(col) {
            if (col === "creation_date") return this.$t("general.date");
            if (col === "id") return this.$t("general.view");
            return this.$t(`general.${col}`);
        },
        onPageChange(event) {
            this.page = event.page;
            this.getData();
        },
        askForDelete(taskId) {
            this.$swal({
                title: this.$t("general.confirmation_required"),
                html: this.$t("pages.report.delete_question"),
                showConfirmButton: true,
                showDenyButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        title: this.$t("general.confirmation_required"),
                        html: this.$t("pages.report.delete_question"),
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        onBeforeOpen: () => {
                            this.$swal.showLoading();
                        },
                        didOpen: () => {
                            setTimeout(() => {
                                this.deleteTask(taskId);
                            }, 500);
                        },
                    });
                } else {
                    this.$swal.close();
                }
            });
        },
        askForStop(taskId) {
            this.$swal({
                title: this.$t("general.confirmation_required"),
                html: this.$t("pages.report.stop_question"),
                showConfirmButton: true,
                showDenyButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        title: this.$t("general.confirmation_required"),
                        html: this.$t("pages.report.stop_question"),
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        onBeforeOpen: () => {
                            this.$swal.showLoading();
                        },
                        didOpen: () => {
                            setTimeout(() => {
                                this.stopTask(taskId);
                            }, 500);
                        },
                    });
                }
            });
        },
        async deleteTask(taskId) {
            this.loading = true;
            try {
                await this.taskService.delete(taskId);
                await this.reportService.delete(taskId, {
                    addSlash: false,
                });
                this.$swal.close();
                this.getData(true);
            } catch (error) {
                console.error(error);
                this.loading = false;
                this.$swal.close();
            }
        },
        async stopTask(taskId) {
            this.loading = true;
            try {
                await this.taskService.delete(`stop/${taskId}`);
                this.$swal.close();
                this.getData(true);
            } catch (error) {
                console.error(error);
                this.loading = false;

                // MESSAGE
                let message = error.message;
                if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.message) message = error.response.data.error.message;
                }

                // SHOW MESSAGE DIALOG
                this.$swal({
                    title: this.$t("general.error") + "!",
                    html: `${message}`,
                    icon: "error",
                    showConfirmButton: true,
                });
            }
        },
        isRunning(task) {
            if (task.status === Task.STATUSES.PENDING && task.percentage) return true;
            if (task.status === Task.STATUSES.RUNNING) {
                if (!task.stop_requested) return true;
            }
            return false;
        },
        goToPage(link) {
            this.$router.push({...link})
        },
        canView(task) {
            const statuses = [Task.STATUSES.SUCCESS]
            if (task.engine.includes('survey')) statuses.push(Task.STATUSES.COMPILING)
            return statuses.includes(task.status)
        },
        canDelete(task) {
            return ![Task.STATUSES.DELETED, Task.STATUSES.PENDING, Task.STATUSES.RUNNING].includes(task.status)
        },
        viewIcon(task) {
            if (task.engine.includes('survey')) {
                if (task.status === Task.STATUSES.SUCCESS) {
                    return `pi pi-chart-bar`
                }
            }
            return `pi pi-search`
        }
    },
};
</script>

<style lang="scss">
</style>
