<template>
    <div class="p-col-12">
        <div class="p-grid">
            <slot></slot>
            <div
                v-for="file of files"
                :key="file.name"
                class="p-col-12 p-md-6 p-lg-4 p-xl-3"
            >
                <Button
                    :icon="file.icon"
                    :label="file.name"
                    style="width: 100%;"
                    class="
                        p-mr-2 p-mb-2 p-button-danger p-button-lg
                        swa-download-button
                    "
                    @click="() => download(file, onDownloadProgress)"
                />
                <div class="download-overlay" v-if="showProgress === file.name">{{$t("general.download_progress")}}: {{progress}}%</div>
            </div>
        </div>
    </div>
</template>

<script>
import {useStore} from "../../store";
import {reportsMethods} from "../../commons";
import {ReportService} from "../../services";

export default {
    name: "DownloadsPDFComponent",
    reportService: null,
    setup() {
        return {
            state: useStore().state,
            setState: useStore().setState,
        };
    },
    emits: ["filesList"],
    props: {
        reportId: {
            type: String,
            default: () => null,
        },
    },
    data() {
        return {
            files: [],
            progress: 0,
            showProgress: null,
        };
    },
    mounted() {
        this.reportService = new ReportService();
        this.getFiles(this.reportId);
    },
    methods: {
        ...reportsMethods,
        onDownloadProgress(progressEvent) {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progress !== this.progress) {
            this.progress = progress;
          }
        },
    },
};
</script>

<style>
.download-overlay {
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: white;
    font-weight: bold;
    padding: 20px;
    border-radius: 6px;
}
</style>
