<template>
    <div class="swa-rounder-container">
        {{ val }}
    </div>
</template>

<script>
import { Formatter } from "../../utilities";
export default {
    name: "RounderContainer",
    props: {
        value: null,
    },
    computed: {
        val() {
            return Formatter(this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./RounderContainer";
</style>