import { isValid } from "./ipaddr.js";

const ipRangeCheck = require("ip-range-check");
const ipaddrJs = require("./ipaddr.js");

const IPRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const IPV6Regex = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/g;
const IPWithPortRegex = /^(?:(?:2[0-4]\d|25[0-5]|1\d{2}|[1-9]?\d)\.){3}(?:2[0-4]\d|25[0-5]|1\d{2}|[1-9]?\d)(?:\:(?:\d|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]))?$/;
const websiteRegex = /^(http(s)?:\/\/)?(www\.)?(((?!-)[a-zA-Z0-9-]{1,63}(?<!-))\.)+[a-zA-Z]{2,63}$/g
const webscanRegex = /^(http(s)?:\/\/)?(www\.)?(((?!-)[a-zA-Z0-9-]{1,63}(?<!-))\.)+(:[0-9]+)?(\/?([^#]*))?$/gm

export const ValidateIPAddress = (address) => {
    const regex = new RegExp(IPRegex);
    if (regex.test(address)) {
        return isValidIp(address);
    }
    return false;
};

export const ValidateIPV6Address = (address) => {
  const regex = new RegExp(IPV6Regex);
  if (regex.test(address)) {
    return true;
  }
  return false;
};

export const ValidateIPAddressWithPort = (address) => {
    if (!(address.includes(".") && address.includes(":"))) return false;
    const regex = new RegExp(IPWithPortRegex);
    if (regex.test(address)) {
        return true;
    }
    return false;
};

export const ValidateRangeIPAddress = (address) => {
  const list = address.split("-");
  if (list.length !== 2) {
    return false;
  }
  try {
    if (ValidateIPAddress(list[0]) && ValidateIPAddress(list[1])) {
      const list1 = list[0].split(".");
      const list2 = list[1].split(".");

      if (
        checkSubnet(list[0], list[1]) &&
        (parseInt(list2[2], 10) > parseInt(list1[2], 10) || (parseInt(list2[2]) === parseInt(list1[2]) && parseInt(list2[3]) >= parseInt(list1[3])))
      ) {
        return true;
      }
    }
  } catch (ignored) {}
  return false;
};

export const ValidateIPAddressSubnet = (address) => {
  const list = address.split("/");
  if (list.length !== 2) {
    return false;
  }

  const subnet = parseInt(list[1], 10);
  if ((ValidateIPAddress(list[0]) || ValidateRangeIPAddress(list[0])) && !isNaN(subnet) && subnet > 19 && subnet < 33) {
    return true;
  }
  return false;
};

export const ValidateWebSite = (address) => {
  const regex = new RegExp(websiteRegex);
  if (regex.test(address)) {
    return true;
  }
  return false;
};

export const ValidateWebscanSite = (address) => {
  const regex = new RegExp(webscanRegex);
  if (regex.test(address)) {
    return true;
  }
  return false;
};

export const checkSubnet = (ip1, ip2) => {
  for (let i = 20; i < 33; i++) {
    if (ipRangeCheck(ip2, `${ip1}/20`)) {
      return true;
    }
  }

  return false;
};

// Non accettare:
// - multicast
// - loopback
// - broadcast
// Come privati:
// - linkLocal
// - reserved
// Come pubblico:
// - carrierGradeNat
export const isValidIp = (ip) => {
    const ranges = [
        "private",
        "unicast",
    ];
    if (ip.includes("/")) {
        const split = ip.split("/");
        return ranges.includes( ipaddrJs.parse(split[0]).range() );
    }
    if (ip.includes("-")) {
        const split = ip.split("-");
        return ranges.includes( ipaddrJs.parse(split[0]).range() );
    }
    return ranges.includes( ipaddrJs.parse(ip).range() );
};

export const isPrivateIp = (ip) => {
    if (ip.includes("/")) {
        const split = ip.split("/");
        return ipaddrJs.parse(split[0]).range() == "private";
    }
    if (ip.includes("-")) {
        const split = ip.split("-");
        return ipaddrJs.parse(split[0]).range() == "private";
    }
    return ipaddrJs.parse(ip).range() == "private";
};

export const isPublicIp = (ip) => {
    if (ip.includes("/")) {
        const split = ip.split("/");
        return ipaddrJs.parse(split[0]).range() == "unicast";
    }
    if (ip.includes("-")) {
        const split = ip.split("-");
        return ipaddrJs.parse(split[0]).range() == "unicast";
    }
    return ipaddrJs.parse(ip).range() == "unicast";
};
