export class Engine {
  static DTI = "dti";
  static DTI_LITE = "dti_lite";
  static NETWORK_SCAN = "nscan";
  static WEB_APP_SCAN = "webapp";
  static CODE_REVIEW = "code_review";
  static GDPR_ASSESSMENT = "gdpr";
  static ICT_ASSESSMENT = "ict";
  static INCIDENT_RESPONSE = "ir";
  static CTI = "cti";
  static TVM = "tvm";
  static RRI = "rri";
  static CARD_PROTECTION = "cardp";
  static SURVEY_ICT = "survey_ict";
  static SURVEY_GDPR = "survey_gdpr";
  static SURVEY_TEST = "survey_test";
}

export const ENGINE_DESCRIPTIONS = {
  dti: "Domain Threat Intelligence",
  dti_lite: "Domain Threat Intelligence (Lite)",
  nscan: "Network Scan",
  webapp: "Web Application Scan",
  code_review: "Code Review",
  gdpr: "GDPR Assessment",
  ict: "ICT Assessment",
  ir: "Incident Response",
  cti: "Cyber Threat Intelligence",
  tvm: "Technology Vulnerability Monitoring",
  rri: "Ransomware Risk Indicator",
  cardp: "Card Protection",
  survey_ict: "Survey ICT",
  survey_gdpr: "Survey GDPR",
  survey_test: "Survey Test",
};

export const getEngineDescription = (engine) => {
  switch (engine) {
    case Engine.DTI:
      return ENGINE_DESCRIPTIONS.dti;
    case Engine.WEB_APP_SCAN:
      return ENGINE_DESCRIPTIONS.webapp;
    case Engine.CODE_REVIEW:
      return ENGINE_DESCRIPTIONS.code_review;
    case Engine.NETWORK_SCAN:
      return ENGINE_DESCRIPTIONS.nscan;
    case Engine.GDPR_ASSESSMENT:
      return ENGINE_DESCRIPTIONS.gdpr;
    case Engine.ICT_ASSESSMENT:
      return ENGINE_DESCRIPTIONS.ict;
    case Engine.INCIDENT_RESPONSE:
      return ENGINE_DESCRIPTIONS.ir;
    case Engine.CTI:
      return ENGINE_DESCRIPTIONS.cti;
    case Engine.TVM:
      return ENGINE_DESCRIPTIONS.tvm;
    case Engine.DTI_LITE:
      return ENGINE_DESCRIPTIONS.dti_lite;
    case Engine.RRI:
      return ENGINE_DESCRIPTIONS.rri;
    case Engine.CARD_PROTECTION:
      return ENGINE_DESCRIPTIONS.cardp;
    case Engine.SURVEY_ICT:
      return ENGINE_DESCRIPTIONS.survey_ict;
    case Engine.SURVEY_GDPR:
      return ENGINE_DESCRIPTIONS.survey_gdpr;
    case Engine.SURVEY_TEST:
      return ENGINE_DESCRIPTIONS.survey_test;
    default:
      return null;
  }
};

export const parseEngineByUrl = (engine) => {
  let array = [];
  if (!engine) {
    array = ["/", ""];
  } else {
    if (engine.charAt(0) != "/") engine = "/" + engine;
    array = engine.split("/");
  }

  return array;
};

export const getEngineAbbreviationByDescription = (engine) => {
  switch (engine.toLowerCase().replace(/ /g, "")) {
    case ENGINE_DESCRIPTIONS.dti.toLowerCase().replace(/ /g, ""):
      return "dti";
    case ENGINE_DESCRIPTIONS.nscan.toLowerCase().replace(/ /g, ""):
      return "nscan";
    case ENGINE_DESCRIPTIONS.webapp.toLowerCase().replace(/ /g, ""):
      return "webapp";
    case ENGINE_DESCRIPTIONS.code_review.toLowerCase().replace(/ /g, ""):
      return "code_review";
    case ENGINE_DESCRIPTIONS.gdpr.toLowerCase().replace(/ /g, ""):
      return "gdpr";
    case ENGINE_DESCRIPTIONS.ict.toLowerCase().replace(/ /g, ""):
      return "ict";
    case ENGINE_DESCRIPTIONS.ir.toLowerCase().replace(/ /g, ""):
      return "ir";
    case ENGINE_DESCRIPTIONS.cti.toLowerCase().replace(/ /g, ""):
      return "cti";
    case ENGINE_DESCRIPTIONS.tvm.toLowerCase().replace(/ /g, ""):
      return "tvm";
    case ENGINE_DESCRIPTIONS.rri.toLowerCase().replace(/ /g, ""):
      return "rri";
    case ENGINE_DESCRIPTIONS.cardp.toLowerCase().replace(/ /g, ""):
      return "cardp";
    case ENGINE_DESCRIPTIONS.survey_ict.toLowerCase().replace(/ /g, ""):
      return "survey_ict";
    case ENGINE_DESCRIPTIONS.survey_gdpr.toLowerCase().replace(/ /g, ""):
      return "survey_gdpr";
    case ENGINE_DESCRIPTIONS.survey_test.toLowerCase().replace(/ /g, ""):
      return "survey_test";
    default:
      return null;
  }
};
