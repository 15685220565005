import { getQuery } from "./get_query";

export class CtiQueryV2 {
  static summary = `query {
summary {
  version
  botnets_count
  breaches_count
  total_breaches_count
  unique_breaches_results_count
  darkweb_count
  darkweb_sources
  ip_count
  certificates_count
  registered_phishing_count
  unregistered_phishing_count
  vulnerabilities_count
  use_aggregation
  malwares_count
  malwares_families
  subdomain_count
  breaches_risks { total critical high medium low none }
  darkwebs_risks { total critical high medium low none }
  ip_risks { total critical high medium low none }
  vulnerabilities_severity { total critical high medium low none }
  registered_phishing
  darkwebs_years
  domain
  license_info {
    name
    api
    scans_max
    full_mode
    binary_edge_ip_score
    id
    user_id
    user_role
    scans_done
    expires_on
  }
  extra_targets
  epss
}}`;

  static botnets(first = 20, skip = 0, { sort_by = "time", order = -1, search = null, filters = {}, use_aggregation = true } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search, filters, use_aggregation });
    return `query {
botnets { items(${query}) {
  _id
  ip
  botnet
  hostname
  time
  info
  count
  target
} total }}`;
  }

  static botnetDetails(first = 20, skip = 0, { sort_by = null, order = null, search = null, exactly = false, filters = {} } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search, exactly, filters });
    return `query {
botnets_info { items(${query}) {
  _id
  ip
  info
} total }}`;
  }

  static breaches(first = 20, skip = 0, { sort_by = "risk_value", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
breaches { items(${query}) {
  _id
  email
  username
  password
  password_hash
  ip
  risk
  risk_value
  source {
    name
    url
    actor_name
    description
    score
    add_date
    attack_vector
    geography
    category
    compromised_data
    is_imported meta
  }
  target
} total }}`;
  }

  static darkweb(first = 20, skip = 0, { sort_by = "timestamp", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
darkweb { items(${query}) {
  _id
  snippet
  resource_name
  url
  country
  language
  actor
  title
  timestamp
  source
  risk
  risk_value
  target
} total }}`;
  }

  static ip(first = 20, skip = 0, { sort_by = "risk_score", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
ip_history { items(${query}) {
  _id
  ip
  risk_score
  highRiskLevelReason
  risk_level
  risk
  malicious_activity {
    feed { source last_sync }
    malware { name alias description }
    last_seen_date
  }
  references { date snippet }
  target
  subdomains } total }}`;
  }

  static phishing(first = 20, skip = 0, { sort_by = "whois.creation_timestamp", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
registered_phishing { items(${query}) {
  _id
  dns_a
  dns_aaaa
  dns_mx
  dns_ns
  domain_name
  whois {
    creation_date
    creation_timestamp
    expiration_date
    name
    name_servers
    registrant
    registrant_country
    registrar
    status
    risk
    risk_value
  }
  info {
    expiration_date 
    name_servers
    registrar
  }
  similarity
  domain_unicode
} total }}`;
  }

  static potentialPhishing(first = 20, skip = 0, { sort_by = "whois.creation_timestamp", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
unregistered_phishing { items(${query}) {
  _id
  dns_a
  dns_aaaa
  dns_mx
  dns_ns
  domain_name
  whois {
    creation_date
    creation_timestamp
    expiration_date
    name
    name_servers
    registrant
    registrant_country
    registrar
    status
  }
  similarity
  domain_unicode
} total }}`;
  }

  static subdomainData(first = 20, skip = 0, { sort_by = "subdomain", order = 1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
subdomain_data { items(${query}) {
  ip_list
  subdomain
  technologies {
    app
    ver
    type
  }
} total }}`;
  }

  static certificates(first = 20, skip = 0, { sort_by = "date", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
certificates { items(${query}) {
  _id
  subdomain
  server
  issuer
  date
} total }}`;
  }

  static malwares(first = 20, skip = 0, { sort_by = "date", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
malwares { items(${query}) {
  _id
  url
  host
  date
  tag
  occurrence
} total }}`;
  }

  static services(first = 20, skip = 0, { sort_by = "time", order = 1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `ip_data {
items(${query}) {
  subdomains
  ip
  ports_details
  vulnerabilities_list_count
  data {
    region_code
    tags
    ip
    area_code
    domains
    hostnames
    postal_code
    dma_code
    country_code
    org
    asn
    city
    latitude
    longitude
    isp
    last_update
    country_code3
    country_name
    ip_str
    os
  }
  ports_list
  vulnerabilities_list
  cpe_list
  vulnerabilities_details
} total }}`;
  }
}
