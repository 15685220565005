<template>
    <div class="full-width">
        <!-- / UPGRADE MESSAGE -->
        <UpgradeComponent
            v-if="!isFull && loaded"
            :title="$t('pages.report.typosquatting')"
        />
        <!-- / UPGRADE MESSAGE -->

        <div v-if="isFull" class="p-col-12 p-mt-2 subdomains-container">
            <!-- Typosquatting -->
            <div class="card p-shadow-7">
                <div class="swa-header">
                    <h3>{{ $t("pages.report.typosquatting") }}</h3>

                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText
                            v-model="filters['global']"
                            :placeholder="$t('general.global_search')"
                            @change="onFilter()"
                        />
                    </span>
                </div>

                <DataTable
                    :filters="filters"
                    :lazy="true"
                    :loading="loading"
                    :paginator="true"
                    :rowHover="true"
                    :rows="page_size"
                    :scrollable="true"
                    :sortField="sortField"
                    :sortOrder="sortOrder"
                    :totalRecords="total_records"
                    :value="records"
                    class="p-datatable-sm p-datatable-responsive min-height-400"
                    scrollHeight="400px"
                    @page="onPageChange($event)"
                    @sort="onPageChange($event)"
                >
                    <Column
                        v-for="col of columns"
                        :key="col"
                        :field="col"
                        :header="getHeaderByCol(col)"
                        :sortable="
                            col === 'domain' ||
                            col === 'risk' ||
                            col === 'creation_date' ||
                            col === 'similarity'
                        "
                    >
                        <template #body="slotProps">
                            <div
                                v-if="
                                    col === 'dns_a' ||
                                    col === 'dns_mx' ||
                                    col === 'dns_ns'
                                "
                            >
                                <span
                                    v-if="
                                        slotProps.data[col]
                                            .toString()
                                            .toUpperCase()
                                            .replace(/,/g, '') === 'N/A'
                                    "
                                >
                                    {{
                                        slotProps.data[col]
                                            .toString()
                                            .toUpperCase()
                                            .replace(/,/g, "")
                                    }}
                                </span>
                                <div v-else>
                                    <span
                                        v-for="dns in slotProps.data[col]"
                                        :key="'col-' + dns"
                                    >
                                        {{ dns }}<br />
                                    </span>
                                </div>
                            </div>
                            <div v-else-if="col === 'data'">
                                <div v-if="slotProps.data.data === null">-</div>
                                <div v-else>
                                    <div
                                        v-for="(value, name) in slotProps.data
                                            .data"
                                        :key="name"
                                    >
                                        <span
                                            v-if="
                                                name !== 'creation_date' &&
                                                name !== 'risk'
                                            "
                                        >
                                            <strong>{{ name }}:</strong>
                                            {{ value }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="col === 'risk'">
                                <Tag
                                    v-if="
                                        slotProps.data.data &&
                                        slotProps.data.data.risk &&
                                        slotProps.data.data.risk === 'High'
                                    "
                                    severity="danger"
                                >
                                    {{ $t("general.high") }}
                                </Tag>
                                <Tag
                                    v-if="
                                        slotProps.data.data &&
                                        slotProps.data.data.risk &&
                                        slotProps.data.data.risk === 'Medium'
                                    "
                                    severity="warning"
                                >
                                    {{ $t("general.medium") }}
                                </Tag>
                                <Tag
                                    v-if="
                                        slotProps.data.data &&
                                        slotProps.data.data.risk &&
                                        slotProps.data.data.risk === 'Low'
                                    "
                                    severity="info"
                                >
                                    {{ $t("general.low") }}
                                </Tag>
                            </div>
                            <span v-else-if="col === 'similarity'">{{ slotProps.data[col].toFixed(5) }}</span>
                            <span v-else-if="col === 'domain_unicode'">{{ slotProps.data[col] || unicodeDomain(slotProps.data.domain) }}</span>
                            <span v-else>{{ slotProps.data[col] }}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <!-- Typosquatting -->
        </div>
    </div>
</template>

<script>
import { ReportService } from "../../../services";
import { DateParser } from "@/utilities";
import { DtiQuery } from "../../../pages/Reports/Report/query";
import { usePunycodeToUnicode } from "@/utilities/PunycodeConverter.js"

export default {
    name: "Typosquatting",
    reportService: null,
    created() {
        this.reportService = new ReportService();
    },
    props: {
        reportId: {
            type: String,
            default: null,
        },
        loaded: {
            type: Boolean,
            default: () => false,
        },
        isFull: {
            type: Boolean,
            default: () => true,
        },
        hasEpss: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        columns () {
            if (this.hasEpss) {
                return [
                    "domain",
                    "domain_unicode",
                    "creation_date",
                    "similarity",
                    "data",
                    "dns_a",
                    "dns_mx",
                    "dns_ns",
                    "risk",
                ]
            }
            return [
                "domain",
                "domain_unicode",
                "creation_date",
                "data",
                "dns_a",
                "dns_mx",
                "dns_ns",
                "risk",
            ]
        }
    },
    watch: {
        loaded(newVal, oldVal) {
            if (newVal && newVal !== oldVal && this.isFull) {
                this.getData();
            }
        },
    },
    data() {
        return {
            loading: true,
            records: [],
            total_records: 0,
            total_filtered: 0,
            page: 0,
            filters: {},
            page_size: 20,
            sortField: "creation_date",
            sortOrder: -1,
        };
    },
    mounted() {
        if (this.loaded && this.isFull) {
            this.getData();
        }
    },
    updated() {},
    methods: {
        async getData() {
            this.loading = true;
            try {
                const skip = this.page * this.page_size;

                const search =
                    this.filters.global && this.filters.global.length > 0
                        ? `${this.filters.global}`
                        : "";

                const response = await this.reportService.graphql({
                    api: this.reportId,
                    method: "get",
                    args: {
                        params: {
                            query: DtiQuery.typosquatting(
                                this.page_size,
                                skip,
                                {
                                    sort_by: this.sortField,
                                    order: this.sortOrder,
                                    search,
                                }
                            ),
                        },
                    },
                });
                const { typosquatting } = response.data.result;

                const records = typosquatting.items.map((row) => {
                    row.creation_date =
                        row.data && row.data.creation_date
                            ? DateParser.parseDate(row.data.creation_date)
                            : null;

                    return row;
                });
                this.total_records = typosquatting.total;
                this.total_filtered = typosquatting.total;
                this.records = records;
            } catch (error) {
                if (error.toString().indexOf("404") === -1) {
                    console.warn(error);
                }
            }
            this.loading = false;
        },
        onPageChange(event) {
            this.page = event.page !== undefined ? event.page : this.page;
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.getData();
        },
        onFilter() {
            this.onPageChange({
                page: 0,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
            });
        },
        getHeaderByCol(col) {
            if (col === "id") return this.$t("general.view");
            if (col === "risk") return this.$t("pages.report.severity");
            if (col === "domain_unicode") return `Decoded name`;
            return this.$t(`pages.report.${col}`);
        },
        renderTechLabel(tech) {
            if (tech.app && tech.ver && tech.type)
                return `${tech.app}: ${tech.ver} (${tech.type})`;
            if (tech.app && tech.ver && !tech.type)
                return `${tech.app}: ${tech.ver}`;
            if (tech.app && !tech.ver && !tech.type) return `${tech.app}`;
            if (!tech.app && tech.ver && tech.type)
                return `${tech.ver} (${tech.type})`;
            if (!tech.app && !tech.ver && tech.type) return `${tech.type}`;
            if (!tech.app && tech.ver && !tech.type) return `${tech.ver}`;

            return "";
        },
        unicodeDomain (value) {
            return usePunycodeToUnicode(value)
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./Report";
</style>
