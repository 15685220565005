<template>
    <div class="full-width">
        <!-- / UPGRADE MESSAGE -->
        <UpgradeComponent
            v-if="!isFull && loaded"
            :title="$tc('general.potential_vulnerabilities', 2)"
        />
        <!-- / UPGRADE MESSAGE -->

        <!-- Services -->
        <div class="p-col-12" v-if="isFull && loaded">
            <div class="card p-shadow-7">
                <div class="swa-header">
                    <h3>{{ $tc("general.potential_vulnerabilities", 2) }}</h3>
                </div>

                <DataTable
                    v-if="isFull"
                    :value="ipData.records"
                    class="p-datatable-sm p-datatable-responsive min-height-400"
                    :rowHover="false"
                    :loading="loading"
                    :lazy="true"
                    :rows="page_size"
                    :paginator="true"
                    :filters="ipData.filters"
                    :totalRecords="ipData.total_filtered"
                    @page="onPageChange($event)"
                    @sort="onPageChange($event)"
                    v-model:expandedRows="expandedRows"
                    data-key="ip"
                    expandedRowIcon="pi pi-search-minus"
                    collapsedRowIcon="pi pi-search-plus"
                    :sortField="sortField"
                    :sortOrder="sortOrder"
                >
                    <Column
                        field="ip"
                        :sortable="true"
                        :header="$t('general.ip_address')"
                    >
                        <template #body="slotProps">
                            <div>
                                {{ slotProps.data.ip }}
                            </div>
                        </template>
                    </Column>
                    <Column
                        field="subdomains"
                        :header="$tc('general.subdomain', 2)"
                    >
                        <template #body="slotProps">
                            <div>
                                <div
                                    style="max-height: 100px; overflow-y: auto"
                                    v-if="slotProps.data.subdomains"
                                >
                                    <div
                                        :key="domain"
                                        v-for="domain in slotProps.data
                                            .subdomains"
                                    >
                                        {{ domain }}
                                    </div>
                                </div>
                                <div v-if="!slotProps.data.subdomains">-</div>
                            </div>
                        </template>
                    </Column>
                    <Column
                        field="vulnerabilities_list_count"
                        :sortable="true"
                        :header="$t('general.potential_vulnerabilities')"
                    >
                        <template #body="slotProps">
                            <div>
                                <div
                                    v-if="
                                        slotProps.data
                                            .vulnerabilities_list_count
                                    "
                                >
                                    {{
                                        slotProps.data
                                            .vulnerabilities_list_count
                                    }}
                                </div>
                                <div
                                    v-if="
                                        !slotProps.data
                                            .vulnerabilities_list_count
                                    "
                                >
                                    0
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="ports_list" :header="$tc('general.port', 2)">
                        <template #body="slotProps">
                            <div>
                                <div v-if="slotProps.data.ports_list">
                                    {{ slotProps.data.ports_list.join(", ") }}
                                </div>
                                <div v-if="!slotProps.data.ports_list">-</div>
                            </div>
                        </template>
                    </Column>
                    <Column
                        :expander="true"
                        :header="$tc('general.detail', 1)"
                        headerStyle="width: 100px"
                    />
                    <template #expansion="ipDetail">
                        <div class="p-col-12">
                            <div class="p-grid">
                                <div class="p-col-12 p-xl-3 p-md-6">
                                    <div
                                        class="
                                            card
                                            white
                                            p-shadow-24
                                            card-rounded
                                        "
                                    >
                                        <div class="p-text-right">
                                            <strong>{{
                                                $t(
                                                    "general.total_potential_vulnerabilities"
                                                )
                                            }}</strong>
                                        </div>
                                        <div class="card-value p-text-right">
                                            {{
                                                detailByIp(ipDetail.data.ip)
                                                    .vulnerabilities.length
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-xl-3 p-md-6">
                                    <div
                                        class="
                                            card
                                            danger
                                            p-shadow-7
                                            card-rounded
                                        "
                                    >
                                        <div class="p-text-right">
                                            <strong>{{
                                                $t("general.high_severity")
                                            }}</strong>
                                        </div>
                                        <div class="card-value p-text-right">
                                            {{
                                                detailByIp(ipDetail.data.ip)
                                                    .total_high
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-xl-3 p-md-6">
                                    <div
                                        class="
                                            card
                                            warning
                                            p-shadow-7
                                            card-rounded
                                        "
                                    >
                                        <div class="p-text-right">
                                            <strong>{{
                                                $t("general.medium_severity")
                                            }}</strong>
                                        </div>
                                        <div class="card-value p-text-right">
                                            {{
                                                detailByIp(ipDetail.data.ip)
                                                    .total_medium
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-xl-3 p-md-6">
                                    <div
                                        class="
                                            card
                                            primary
                                            p-shadow-7
                                            card-rounded
                                        "
                                    >
                                        <div class="p-text-right">
                                            <strong>{{
                                                $t("general.low_severity")
                                            }}</strong>
                                        </div>
                                        <div class="card-value p-text-right">
                                            {{
                                                detailByIp(ipDetail.data.ip)
                                                    .total_low
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-12 p-xl-3 p-md-6">
                                    <div
                                        class="
                                            card
                                            white
                                            p-shadow-24
                                            card-rounded
                                        "
                                    >
                                        <div class="p-text-right">
                                            <strong>{{
                                                $t(
                                                    "pages.report.potential_gdpr"
                                                )
                                            }}</strong>
                                        </div>
                                        <div class="card-value p-text-right">
                                            {{
                                                detailByIp(ipDetail.data.ip)
                                                    .total_gdpr
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-xl-3 p-md-6">
                                    <div
                                        class="
                                            card
                                            bg-confidentiality
                                            p-shadow-7
                                            card-rounded
                                        "
                                    >
                                        <div class="p-text-right">
                                            <strong>{{
                                                $t("general.confidentiality")
                                            }}</strong>
                                        </div>
                                        <div class="card-value p-text-right">
                                            {{
                                                detailByIp(ipDetail.data.ip)
                                                    .total_confidentiality
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-xl-3 p-md-6">
                                    <div
                                        class="
                                            card
                                            bg-integrity
                                            p-shadow-7
                                            card-rounded
                                        "
                                    >
                                        <div class="p-text-right">
                                            <strong>{{
                                                $t("general.integrity")
                                            }}</strong>
                                        </div>
                                        <div class="card-value p-text-right">
                                            {{
                                                detailByIp(ipDetail.data.ip)
                                                    .total_integrity
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-xl-3 p-md-6">
                                    <div
                                        class="
                                            card
                                            bg-availability
                                            p-shadow-7
                                            card-rounded
                                        "
                                    >
                                        <div class="p-text-right">
                                            <strong>{{
                                                $t("general.availability")
                                            }}</strong>
                                        </div>
                                        <div class="card-value p-text-right">
                                            {{
                                                detailByIp(ipDetail.data.ip)
                                                    .total_availability
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-12 swa-header">
                                    <h3>{{ $tc("general.detail", 2) }}</h3>
                                </div>
                                <div class="p-col-12 p-md-6">
                                    <table class="swa-table">
                                        <tbody class="p-datatable-tbody">
                                            <tr>
                                                <td class="td-header">
                                                    <strong
                                                        >{{
                                                            $t(
                                                                "pages.report.country_name"
                                                            )
                                                        }}:</strong
                                                    >
                                                </td>
                                                <td class="td-value">
                                                    {{
                                                        detailByIp(
                                                            ipDetail.data.ip
                                                        ).data.city
                                                    }}&nbsp;-&nbsp;
                                                    {{
                                                        detailByIp(
                                                            ipDetail.data.ip
                                                        ).data.country_code
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="td-header">
                                                    <strong
                                                        >{{
                                                            $t(
                                                                "pages.report.organization"
                                                            )
                                                        }}:</strong
                                                    >
                                                </td>
                                                <td class="td-value">
                                                    {{
                                                        detailByIp(
                                                            ipDetail.data.ip
                                                        ).data.org
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="td-header">
                                                    <strong
                                                        >{{
                                                            $t(
                                                                "pages.report.isp"
                                                            )
                                                        }}:</strong
                                                    >
                                                </td>
                                                <td class="td-value">
                                                    {{
                                                        detailByIp(
                                                            ipDetail.data.ip
                                                        ).data.isp
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="td-header">
                                                    <strong>
                                                        {{
                                                            $tc(
                                                                "pages.report.hostname",
                                                                detailByIp(
                                                                    ipDetail
                                                                        .data.ip
                                                                ).data.hostnames
                                                                    ? detailByIp(
                                                                          ipDetail
                                                                              .data
                                                                              .ip
                                                                      ).data
                                                                          .hostnames
                                                                          .length
                                                                    : 1
                                                            )
                                                        }}:
                                                    </strong>
                                                </td>
                                                <td class="td-value">
                                                    <div
                                                        v-if="
                                                            detailByIp(
                                                                ipDetail.data.ip
                                                            ).data.hostnames
                                                        "
                                                    >
                                                        {{
                                                            detailByIp(
                                                                ipDetail.data.ip
                                                            ).data.hostnames.join(
                                                                ", "
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        v-if="
                                                            !detailByIp(
                                                                ipDetail.data.ip
                                                            ).data.hostnames
                                                        "
                                                    >
                                                        -
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="td-header">
                                                    <strong
                                                        >{{
                                                            $t(
                                                                "pages.report.asn"
                                                            )
                                                        }}:</strong
                                                    >
                                                </td>
                                                <td class="td-value">
                                                    {{
                                                        detailByIp(
                                                            ipDetail.data.ip
                                                        ).data.asn
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <ServiceDetailsStackedHistogram
                                    v-if="
                                        serviceDetailsChart[
                                            ipDetail.data.ip
                                        ] !== null
                                    "
                                    :chartData="
                                        serviceDetailsChart[ipDetail.data.ip]
                                    "
                                />
                            </div>
                            <div
                                class="p-grid"
                                v-if="
                                    detailByIp(ipDetail.data.ip).ports_list &&
                                    detailByIp(ipDetail.data.ip).ports_list
                                        .length > 0
                                "
                            >
                                <div class="p-col-12 swa-header">
                                    <h3 class="p-mt-3">
                                        {{ $tc("general.potential_vulnerabilities", 2) }}
                                    </h3>
                                </div>
                            </div>
                            <div
                                class="p-grid"
                                v-for="port in detailByIp(ipDetail.data.ip)
                                    .ports_list"
                                :key="port"
                            >
                                <Divider align="center">
                                    <span class="p-tag" style="font-size: 20px"
                                        >{{ $t("general.port") }}:
                                        {{ port }}</span
                                    >
                                </Divider>
                                <div
                                    class="p-col-12 p-md-6 p-lg-4"
                                    v-if="
                                        detailByIp(ipDetail.data.ip)
                                            .ports_details[port].transport
                                    "
                                >
                                    <div
                                        class="
                                            p-card
                                            bg-grey
                                            p-text-center p-p-3
                                            bordered
                                            card-rounded
                                            text-20
                                        "
                                    >
                                        <strong
                                            >{{
                                                $t("pages.report.protocol")
                                            }}:</strong
                                        >
                                        {{
                                            detailByIp(ipDetail.data.ip)
                                                .ports_details[port].transport
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="p-col-12 p-md-6 p-lg-4"
                                    v-if="
                                        detailByIp(ipDetail.data.ip)
                                            .ports_details[port].product
                                    "
                                >
                                    <div
                                        class="
                                            p-card
                                            bg-grey
                                            p-text-center p-p-3
                                            bordered
                                            card-rounded
                                            text-20
                                        "
                                    >
                                        <strong
                                            >{{
                                                $t("pages.report.product")
                                            }}:</strong
                                        >
                                        {{
                                            detailByIp(ipDetail.data.ip)
                                                .ports_details[port].product
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="p-col-12 p-md-6 p-lg-4"
                                    v-if="
                                        detailByIp(ipDetail.data.ip)
                                            .ports_details[port].version
                                    "
                                >
                                    <div
                                        class="
                                            p-card
                                            bg-grey
                                            p-text-center p-p-3
                                            bordered
                                            card-rounded
                                            text-20
                                        "
                                    >
                                        <strong
                                            >{{
                                                $t("pages.report.version")
                                            }}:</strong
                                        >
                                        {{
                                            detailByIp(ipDetail.data.ip)
                                                .ports_details[port].version
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="p-col-12"
                                    v-if="
                                        detailByIp(ipDetail.data.ip)
                                            .ports_details[port].data
                                    "
                                >
                                    <pre v-code>
                                        <code>
                                            {{ detailByIp(ipDetail.data.ip).ports_details[port].data }}
                                        </code>
                                    </pre>
                                </div>
                            </div>
                            <div
                                class="p-grid"
                                v-if="
                                    detailByIp(ipDetail.data.ip)
                                        .vulnerabilities &&
                                    detailByIp(ipDetail.data.ip).vulnerabilities
                                        .length > 0
                                "
                            >
                                <div class="p-col-12">
                                    <DataTable
                                        :value="
                                            detailByIp(ipDetail.data.ip)
                                                .vulnerabilities
                                        "
                                        class="
                                            p-datatable-sm
                                            p-datatable-responsive
                                            min-height-400
                                        "
                                        :rows="10"
                                        :paginator="true"
                                        :rowHover="true"
                                        :filters="
                                            detailByIp(ipDetail.data.ip).filters
                                        "
                                    >
                                        <Column
                                            field="id"
                                            :header="$t('pages.report.cve')"
                                            headerStyle="white-space: nowrap; width: 15%"
                                            bodyStyle="white-space: nowrap; width: 15%">
                                            <template #body="slotProps">
                                                <div>
                                                    <a :href="`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${slotProps.data.cve}`" target="_blank">{{ slotProps.data.cve }}</a>
                                                </div>
                                            </template>
                                        </Column>
                                        <Column
                                            field="cvss"
                                            :header="$t('pages.report.cvssv2')"
                                            headerStyle="white-space: nowrap; width: 15%"
                                            bodyStyle="white-space: nowrap; width: 15%"
                                        >
                                            <template #body="slotProps">
                                                <div>
                                                    {{ slotProps.data.cvss }}
                                                </div>
                                            </template>
                                        </Column>
                                        <Column
                                            field="vulnerabilities_list"
                                            :header="$t('pages.report.impact')"
                                            headerStyle="white-space: nowrap; width: 15%"
                                            bodyStyle="white-space: pre-line; width: 15%"
                                        >
                                            <template #body="slotProps">
                                                <div>
                                                    <span
                                                        v-for="impact in slotProps
                                                            .data
                                                            .impact_description"
                                                        :key="impact"
                                                        style="
                                                            white-space: nowrap;
                                                        "
                                                    >
                                                        {{ impact }}
                                                        <br />
                                                    </span>
                                                </div>
                                            </template>
                                        </Column>
                                        <Column
                                            field="severity"
                                            :header="
                                                $t('pages.report.severity')
                                            "
                                            headerStyle="white-space: nowrap; width: 15%"
                                            bodyStyle="white-space: nowrap; width: 15%"
                                        >
                                            <template #body="slotProps">
                                                <div>
                                                    <Tag
                                                        :severity="
                                                            slotProps.data
                                                                .severity
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                slotProps.data
                                                                    .severity ===
                                                                'danger'
                                                            "
                                                            >{{
                                                                $t(
                                                                    "general.high"
                                                                )
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="
                                                                slotProps.data
                                                                    .severity ===
                                                                'warning'
                                                            "
                                                            >{{
                                                                $t(
                                                                    "general.medium"
                                                                )
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="
                                                                slotProps.data
                                                                    .severity ===
                                                                'primary'
                                                            "
                                                            >{{
                                                                $t(
                                                                    "general.low"
                                                                )
                                                            }}</span
                                                        >
                                                    </Tag>
                                                </div>
                                            </template>
                                        </Column>
                                        <Column
                                            field="summary"
                                            :header="
                                                $t('pages.report.description')
                                            "
                                        >
                                            <template #body="slotProps">
                                                <div>
                                                    {{ slotProps.data.summary }}
                                                </div>
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
        <!-- / Services -->
    </div>
</template>

<script>
import { ReportService } from "../../../services";
import { IMPACT_TYPE } from "../../../utilities";
import { DtiQuery } from "../../../pages/Reports/Report/query";
import ServiceDetailsStackedHistogram from "./ServiceDetailsStackedHistogram.vue";

export default {
    name: "Services",
    reportService: null,
    components: {
        ServiceDetailsStackedHistogram,
    },
    created() {
        this.reportService = new ReportService();
    },
    props: {
        reportId: {
            type: String,
            default: null,
        },
        loaded: {
            type: Boolean,
            default: () => false,
        },
        isFull: {
            type: Boolean,
            default: () => true,
        },
    },
    data() {
        return {
            page_size: 15,
            loading: true,
            ipData: {
                records: [],
                total_records: 0,
                total_filtered: 0,
                page: 0,
                filters: {},
                isModalDetailVisible: false,
                detail: {},
            },
            expandedRows: [],
            detailedRows: {},
            ipDetailFilters: {},
            sortField: "vulnerabilities_list_count",
            sortOrder: -1,
            filters: {},
            serviceDetailsChart: {},
        };
    },
    mounted() {
        if (this.loaded && this.isFull) {
            this.getIPData();
        }
    },
    updated() {},
    watch: {
        loaded(newVal, oldVal) {
            if (newVal && newVal !== oldVal && this.isFull) {
                this.getIPData();
            }
        },
    },
    methods: {
        async getIPData(showLoader = true) {
            this.loading = showLoader;
            try {
                const skip = this.ipData.page
                    ? this.ipData.page * this.page_size
                    : 0;
                const search =
                    this.filters.global && this.filters.global.length > 0
                        ? `${this.filters.global}`
                        : "";
                const query = DtiQuery.services(this.page_size, skip, {
                    sort_by: this.sortField,
                    order: this.sortOrder,
                    search,
                });
                const response = await this.reportService.graphql({
                    api: this.reportId,
                    method: "get",
                    args: {
                        params: {
                            query,
                        },
                    },
                });
                const { ip_data } = response.data.result;
                for (let i = 0; i < ip_data.items.length; i++) {
                    this.showIPDataDetail(ip_data.items[i]);
                }
                this.ipData = {
                    ...this.ipData,
                    records: ip_data.items,
                    total_filtered: ip_data.total,
                    total_records: ip_data.total,
                };
            } catch (error) {
                if (error.toString().indexOf("404") === -1) {
                    console.warn(error);
                }
            }

            this.loading = false;
        },
        onPageChange(event) {
            this.ipData.page =
                event.page !== undefined ? event.page : this.ipData.page;
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.getIPData();
        },
        onIPFilter() {
            this.onPageChange({
                page: 0,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
            });
        },
        getFilteredCVECount(array) {
            const list = [];
            for (const row in array) {
                if (list.indexOf(array[row]) === -1) {
                    list.push(array[row]);
                }
            }

            return list.length;
        },
        showIPDataDetail(data) {
            let total_high = 0;
            let total_medium = 0;
            let total_low = 0;
            let total_gdpr = 0;
            let total_availability = 0;
            let total_integrity = 0;
            let total_confidentiality = 0;
            const vulnerabilities = [];

            this.serviceDetailsChart[data.ip] = {
                availability: {
                    none: 0,
                    partial: 0,
                    complete: 0,
                },
                integrity: {
                    none: 0,
                    partial: 0,
                    complete: 0,
                },
                confidentiality: {
                    none: 0,
                    partial: 0,
                    complete: 0,
                },
            };
            for (const key in data.vulnerabilities_details) {
                const cve = data.vulnerabilities_details[key];
                let impact_description = [];
                if (cve.cvss < 4) {
                    total_low += 1;
                    cve.severity = "primary";
                } else if (cve.cvss >= 4 && cve.cvss < 7) {
                    total_medium += 1;
                    cve.severity = "warning";
                } else {
                    total_high += 1;
                    cve.severity = "danger";
                }
                if (cve.impact) {
                    let count = 0;
                    if (cve.impact.availability) {
                        if (cve.impact.availability !== IMPACT_TYPE.NONE) {
                            count++;
                            total_availability += 1;
                            impact_description.push(
                                this.$t("general.availability")
                            );
                        }

                        if (cve.impact.availability == IMPACT_TYPE.PARTIAL)
                            this.serviceDetailsChart[
                                data.ip
                            ].availability.partial += 1;
                        else if (
                            cve.impact.availability == IMPACT_TYPE.COMPLETE
                        )
                            this.serviceDetailsChart[
                                data.ip
                            ].availability.complete += 1;
                        else
                            this.serviceDetailsChart[
                                data.ip
                            ].availability.none += 1;
                    }
                    if (cve.impact.integrity) {
                        if (cve.impact.integrity !== IMPACT_TYPE.NONE) {
                            count++;
                            total_integrity += 1;
                            impact_description.push(
                                this.$t("general.integrity")
                            );
                        }

                        if (cve.impact.integrity == IMPACT_TYPE.PARTIAL)
                            this.serviceDetailsChart[
                                data.ip
                            ].integrity.partial += 1;
                        else if (cve.impact.integrity == IMPACT_TYPE.COMPLETE)
                            this.serviceDetailsChart[
                                data.ip
                            ].integrity.complete += 1;
                        else
                            this.serviceDetailsChart[
                                data.ip
                            ].integrity.none += 1;
                    }
                    if (cve.impact.confidentiality) {
                        if (cve.impact.confidentiality !== IMPACT_TYPE.NONE) {
                            count++;
                            total_confidentiality += 1;
                            impact_description.push(
                                this.$t("general.confidentiality")
                            );
                        }

                        if (cve.impact.confidentiality == IMPACT_TYPE.PARTIAL)
                            this.serviceDetailsChart[
                                data.ip
                            ].confidentiality.partial += 1;
                        else if (
                            cve.impact.confidentiality == IMPACT_TYPE.COMPLETE
                        )
                            this.serviceDetailsChart[
                                data.ip
                            ].confidentiality.complete += 1;
                        else
                            this.serviceDetailsChart[
                                data.ip
                            ].confidentiality.none += 1;
                    }
                    total_gdpr += count;

                    cve.impact_description = impact_description;
                }
                vulnerabilities.push(cve);
            }

            data.total_high = total_high;
            data.total_medium = total_medium;
            data.total_low = total_low;
            data.total_gdpr = total_gdpr;
            data.total_confidentiality = total_confidentiality;
            data.total_integrity = total_integrity;
            data.total_availability = total_availability;
            data.vulnerabilities = vulnerabilities.sort((a, b) => {
                if (a.cvss < b.cvss) return 1;
                else return -1;
            });

            data.filters = {};
            this.detailedRows[`${data.ip}`] = data;
            // this.ipData.isModalDetailVisible = true;
        },
        detailByIp(ip) {
            return this.detailedRows[`${ip}`];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./Report";
</style>
<style lang="scss">
.p-datatable-tbody > .p-datatable-row-expansion {
    background: rgb(33, 33, 33) !important;
}

.text-20 {
    font-size: 20px;
}
pre[class*="language-"] > code {
    max-width: calc(100vw - 120px);
}
@media all and (min-width: 1025px) {
    pre[class*="language-"] > code {
        max-width: calc(100vw - 380px) !important;
    }
}
</style>
