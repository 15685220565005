<template>
    <div>
        <component 
            v-for="(question, index) in questions"
            :key="question.question.id"
            :is="getQuestionComponent(question)"
            :id="question.question.id"
            :question="question"
            :index="index"
            :updating="updating"
            @onValueChange="onValueChange" />
    </div>
</template>

<script>
import { useStore } from "@/store";

export default {
    name: "Questions",
    setup() {
        return {
            state: useStore().state,
            setState: useStore().setState,
        };
    },
    created() {
    },
    props: {
        questions: {
            type: Array,
            default: function () {
                return []
            },
        },
        updating: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    updated() {},
    watch: {
    },
    computed: {
    },
    methods: {
        getQuestionComponent (question) {
            return `question-${question.question.question_type}`
        },
        onValueChange (question, answer) {
            this.$emit('onValueChange', question, answer)
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
