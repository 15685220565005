<template>
    <div class="p-col-12 p-md-6">
        <div :key="chartKey" class="p-d-flex p-jc-center" id="serviceDetailsStackedHistogramWrapper">
            <Chart
                v-if="isVisible"
                type="bar"
                :height="270"
                :width="chartWidth"
                :data="chart.data"
                :options="chart.options"
            />
        </div>
    </div>
</template>

<script>
export default {
name: 'ServiceDetailsStackedHistogram',
    props: {
        chartData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            isVisible: false,
            chartKey: 1,
            chartWidth: 420,
            chart: {
                data: {
                    labels: [
                        this.$t("general.availability"),
                        this.$t("general.integrity"),
                        this.$t("general.confidentiality")
                    ],
                    datasets: []
                },
                options: {
                    responsive: false,
                    legend: {
                        display: false,
                        position: "bottom"
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#333'
                            }
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                suggestedMax: 100,
                                color: '#495057'
                            },
                            grid: {
                                color: '#333'
                            }
                        }
                    }
                }
            },
        }
    },
    methods: {
        setChartData() {
            this.chart.data.datasets = [{
                label: this.$t("general.low"),
                backgroundColor: [
                    this.$appColors.blue
                ],
                data: [
                    this.chartData.availability.none,
                    this.chartData.integrity.none,
                    this.chartData.confidentiality.none
                ],
            },
            {
                label: this.$t("general.medium"),
                backgroundColor: [
                    this.$appColors.yellow
                ],
                data: [
                    this.chartData.availability.partial,
                    this.chartData.integrity.partial,
                    this.chartData.confidentiality.partial
                ],
            },
            {
                label: this.$t("general.high"),
                backgroundColor: [
                    this.$appColors.red
                ],
                data: [
                    this.chartData.availability.complete,
                    this.chartData.integrity.complete,
                    this.chartData.confidentiality.complete
                ],
            }];
            this.isVisible = true;
        },
        setChartWidth() {
            this.chartWidth = document.getElementById('serviceDetailsStackedHistogramWrapper').clientWidth - 80;
            this.chartKey += 1;
        }
    },
    mounted() {
        this.setChartData();
        this.setChartWidth();
        window.addEventListener('resize', this.setChartWidth);
    }
}
</script>