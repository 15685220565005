<template>
    <div class="p-col-12">
        <div class="p-grid" v-if="!hide">
            <!-- / Breaches -->
            <div v-if="isFull" class="p-col-12 p-lg-8">
                <div class="card p-shadow-7 full-height">
                    <div class="swa-header">
                        <h3>{{ $t("pages.report.breaches") }}</h3>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText
                                v-model="filters['global']"
                                :placeholder="$t('general.global_search')"
                                @change="onFilter()"
                            />
                        </span>
                    </div>

                    <DataTable
                        :filters="filters"
                        :lazy="true"
                        :loading="loading"
                        :rowHover="true"
                        :scrollable="true"
                        :sortField="sortField"
                        :sortOrder="sortOrder"
                        :totalRecords="total_records"
                        :rows="page_size"
                        :paginator="true"
                        :value="records"
                        class="
                            p-datatable-sm p-datatable-responsive
                            min-height-400
                            max-height-600
                        "
                        @page="onPageChange($event)"
                        @sort="onPageChange($event)"
                    >
                        <Column
                            :header="$t('pages.report.fullname')"
                            field="source.fullname"
                        >
                            <template #body="slotProps">
                                <div>
                                    {{ slotProps.data.source.fullname }}
                                </div>
                            </template>
                        </Column>
                        <Column
                            :header="$t('general.risk')"
                            :sortable="true"
                            field="risk"
                            headerClass="column-max120"
                            bodyClass="column-max120"
                        >
                            <template #body="slotProps">
                                <Tag
                                    v-if="slotProps.data.risk === 'High'"
                                    severity="danger"
                                >
                                    {{ $t("general.high") }}
                                </Tag>
                                <Tag
                                    v-if="slotProps.data.risk === 'Medium'"
                                    severity="warning"
                                >
                                    {{ $t("general.medium") }}
                                </Tag>
                                <Tag
                                    v-if="slotProps.data.risk === 'Low'"
                                    severity="info"
                                >
                                    {{ $t("general.low") }}
                                </Tag>
                            </template>
                        </Column>
                        <Column
                            :header="$t('pages.report.year')"
                            :sortable="true"
                            field="source.year"
                            headerClass="column-max100"
                            bodyClass="column-max100"
                        >
                            <template #body="slotProps">
                                <div>
                                    {{ slotProps.data.source.year }}
                                </div>
                            </template>
                        </Column>
                        <Column
                            :header="$t('pages.report.features')"
                            field="source.features"
                        >
                            <template #body="slotProps">
                                <div>
                                    <li
                                        v-for="feature in slotProps.data.source.features.filter(
                                            function (e) {
                                                return e;
                                            }
                                        )"
                                        :key="feature"
                                    >
                                        {{
                                            translate(
                                                "pages.report.features_list",
                                                feature
                                            )
                                        }}<br />
                                    </li>
                                </div>
                            </template>
                        </Column>
                        <Column
                            :header="$t('pages.report.total')"
                            :sortable="true"
                            field="total"
                            headerClass="column-max100"
                            bodyClass="column-max100"
                        >
                            <template #body="slotProps">
                                <div>
                                    {{ slotProps.data.total }}
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
            <!-- / Breaches -->

            <!-- / UPGRADE MESSAGE -->
            <UpgradeComponent
                v-if="!isFull && loaded"
                :title="$t('pages.report.breaches')"
                className="p-col-12 p-lg-8"
            />
            <!-- / UPGRADE MESSAGE -->

            <div v-if="loaded" class="p-col-12 p-lg-4">
                <!-- Breaches -->
                <div class="card p-shadow-7 full-height">
                    <div class="p-text-center p-mb-4">
                        <strong>
                            {{ $t("general.breach_source") }}&nbsp;
                        </strong>
                    </div>
                    <Chart
                        id="breaches-chart"
                        :data="charts.data"
                        :options="charts.options"
                        type="doughnut"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ReportService } from "../../../services";
import { translateMethod } from "../../../commons";
import { DtiQuery } from "../../../pages/Reports/Report/query";

const COLORS = [
    "#1F2041",
    "#070A35",
    "#4B3F72",
    "#261F4F",
    "#0A2472",
    "#071749",
    "#19647E",
    "#134356",
    "#119DA4",
    "#247B7F",
];

export default {
    name: "Breaches",
    reportService: null,
    created() {
        this.reportService = new ReportService();
    },
    props: {
        reportId: {
            type: String,
            default: null,
        },
        loaded: {
            type: Boolean,
            default: () => false,
        },
        breach_chart: {
            type: Object,
            default: () => null,
        },
        isFull: {
            type: Boolean,
            default: () => true,
        },
    },
    data() {
        return {
            loading: true,
            hide: false,
            firstCall: true,
            records: [],
            total_records: 0,
            total_filtered: 0,
            sortField: "source.year",
            sortOrder: -1,
            page: 0,
            filters: {},
            page_size: 20,
            charts: {
                data: {
                    labels: [],
                    datasets: [],
                    legend: false,
                },
                options: {
                    legend: {
                        display: true,
                        position: "bottom",
                        align: "start",
                    },
                },
            },
        };
    },
    mounted() {
        if (this.loaded && this.isFull) {
            this.getBreaches();
            this.drawChart();
        }
    },
    updated() {},
    watch: {
        loaded(newVal, oldVal) {
            if (newVal && newVal !== oldVal && this.isFull) {
                this.getBreaches();
            }
        },
    },
    methods: {
        async getBreaches() {
            this.loading = true;
            try {
                const skip = this.page * this.page_size || 0;

                const search =
                    this.filters.global && this.filters.global.length > 0
                        ? `${this.filters.global}`
                        : "";

                const response = await this.reportService.graphql({
                    api: this.reportId,
                    method: "get",
                    args: {
                        params: {
                            query: DtiQuery.breaches(this.page_size, skip, {
                                sort_by: this.sortField,
                                order: this.sortOrder,
                                search,
                            }),
                        },
                    },
                });

                const { breach_data } = response.data.result;
                this.records = breach_data.items;
                this.total_records = breach_data.total || 0;
                this.total_filtered = breach_data.total || 0;

                if (this.firstCall) {
                    this.firstCall = false;
                    if (this.total_records === 0) {
                        this.hide = true;
                    }
                }
            } catch (error) {
                if (error.toString().indexOf("404") === -1) {
                    console.warn(error);
                }
            } finally {
                this.loading = false;
            }
        },
        drawChart() {
            const data = [];
            const labels = [];
            const datasets = [];
            const colors = [];
            let i = 0;
            for (const row of this.breach_chart) {
                labels.push(row.name);
                data.push(row.total);
                colors.push(COLORS[i]);
                datasets.push({
                    label: row.name,
                    backgroundColor: [this.$appColors.red],
                    data: [row.total],
                });
                i++;
            }

            this.charts.data.labels = labels;
            this.charts.data.datasets = [
                {
                    label: "",
                    backgroundColor: colors,
                    data,
                },
            ];

            setTimeout(() => {
                const container = document.getElementById("breaches-chart");
                const canvas = container.children[0];
                canvas.style.maxHeight = "600px";
            }, 500);
        },
        onPageChange(event) {
            this.page = event.page !== undefined ? event.page : this.page;
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.getBreaches();
        },
        onFilter() {
            this.onPageChange({
                page: 0,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
            });
        },
        ...translateMethod,
    },
};
</script>

<style lang="scss" scoped>
.vertical-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
