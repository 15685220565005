<template>
    <div
        v-if="summary"
        class="full-width cve-details-container"
    >
        <!-- / UPGRADE MESSAGE -->
        <UpgradeComponent
            v-if="!isFull"
            :title="$t('pages.report.cve_details')"
        />
        <!-- / UPGRADE MESSAGE -->

        <!-- CVE Details -->
        <div class="p-col-12" v-if="isFull">
            <div class="card p-shadow-7">
                <div class="swa-header">
                    <h3>{{ $t("pages.report.cve_details") }}</h3>
                </div>

                <div class="p-grid">
                    <!-- High -->
                    <div class="p-col-12 p-lg-4">
                        <div class="card danger card-rounded">
                            <div class="p-text-right">
                                <strong>{{
                                    $t("general.high_severity")
                                }}</strong>
                            </div>
                            <div class="card-value p-text-right">
                                {{ summary.cve_high_total }}
                            </div>
                        </div>
                        <DataTable
                            :filters="cve.high.filters"
                            :lazy="true"
                            :loading="loading.high"
                            :paginator="true"
                            :rowHover="true"
                            :rows="10"
                            :sortField="cve.high.sortField"
                            :sortOrder="cve.high.sortOrder"
                            :totalRecords="cve.high.total_filtered"
                            :value="cve.high.records"
                            class="p-datatable-sm"
                            @page="onCVEChange($event, 'high')"
                            @sort="onCVEChange($event, 'high')"
                        >
                            <template #header>
                                <div class="table-header">
                                    <span
                                        class="p-input-icon-left"
                                        style="
                                            flex: 1;
                                            margin-left: -0.5rem;
                                            margin-right: -0.5rem;
                                        "
                                    >
                                        <i class="pi pi-search" />
                                        <InputText
                                            v-model="cve.high.filters['global']"
                                            :placeholder="
                                                $t('general.global_search')
                                            "
                                            style="width: 100%"
                                            @change="onCVEFilter('high')"
                                        />
                                    </span>
                                </div>
                            </template>
                            <Column
                                bodyStyle="text-align: center;"
                                field="cve"
                                header="CVE"
                                headerClass="header-centered">
                                <template #body="slotProps">
                                    <a :href="`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${slotProps.data.cve}`" target="_blank">{{ slotProps.data.cve }}</a>
                                </template>
                            </Column>
                            <Column
                                :sortable="true"
                                bodyStyle="text-align: center;"
                                field="cvss"
                                :header="$t('pages.report.cvssv2')"
                                headerClass="header-centered"
                            />
                            <Column
                                :header="$t('general.ip_address')"
                                :sortable="true"
                                bodyStyle="text-align: center;"
                                field="ip"
                                headerClass="header-centered"
                            >
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.ip">
                                        {{ slotProps.data.ip }}
                                    </div>
                                    <div v-if="!slotProps.data.ip">-</div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <!-- High -->

                    <!-- Medium -->
                    <div class="p-col-12 p-lg-4">
                        <div class="card warning card-rounded">
                            <div class="p-text-right">
                                <strong>{{
                                    $t("general.medium_severity")
                                }}</strong>
                            </div>
                            <div class="card-value p-text-right">
                                {{ summary.cve_medium_total }}
                            </div>
                        </div>
                        <DataTable
                            :filters="cve.medium.filters"
                            :lazy="true"
                            :loading="loading.medium"
                            :paginator="true"
                            :rowHover="true"
                            :rows="10"
                            :sortField="cve.medium.sortField"
                            :sortOrder="cve.medium.sortOrder"
                            :totalRecords="cve.medium.total_filtered"
                            :value="cve.medium.records"
                            class="p-datatable-sm"
                            @page="onCVEChange($event, 'medium')"
                            @sort="onCVEChange($event, 'medium')"
                        >
                            <template #header>
                                <div class="table-header">
                                    <span
                                        class="p-input-icon-left"
                                        style="
                                            flex: 1;
                                            margin-left: -0.5rem;
                                            margin-right: -0.5rem;
                                        "
                                    >
                                        <i class="pi pi-search" />
                                        <InputText
                                            v-model="
                                                cve.medium.filters['global']
                                            "
                                            :placeholder="
                                                $t('general.global_search')
                                            "
                                            style="width: 100%"
                                            @change="onCVEFilter('medium')"
                                        />
                                    </span>
                                </div>
                            </template>
                            <Column
                                bodyStyle="text-align: center;"
                                field="cve"
                                header="CVE"
                                headerClass="header-centered">
                                <template #body="slotProps">
                                    <a :href="`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${slotProps.data.cve}`" target="_blank">{{ slotProps.data.cve }}</a>
                                </template>
                            </Column>
                            <Column
                                :sortable="true"
                                bodyStyle="text-align: center;"
                                field="cvss"
                                :header="$t('pages.report.cvssv2')"
                                headerClass="header-centered"
                            />
                            <Column
                                :header="$t('general.ip_address')"
                                :sortable="true"
                                bodyStyle="text-align: center;"
                                field="ip"
                                headerClass="header-centered"
                            >
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.ip">
                                        {{ slotProps.data.ip }}
                                    </div>
                                    <div v-if="!slotProps.data.ip">-</div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <!-- Medium -->

                    <!-- Low -->
                    <div class="p-col-12 p-lg-4">
                        <div class="card primary card-rounded">
                            <div class="p-text-right">
                                <strong>{{
                                    $t("general.low_severity")
                                }}</strong>
                            </div>
                            <div class="card-value p-text-right">
                                {{ summary.cve_low_total }}
                            </div>
                        </div>
                        <DataTable
                            :filters="cve.low.filters"
                            :lazy="true"
                            :loading="loading.low"
                            :paginator="true"
                            :rowHover="true"
                            :rows="10"
                            :sortField="cve.low.sortField"
                            :sortOrder="cve.low.sortOrder"
                            :totalRecords="cve.low.total_filtered"
                            :value="cve.low.records"
                            class="p-datatable-sm"
                            @page="onCVEChange($event, 'low')"
                            @sort="onCVEChange($event, 'low')"
                        >
                            <template #header>
                                <div class="table-header">
                                    <span
                                        class="p-input-icon-left"
                                        style="
                                            flex: 1;
                                            margin-left: -0.5rem;
                                            margin-right: -0.5rem;
                                        "
                                    >
                                        <i class="pi pi-search" />
                                        <InputText
                                            v-model="cve.low.filters['global']"
                                            :placeholder="
                                                $t('general.global_search')
                                            "
                                            style="width: 100%"
                                            @change="onCVEFilter('low')"
                                        />
                                    </span>
                                </div>
                            </template>
                            <Column
                                bodyStyle="text-align: center;"
                                field="cve"
                                header="CVE"
                                headerClass="header-centered">
                                <template #body="slotProps">
                                    <a :href="`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${slotProps.data.cve}`" target="_blank">{{ slotProps.data.cve }}</a>
                                </template>
                            </Column>
                            <Column
                                :sortable="true"
                                bodyStyle="text-align: center;"
                                field="cvss"
                                :header="$t('pages.report.cvssv2')"
                                headerClass="header-centered"
                            />
                            <Column
                                :header="$t('general.ip_address')"
                                :sortable="true"
                                bodyStyle="text-align: center;"
                                field="ip"
                                headerClass="header-centered"
                            >
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.ip">
                                        {{ slotProps.data.ip }}
                                    </div>
                                    <div v-if="!slotProps.data.ip">-</div>
                                </template>
                            </Column>
                            <template #empty>{{ $t('general.no_data_available') }}</template>
                        </DataTable>
                    </div>
                    <!-- Low -->
                </div>
            </div>
        </div>
        <!-- CVE Details -->
    </div>
</template>

<script>
import { ReportService } from "../../../services";
import { DtiQuery } from "../../../pages/Reports/Report/query";

const RECORDS_PER_PAGE = 10;
const WIDTH = 1200;

const listener = () => {
    const elements = document.querySelectorAll(
        ".cve-details-container .p-datatable-wrapper"
    );
    let height = 0;
    for (const el of elements) {
        if (el.clientHeight > height) {
            el.style.height = "";
        }
    }

    if (window.innerWidth > WIDTH) {
        for (const el of elements) {
            if (el.clientHeight > height) {
                height = el.clientHeight;
            }
        }

        for (const el of elements) {
            el.style.height = height + "px";
        }
    }
};

export default {
    name: "CveDetail",
    reportService: null,
    listenerContext: null,
    created() {
        this.reportService = new ReportService();
        this.listenerContext = listener.bind(this);
        window.addEventListener("resize", this.listenerContext);
    },
    props: {
        reportId: {
            type: String,
            default: null,
        },
        summary: {
            type: Object,
        },
        isFull: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        isCveVisible() {
            return (
                this.summary &&
                ((this.summary.cve_high_total &&
                    this.summary.cve_high_total > 0) ||
                    (this.summary.cve_medium_total &&
                        this.summary.cve_medium_total > 0) ||
                    (this.summary.cve_low_total &&
                        this.summary.cve_low_total > 0))
            );
        },
        cveClasses() {
            // INIT
            let classes = ["p-col-12"];
            let counter = 0;

            // CHECK
            if (
                this.summary &&
                this.summary.cve_high_total &&
                this.summary.cve_high_total > 0
            )
                counter += 1;
            if (
                this.summary &&
                this.summary.cve_medium_total &&
                this.summary.cve_medium_total > 0
            )
                counter += 1;
            if (
                this.summary &&
                this.summary.cve_low_total &&
                this.summary.cve_low_total > 0
            )
                counter += 1;

            // ADD
            if (counter == 2) classes.push("p-xl-6");
            if (counter == 3) classes.push("p-xl-4");

            // JOIN
            return classes.join(" ");
        },
    },
    watch: {
        summary(newVal, oldVal) {
        },
    },
    data() {
        return {
            loading: {
                high: true,
                medium: true,
                low: true,
            },
            cve: {
                high: {
                    records: [],
                    total_records: 0,
                    total_filtered: 0,
                    page: 0,
                    filters: {},
                    sortField: "cvss",
                    sortOrder: -1,
                },
                medium: {
                    records: [],
                    total_records: 0,
                    total_filtered: 0,
                    page: 0,
                    filters: {},
                    sortField: "cvss",
                    sortOrder: -1,
                },
                low: {
                    records: [],
                    total_records: 0,
                    total_filtered: 0,
                    page: 0,
                    filters: {},
                    sortField: "cvss",
                    sortOrder: -1,
                },
            },
        };
    },
    mounted() {
        this.$nextTick(() => {
            if (this.isFull) {
                this.getCVEDetails("high");
                this.getCVEDetails("medium");
                this.getCVEDetails("low");
            }
        })
    },
    updated() {},
    unmounted() {
        window.removeEventListener("resize", this.listenerContext);
    },
    methods: {
        async getCVEDetails(level) {
            this.loading[level] = true;
            try {
                const skip = this.cve[level].page * RECORDS_PER_PAGE || 0;
                const cve_query = `cve_ordered_${level}`;
                const search =
                    this.cve[level].filters.global &&
                    this.cve[level].filters.global.length > 0
                        ? `${this.cve[level].filters.global}`
                        : "";
                const sort_by = this.cve[level].sortField;
                const order = this.cve[level].sortOrder;
                const query = DtiQuery.cve(cve_query, this.page_size, skip, {
                    sort_by,
                    order,
                    search,
                });

                const response = await this.reportService.graphql({
                    api: this.reportId,
                    method: "get",
                    args: {
                        params: {
                            query,
                        },
                    },
                });
                this.cve[level] = {
                    ...this.cve[level],
                    total_records: response.data.result[cve_query].total,
                    total_filtered: response.data.result[cve_query].total,
                    records: response.data.result[cve_query].items,
                };
            } catch (error) {
                if (error.toString().indexOf("404") === -1) {
                    console.warn(error);
                }
            }
            this.loading[level] = false;
            setTimeout(() => listener(), 100);
        },
        onCVEChange(event, level) {
            this.cve[level].page =
                event.page !== undefined ? event.page : this.cve[level].page;
            this.cve[level].sortField = event.sortField;
            this.cve[level].sortOrder = event.sortOrder;
            this.getCVEDetails(level);
        },
        onCVEFilter(level) {
            this.onCVEChange(
                {
                    page: 0,
                    sortField: this.cve[level].sortField,
                    sortOrder: this.cve[level].sortOrder,
                },
                level
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./Report";
</style>
