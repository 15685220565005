import { ENV_VARS } from "@/env_vars";
import { BaseService } from "./BaseService";
import { RouterService } from "./RouterService/RouterService";
import { Routes } from "./RouterService/Routes";

export class ReportService extends BaseService {
  constructor() {
    super();
    this.BASE_URL = ENV_VARS.VUE_APP_BACKEND_BASE_URL;
    this.BASE_API = Routes.REPORTS;
  }

  getFileUrl(file) {
    const headers = RouterService.getHeaders();
    const bearer = headers["Authorization"];

    return this.BASE_URL + this.BASE_API + file.url + "?token=" + bearer.replace("Bearer ", "");
  }
}

//* GENERIC
/**
 * @api {get} https://backend.platform.swascan.com/core/tasks/<TASK_ID>/ Get task details
 * @apiVersion 1.0.0
 * @apiName ReportDetail
 * @apiGroup Report All
 *
 * @apiSampleRequest https://core.beta.swascan.com/core/tasks/TASK_ID/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiSuccess {String} creation_time       Creation date.
 * @apiSuccess {Object} result              Task result.
 * @apiSuccess {String} status              Task status.
 * @apiSuccess {String} task_id             Task id.
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *          "creation_time": "Fri, 23 Apr 2021 06:02:28 GMT",
 *          "result": {
 *              "message": "Task succesfully completed"
 *          },
 *          "status": "SUCCESS",
 *          "task_id": "0e589bb1-f057-4c23-bf98-51761e24885a"
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/core/tasks/<TASK_ID>/files Get reports files
 * @apiVersion 1.0.0
 * @apiName ReportFiles
 * @apiGroup Report All
 *
 * @apiSampleRequest https://core.beta.swascan.com/core/tasks/TASK_ID/files
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiSuccess {String[]} files               Files list
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *          "files": [
 *              "full_report.pdf",
 *              "remediation_report_ita.pdf",
 *              "executive_report.pdf",
 *              "vulnerabilities_report.pdf",
 *              "remediation_report_eng.pdf",
 *              "report.zip"
 *          ]
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/core/tasks/<TASK_ID>/files/<FILENAME>/?token?<BEARER_TOKEN></BEARER_TOKEN> download file
 * @apiVersion 1.0.0
 * @apiName ReportFiles
 * @apiGroup Report All
 *
 * @apiSampleRequest https://core.beta.swascan.com/core/tasks/TASK_ID/files/report.zip/?token=eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A
 *
 */
/**
 * @api {delete} https://backend.beta.swascan.com/reports/ Delete reports
 * @apiVersion 1.0.0
 * @apiName DeleteReports
 * @apiGroup Report All
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {List} [reports_ids]        Reports id list
 * @apiParamExample {json} Body-Example:
 *      {
 *          "reports_ids": "["03bdc2c3-88ef-45c1-9509-5aa702ddc5f9","6f39e439-b46a-4039-b770-ef6fbbf77bea","7b2c829d-95ac-41b3-abbf-9b641e64d7ef","af860df6-fa0a-4ea7-b28c-2a64d6f1dc2d","facb8a54-bc03-4d13-b407-dc78f1781fb2"],
 *     }
 */
/**
 * @api {delete} https://backend.beta.swascan.com/reports/<REPORT_ID> Delete report
 * @apiVersion 1.0.0
 * @apiName DeleteReport
 * @apiGroup Report All
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/5b4b20e3-7dce-4e15-af8f-954cbe2bd5f4
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 */

//* DTI

/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report summary
 * @apiVersion 1.0.0
 * @apiName Summary
 * @apiGroup Report DTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 * @apiParamExample {json} Query-Example:
 *      query { summary { breach_total email_total breach_source_total impact_availability_total impact_confidentiality_total impact_integrity_total cve_high_unique_total cve_medium_unique_total cve_low_unique_total breach_total breach_source_total impact_availability_total impact_confidentiality_total impact_integrity_total cve_high_unique_total cve_medium_unique_total cve_low_unique_total cve_total cve_high_total cve_medium_total cve_low_total cve_unique_total impact_availability_unique_total impact_confidentiality_unique_total impact_integrity_unique_total total_ip_found total_subdomain_found breach_chart { name total } breach_chart_risk { breach_total_high breach_total_medium breach_total_low } target target_cleaned license_info { name api scans_max full_mode binary_edge_ip_score id user_id user_role scans_done expires_on }}}
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "summary": {
 *                 "breach_total": 176.0,
 *                 "email_total": 176.0,
 *                 "breach_source_total": 17.0,
 *                 "impact_availability_total": 0.0,
 *                 "impact_confidentiality_total": 0.0,
 *                 "impact_integrity_total": 0.0,
 *                 "cve_high_unique_total": 0.0,
 *                 "cve_medium_unique_total": 0.0,
 *                 "cve_low_unique_total": 0.0,
 *                 "cve_total": 0.0,
 *                 "cve_high_total": 0.0,
 *                 "cve_medium_total": 0.0,
 *                 "cve_low_total": 0.0,
 *                 "cve_unique_total": 0.0,
 *                 "impact_availability_unique_total": 1.0,
 *                 "impact_confidentiality_unique_total": 1.0,
 *                 "impact_integrity_unique_total": 1.0,
 *                 "total_ip_found": 45.0,
 *                 "total_subdomain_found": 118.0,
 *                 "breach_chart": [{
 *                     "name": "the-collections",
 *                     "total": 39.0
 *                 }, {
 *                     "name": "linkedin",
 *                     "total": 28.0
 *                 }, {
 *                     "name": "breachcompilation",
 *                     "total": 23.0
 *                 }, {
 *                     "name": "verificationsio",
 *                     "total": 21.0
 *                 }, {
 *                     "name": "antipublic",
 *                     "total": 13.0
 *                 }, {
 *                     "name": "other",
 *                     "total": 52.0
 *                 }],
 *                 "breach_chart_risk": {
 *                     "breach_total_high": 1.0,
 *                     "breach_total_medium": 7.0,
 *                     "breach_total_low": 9.0
 *                 },
 *                 "target": "ania.it",
 *                 "target_cleaned": "ania.it",
 *                 "license_info": {
 *                     "name": "DTI Staff",
 *                     "api": "dti/1.0",
 *                     "scans_max": 999.0,
 *                     "full_mode": true,
 *                     "binary_edge_ip_score": false,
 *                     "id": "bc2da29a-9222-45ce-afa2-28c66bcd4182",
 *                     "user_id": "11c13870-6e4f-4cb2-9a50-331f97415689",
 *                     "user_role": null,
 *                     "scans_done": 13.0,
 *                     "expires_on": "2022-03-16T11:09:21Z"
 *                 }
 *             }
 *         }
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report CVE High List
 * @apiVersion 1.0.0
 * @apiName CVEOrderedHih
 * @apiGroup Report DTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { cve_ordered_high { items(search "search string", sort_by:"sort field", order: -1, first: 0, skip: 0) { cve cvss ip } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "cve_ordered_high": {
 *                 "items": [{
 *                      "cve":"CVE-2010-0425",
 *                      "cvss":10.0,
 *                      "ip":"62.110.42.103"
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report CVE Medium List
 * @apiVersion 1.0.0
 * @apiName CVEOrderedMedium
 * @apiGroup Report DTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { cve_ordered_medium { items(search "search string", sort_by:"sort field", order: -1, first: 0, skip: 0) { cve cvss ip } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "cve_ordered_medium": {
 *                 "items": [{
 *                      "cve":"CVE-2010-0425",
 *                      "cvss":6.2,
 *                      "ip":"62.110.42.103"
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report CVE Low List
 * @apiVersion 1.0.0
 * @apiName CVEOrderedLow
 * @apiGroup Report DTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { cve_ordered_low { items(search "search string", sort_by:"sort field", order: -1, first: 0, skip: 0) { cve cvss ip } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "cve_ordered_low": {
 *                 "items": [{
 *                      "cve":"CVE-2010-0425",
 *                      "cvss":2.3,
 *                      "ip":"62.110.42.103"
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get Breaches List
 * @apiVersion 1.0.0
 * @apiName Breaches
 * @apiGroup Report DTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { breach_data { items(search "search string", sort_by:"sort field", order: -1, first: 0, skip: 0) { source { data date description features fullname logo name year } total risk } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "breach_data": {
 *                 "items": [{
 *                      "source": {
 *                           "data": "Names, Hashed Passwords, Physical Addresses, Phone numbers, Document Titles",
 *                           "date": null,
 *                           "description": "In September 2020, the Nitro PDF service suffered a massive data breach which exposed over 70 million unique email addresses. The breach also exposed names, bcrypt password hashes and the titles of converted documents.",
 *                           "features": ["Names", "Hashed Passwords", "Physical Addresses", "Phone numbers", "Document Titles"],
 *                           "fullname": "Nitro",
 *                           "logo": "https://be-resources.s3-eu-west-1.amazonaws.com/dataleaks/nitro.png",
 *                           "name": "nitro",
 *                           "year": 2020
 *                      },
 *                      "total": 1,
 *                      "risk": "High"
 *                  }, ...],
 *                  "total": 8
 *             }
 *         }
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get Typosquatting List
 * @apiVersion 1.0.0
 * @apiName Typosquatting
 * @apiGroup Report DTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { typosquatting { items(search "search string", sort_by:"sort field", order: -1, first: 0, skip: 0) { data { creation_date expiration_date name registrar risk } dns_a dns_mx dns_ns domain } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "typosquatting": {
 *                 "items": [{
 *                      "data": {
 *                           "creation_date": "2005-12-06 00:00:00",
 *                           "expiration_date": "2021-12-06 00:00:00",
 *                           "name": "angelaghezzi.it",
 *                           "registrar": "",
 *                           "risk": "Low"
 *                      },
 *                      "dns_a": ["195.110.124.188"],
 *                      "dns_mx": ["mail.register.it"],
 *                      "dns_ns": ["ns1.register.it"],
 *                      "domain": "angelaghezzi.it"
 *                  }, ...],
 *                  "total": 3
 *             }
 *         }
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get Subdomains List
 * @apiVersion 1.0.0
 * @apiName Subdomains
 * @apiGroup Report DTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { subdomain_data { items(search "search string", sort_by:"sort field", order: -1, first: 0, skip: 0) { ip_list subdomain technologies { app ver type } } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "subdomain_data": {
 *                 "items": [{
 *                      "ip_list": ["62.110.42.103"],
 *                      "subdomain": "angeloghezzi.it",
 *                      "technologies": [{
 *                           "app": "Apache",
 *                           "ver": "2.2.3",
 *                           "type": "Web Servers"
 *                      },{
 *                           "app": "CentOS",
 *                           "ver": null,
 *                           "type": "Operating Systems"
 *                      }]
 *                  }, ...],
 *                  "total": 4
 *             }
 *         }
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get Services List
 * @apiVersion 1.0.0
 * @apiName Services
 * @apiGroup Report DTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { ip_data { items(search "search string", sort_by:"sort field", order: -1, first: 0, skip: 0) { ip vulnerabilities_list_count subdomains ports_list vulnerabilities_list cpe_list ports_details vulnerabilities_details data { region_code tags isp area_code domains hostnames postal_code dma_code country_code org asn city latitude longitude isp last_update country_code3 } } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "ip_data": {
 *                 "items": [{
 *                      "ip": "62.110.42.103",
 *                      "vulnerabilities_list_count": 53.0,
 *                      "subdomains": ["www.angeloghezzi.it", "angeloghezzi.it"],
 *                      "ports_list": [80],
 *                      "vulnerabilities_list": ["CVE-2008-2939", ...],
 *                      "cpe_list": ["cpe:/a:apache:http_server:2.2.3", ...],
 *                      "ports_details": {
 *                          "80": {
 *                              "vulns": {
 *                                  "CVE-2008-2939": {
 *                                      "verified": false,
 *                                      "references": ["http://lists.apple.com/archives/security-announce/2009/May/msg00002.html", ...],
 *                                      "cvss": "4.3",
 *                                      "summary": "Cross-site scripting (XSS) vulnerability in proxy_ftp.c in the mod_proxy_ftp module in Apache 2.0.63 and earlier, and mod_proxy_ftp.c in the mod_proxy_ftp module in Apache 2.2.9 and earlier 2.2 versions, allows remote attackers to inject arbitrary web script or HTML via a wildcard in the last directory component in the pathname in an FTP URI."
 *                                  }
 *                                  ...
 *                              },
 *                              "hash": -222574294,
 *                              "port": 80,
 *                              "transport": "tcp",
 *                              "version": "2.2.3",
 *                              "location": {
 *                                  "city": "Milan",
 *                                  "region_code": "09",
 *                                  "area_code": null,
 *                                  "longitude": 9.18951,
 *                                  "country_code3": null,
 *                                  "latitude": 45.46427,
 *                                  "postal_code": null,
 *                                  "dma_code": null,
 *                                  "country_code": "IT",
 *                                  "country_name": "Italy"
 *                              },
 *                              "ip": 1047407207,
 *                              "product": "Apache httpd",
 *                              "http": {
 *                                  "robots_hash": null,
 *                                  "redirects": [],
 *                                  "securitytxt": null,
 *                                  "title": null,
 *                                  "sitemap_hash": null,
 *                                  "robots": null,
 *                                  "favicon": {
                                        "data": "AAABAAEAEBAQAAAAAAAoAQAAFgAAACgAAAAQAAAAIAAAAAEABAAAAAAAgAAAAAAAAAAAAAAAEAAA\nAAAAAAAAAAAA6LlwAP///wDlsmEA/ fjwANaCAAD6798A3powAPjr1wDjqlEA68GBAAAAAAAAAAAA\nAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIiAAAAAAAAVVUgAFVVVVVV\nVVAARVVVV1VVVAAFVVV1VZVVAABVUlVRVVUwAFVVVVVVVVIABVVVZVVVVQAKVVUAAAAAAABVWAAA\nAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD//wAA//8AAP//AAD/jwAA/wcAAAAHAAAAAwAAgAMA\nAMABAADAAAAA4AAAAOD/AADw/wAA//8AAP//AAD//wAA\n",
 *                                      "hash": 507741836,
 *                                      "location": "http://62.110.42.103:80/favicon.ico"
 *                                  },
 *                                  "host": "62.110.42.103",
 *                                  "html": "<html>\n<header>\n</header>\n<body align=\"center\">\n<form id=\"frmDefault\" name=\"frmDefault\" action=\"/public/home.php\" method=\"post\" >\n</form> \n<script>\n  var frm = document.getElementById('frmDefault');\n  frm.submit();\n</script>\n</body>\n</html>\n",
 *                                  "location": "/",
 *                                  "components": {},
 *                                  "securitytxt_hash": null,
 *                                  "server": "Apache/2.2.3 (CentOS)",
 *                                  "sitemap": null,
 *                                  "html_hash": -28244320
 *                              },
 *                              "timestamp": "2021-04-09T04:13:13.844230",
 *                              "hostnames": ["host-62-110-42-103.business.telecomitalia.it"],
 *                              "org": "ANGELO GHEZZI & C. SPA",
 *                              "data": "HTTP/1.1 200 OK\r\nDate: Fri, 09 Apr 2021 04:34:44 GMT\r\nServer: Apache/2.2.3 (CentOS)\r\nLast-Modified: Tue, 18 Jun 2013 13:43:20 GMT\r\nETag: \"1960dd5-f1-e566ee00\"\r\nAccept-Ranges: bytes\r\nContent-Length: 241\r\nConnection: close\r\nContent-Type: text/html\r\n\r\n",
 *                              "asn": "AS3269",
 *                              "info": "(CentOS)",
 *                              "isp": "Telecom Italia S.p.A.",
 *                              "cpe": ["cpe:/a:apache:http_server:2.2.3"],
 *                              "domains": ["telecomitalia.it"],
 *                              "ip_str": "62.110.42.103",
 *                              "os": null,
 *                              "opts": {}
 *                          }
 *                      },
 *                      "vulnerabilities_details": {
 *                          "CVE-2008-2939": {
 *                              ...
 *                          },
 *                          ...
 *                      }
 *                  }, ...],
 *                  "total": 4
 *             }
 *         }
 *     }
 */

//* CTI
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report summary
 * @apiVersion 1.0.0
 * @apiName Summary
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 * @apiParamExample {json} Query-Example:
 *      query { summary { attack_surface_count botnets_count breaches_count darkweb_count ip_count subdomain_count registered_phishing_count unregistered_phishing_count vulnerabilities_count domain breaches_risks { total high medium low none } darkwebs_risks { total high medium low none } ip_risks { total high medium low none } vulnerabilities_severity { total high medium low none } license_info {  name api scans_max  full_mode  binary_edge_ip_score  id  user_id  user_role  scans_done  expires_on } extra_targets darkwebs_years } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "summary": {
 *                  "attack_surface_count": 20,
 *                  "botnets_count": 1689,
 *                  "breaches_count": 3443,
 *                  "darkweb_count": 59,
 *                  "ip_count": 5,
 *                  "subdomain_count": 295,
 *                  "registered_phishing_count": 2,
 *                  "unregistered_phishing_count": 4038,
 *                  "vulnerabilities_count": 32,
 *                  "breaches_risks": { "total": 3443, "critical": 12, "high": 2821, "medium": 0, "low": 610, "none": 0 },
 *                  "ip_risks": { "total": 5, "critical": 0, "high": 0, "medium": 0, "low": 3, "none": 2 },
 *                  "vulnerabilities_severity": { "total": 32, "critical": 0, "high": 6, "medium": 0, "low": 26, "none": 0 },
 *                  "domain": "domain_name.it",
 *                  "license_info": {
 *                      "name": "CTI Staff",
 *                      "api": "cti/1.0",
 *                      "scans_max": 999.0,
 *                      "full_mode": true,
 *                      "binary_edge_ip_score": false,
 *                      "id": "bc2da29a-9222-45ce-afa2-28c66bcd4182",
 *                      "user_id": "11c13870-6e4f-4cb2-9a50-331f97415689",
 *                      "user_role": null,
 *                      "scans_done": 13.0,
 *                      "expires_on": "2022-03-16T11:09:21Z"
 *                  }
 *                  "extra_targets": []
 *              }
 *          }
 *      }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report attack surface
 * @apiVersion 1.0.0
 * @apiName Arrack Surface
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { attack_surface { items(first: 20, skip: 0, sort_by: "higher_cvss", order: 1, sesrch: "search") { _id ip_address higher_cvss results_detailed { is_load_balanced ports { open } cve { cpe port cve_list { cve cvss severity } } } vulnerabilities { cve verified references cvss summary severity } target } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "attack_surface": {
 *                  items: [{
 *                      "_id": "324bce78-3281-4503-9f05-d1ec15c0626c",
 *                      "results_detailed": {
 *                          "ports": null,
 *                          "cve": [{
 *                              "cpe": "cpe:/a:openbsd:openssh:7.9:p1",
 *                              "cve_list": [{ "cve": "CVE-2020-15778", "cvss": 6.8, "severity": "Medium" }]
 *                               "port": 22
 *                          }],
 *                          "is_load_balancer": false
 *                      },
 *                      "ip_address": "89.97.235.40",
 *                      "higher_cvss": 7.8
 *                      "vulnerabilities": [{
 *                          "cve": "CVE-2010-2068",
 *                          "verified": false,
 *                          "references": [ "http://httpd.apache.org/security/vulnerabilities_22.html" ],
 *                          "cvss": 5.0,
 *                          "summary": "mod_proxy_http.c in mod_proxy_http in the Apache HTTP Server 2.2.9 through 2.2.15, 2.3.4-alpha, and 2.3.5-alpha on Windows, NetWare, and OS/2, in certain configurations involving proxy worker pools, does not properly detect timeouts, which allows remote attackers to obtain a potentially sensitive response intended for a different client in opportunistic circumstances via a normal HTTP request.",
 *                          "severity": "Medium"
 *                      }]
 *                  }]
 *             }
 *         }
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report botnets
 * @apiVersion 1.0.0
 * @apiName Botnets
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query (use_aggregation --> use aggregated function or not. With aggregated function info and _id are null and count is valorize. Viceversa with use_aggregation = false)
 *
 * @apiParamExample {json} Query-Example:
 *      query { botnets { items(first: 20, skip: 0, sort_by: "time", order: 1, search: "search", use_aggregation: true) { _id ip botnet hostname time info count target } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "botnets": {
 *                  items: [{
 *                      "_id": "324bce78-3281-4503-9f05-d1ec15c0626c",
 *                      "ip": "87.13.146.216",
 *                      "botnet": "2021.08_2/raccoon_1",
 *                      "hostname": "LAPTOP-AR29J7HM(jacop)",
 *                      "time": 1628640000,
 *                      "info": null,
 *                      "count": 2
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report botnet info list
 * @apiVersion 1.0.0
 * @apiName Botnets Info
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { botnets_info { items(first: 20, skip: 0, sort_by: "time", order: 1, ip: "87.13.146.216", botnet: "2021.08_2/raccoon_1", hostname: "LAPTOP-AR29J7HM(jacop)", time: 1628640000) { _id ip info } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "botnets": {
 *                  items: [{
 *                      "_id": null,
 *                      "ip": "87.13.146.216",
 *                      "botnet": "2021.08_2/raccoon_1",
 *                      "hostname": "LAPTOP-AR29J7HM(jacop)",
 *                      "time": 1628640000
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */

/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report breaches
 * @apiVersion 1.0.0
 * @apiName Breaches
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query (search on: ip, email, username, risk)
 *
 * @apiParamExample {json} Query-Example:
 *      query { breaches { items(first: 20, skip: 0, sort_by: "risk_value", order: 1, sesrch: "search") { _id email username password password_hash ip risk risk_value source { name url actor_name description accounts score add_date attack_vector geography category compromised_data is_imported meta } target } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "breaches": {
 *                  items: [{
 *                      "_id": "324bce78-3281-4503-9f05-d1ec15c0626c",
 *                      "email": "fake@mail.it",
 *                      "username": "username",
 *                      "password": "fake_password",
 *                      "password_hash": "password_hash",
 *                      "ip": "",
 *                      "source": {
 *                          "name": "ya.ru combo 271gb",
 *                          "url": "url",
 *                          "actor_name": "actor_name",
 *                          "description": "description",
 *                          "accounts": 1086587122,
 *                          "score": 100,
 *                          "add_date": 1620761838,
 *                          "attack_vector": "attack_vector",
 *                          "geography": ["International"],
 *                          "category": "Social Network",
 *                          "compromised_data": ["Passwords", "Email addresses"],
 *                          "is_imported": 1,
 *                          "meta": ["Contains plaintext passwords", "Large-scale breach (1M+ victims)"]
 *                      },
 *                      "risk": "High",
 *                      "risk_value": 2
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */

/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report Darkweb
 * @apiVersion 1.0.0
 * @apiName Darkweb
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query (search on: title, actor, resource_name, url, risk)
 *
 * @apiParamExample {json} Query-Example:
 *      query { darkweb { items(first: 20, skip: 0, sort_by: "risk_value", order: 1, sesrch: "search") { _id snippet resource_name url country language actor title timestamp source risk risk_value target } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "darkweb": {
 *                  items: [{
 *                      "_id": "324bce78-3281-4503-9f05-d1ec15c0626c",
 *                      "snippet": " ... % 608 view 22 Regione Lazio <b>regione.lazio.it</b> 37.50% 75.00% 10 ... ",
 *                      "resource_name": "relateoak2hkvdty6ldp7x67hys7pzaeax3hwhidbqkjzva3223jpxqd.onion",
 *                      "url": "http://relateoak2hkvdty6ldp7x67hys7pzaeax3hwhidbqkjzva3223jpxqd.onion/...",
 *                      "country": "country",
 *                      "language": "en",
 *                      "actor": "actor",
 *                      "title": "title",
 *                      "timestamp": 1627632900,
 *                      "source": "TOR",
 *                      "risk": "High",
 *                      "risk_value": 2
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */

/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report IP
 * @apiVersion 1.0.0
 * @apiName IP
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query (search on: ip, risk)
 *
 * @apiParamExample {json} Query-Example:
 *      query { ip { items(first: 20, skip: 0, sort_by: "risk_score", order: 1, sesrch: "search") { _id ip risk_score highRiskLevelReason risk_level risk malicious_activity { feed { source last_sync } malware { name alias description } last_seen_date } references { date snippet } target } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "ip": {
 *                  items: [{
 *                      "_id": "324bce78-3281-4503-9f05-d1ec15c0626c",
 *                      "ip": "13.80.65.154",
 *                      "risk_score": 25,
 *                      "highRiskLevelReason": "IP is not high-risk",
 *                      "risk_level": 0,
 *                      "malicious_activity": [{
 *                          "feed": { "source": "IP2Location (Proxy)", "last_sync": 1510580872 },
 *                          "malware": { "name": "Data Center or Hosting", "description": null, "alias": ["data center or hosting"] },
 *                          "last_seen_date": 1510223401
 *                      }],
 *                      "references": [{
 *                          "date": "5 months ago",
 *                          "snippet": "DOMAIN( jitsi1.regione.lazio.it ), NAME( @ ), VALUE( <b>13.80.65.154</b> )"
 *                      }],
 *                      "risk": "High"
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */

/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report Subdomains
 * @apiVersion 1.0.0
 * @apiName Subdomains
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query (search on: subdomain, issuer, date)
 *
 * @apiParamExample {json} Query-Example:
 *      query { subdomain { items(first: 20, skip: 0, sort_by: "date", order: 1, sesrch: "search") { _id subdomain issuer date } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "subdomain": {
 *                  items: [{
 *                      "_id": "324bce78-3281-4503-9f05-d1ec15c0626c",
 *                      "subdomain": "subdomain",
 *                      "issuer": "C=BE, O=GlobalSign nv-sa, CN=GlobalSign RSA OV SSL CA 2018",
 *                      "date": "2021-08-30"
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */

/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report Registered Phishing
 * @apiVersion 1.0.0
 * @apiName Registered Phishing
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { registered_phishing { items(first: 20, skip: 0, sort_by: "domain_name", order: 1, sesrch: "search") { _id dns_a dns_aaaa dns_mx dns_ns domain_name whois { creation_date creation_timestamp expiration_date name name_servers registrant registrant_country registrar status } } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "registered_phishing": {
 *                  items: [{
 *                      "_id": "324bce78-3281-4503-9f05-d1ec15c0626c",
 *                      "dns_a": ["20.76.128.247"],
 *                      "dns_aaaa": [],
 *                      "dns_mx": ["mx_domain_name"],
 *                      "dns_ns": ["ns1-03.azure-dns.com", "ns2-03.azure-dns.net", "ns3-03.azure-dns.org", "ns4-03.azure-dns.info"],
 *                      "domain_name": "domain_name",
 *                      "whois": {
 *                          "creation_date": "17/12/1996, 00:00:00",
 *                          "creation_timestamp": 850780800,
 *                          "expiration_date": "09/01/2022, 00:00:00",
 *                          "name": "name",
 *                          "name_servers": ["ns2-03.azure-dns.net", "ns1-03.azure-dns.com", "ns3-03.azure-dns.org", "ns4-03.azure-dns.info"],
 *                          "registrant": "Registrant",
 *                          "registrant_country": "",
 *                          "registrar": "Fastweb s.p.a.",
 *                          "status": "ok"
 *                      }
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */

/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report Unregistered Phishing
 * @apiVersion 1.0.0
 * @apiName Unregistered Phishing
 * @apiGroup Report CTI
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { registered_phishing { items(first: 20, skip: 0, sort_by: "domain_name", order: 1, sesrch: "search") { _id dns_a dns_aaaa dns_mx dns_ns domain_name whois { creation_date creation_timestamp expiration_date name name_servers registrant registrant_country registrar status } } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "registered_phishing": {
 *                  items: [{
 *                      "_id": "324bce78-3281-4503-9f05-d1ec15c0626c",
 *                      "dns_a": [],
 *                      "dns_aaaa": [],
 *                      "dns_mx": [],
 *                      "dns_ns": [],
 *                      "domain_name": "domain_name",
 *                      "whois": null
 *                  }],
 *                  "total": 1
 *             }
 *         }
 *     }
 */

//* Network Scan
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report summary
 * @apiVersion 1.0.0
 * @apiName Summary
 * @apiGroup Report Network Scan
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 * @apiParamExample {json} Query-Example:
 *      query { summary { severity_range { name min max } hosts_count vulns critical high medium low confidentiality { N P C } integrity { N P C } availability { N P C } scan_start scan_end result_count { full filtered debug { full filtered } hole { full filtered } info { full filtered } log { full filtered } warning { full filtered } false_positive { full filtered } } domains target_down vuln_summary { target LOW MEDIUM HIGH } top_3_vuln { top_1 { target LOW MEDIUM HIGH } top_2 { target LOW MEDIUM HIGH } top_3 { target LOW MEDIUM HIGH } } license_info { name api scans_max full_mode binary_edge_ip_score id user_id user_role scans_done expires_on } } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "summary": {
 *                  "severity_range": null,
 *                  "hosts_count": "3",
 *                  "vulns": 11,
 *                  "critical": 0,
 *                  "high": 2,
 *                  "medium": 5,
 *                  "low": 4,
 *                  "confidentiality": {
 *                    "N": null,
 *                    "P": 11,
 *                    "C": null
 *                  },
 *                  "integrity": {
 *                    "N": 7,
 *                    "P": 4,
 *                    "C": null
 *                  },
 *                  "availability": {
 *                    "N": 11,
 *                    "P": null,
 *                    "C": null
 *                  },
 *                  "scan_start": "2021-10-13T16:05:08Z",
 *                  "scan_end": "2021-10-13T16:56:55Z",
 *                  "result_count": {
 *                    "full": "199",
 *                    "filtered": "98",
 *                    "debug": null,
 *                    "hole": {
 *                      "full": "54",
 *                      "filtered": "2"
 *                    },
 *                    "info": {
 *                      "full": "6",
 *                      "filtered": "4"
 *                    },
 *                    "log": {
 *                      "full": "87",
 *                      "filtered": "87"
 *                    },
 *                    "warning": {
 *                      "full": "52",
 *                      "filtered": "5"
 *                    },
 *                    "false_positive": {
 *                      "full": "0",
 *                      "filtered": "0"
 *                    }
 *                  },
 *                  "license_info": {
 *                    "name": "Network Scan Staff - Basic",
 *                    "api": "nscan/1.0",
 *                    "scans_max": 9999.0,
 *                    "full_mode": true,
 *                    "binary_edge_ip_score": null,
 *                    "id": "9517ff6b-976b-4f7e-b2b7-125893f2dba1",
 *                    "user_id": "103514b6-d78a-4194-ac77-0ba16bdd59ad",
 *                    "user_role": null,
 *                    "scans_done": 96.0,
 *                    "expires_on": "2022-06-15T16:56:10Z"
 *                  },
 *                  "domains": ["192.168.10.210", "192.168.10.230", "192.168.10.240"],
 *                  "vuln_summary": [{
 *                    "target": "192.168.10.240",
 *                    "HIGH": 1.0,
 *                    "MEDIUM": 3.0,
 *                    "LOW": 1.0
 *                  }, {
 *                    "target": "192.168.10.210",
 *                    "HIGH": 1.0,
 *                    "MEDIUM": 2.0,
 *                    "LOW": 2.0
 *                  }, {
 *                    "target": "192.168.10.230",
 *                    "HIGH": 0.0,
 *                    "MEDIUM": 0.0,
 *                    "LOW": 1.0
 *                  }],
 *                  "top_3_vuln": {
 *                    "top_1": {
 *                      "target": "192.168.10.240",
 *                      "LOW": 1.0,
 *                      "MEDIUM": 3.0,
 *                      "HIGH": 1.0
 *                    },
 *                    "top_2": {
 *                      "target": "192.168.10.210",
 *                      "LOW": 2.0,
 *                      "MEDIUM": 2.0,
 *                      "HIGH": 1.0
 *                    },
 *                    "top_3": {
 *                      "target": "192.168.10.230",
 *                      "LOW": 1.0,
 *                      "MEDIUM": 0.0,
 *                      "HIGH": 0.0
 *                    }
 *                  },
 *                  "target_down": []
 *              }
 *          }
 *      }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report hosts
 * @apiVersion 1.0.0
 * @apiName Hosts
 * @apiGroup Report Network Scan
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { hosts { items(first: 20, skip: 0) { ip asset { asset_id } start end port_count { page } result_count { page hole { page } warning { page } info { page } log { page } false_positive { page } } detail { name value source { type name description } } open_ports index ports { protocol portid state { state reason reason_ttl } service { name product extrainfo ostype method conf cpe } script { id output table { elem { key } } } } hostnames os { portused { state proto portid } osmatch { name accuracy line osclass { type vendor osfamily accuracy cpe } } } } total  } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "hosts": {
 *                "items": [
 *                  {
 *                    "ip": "192.168.10.240",
 *                    "asset": {
 *                      "asset_id": "d90742f8-6d6f-474f-aebf-badb6532e590"
 *                    },
 *                    "start": "2021-10-13T16:05:27Z",
 *                    "end": "2021-10-13T16:43:50Z",
 *                    "port_count": {
 *                      "page": "5"
 *                    },
 *                    "result_count": {
 *                      "page": "42",
 *                      "hole": {
 *                        "page": "1"
 *                      },
 *                      "warning": {
 *                        "page": "3"
 *                      },
 *                      "info": {
 *                        "page": "1"
 *                      },
 *                      "log": {
 *                        "page": "37"
 *                      },
 *                      "false_positive": {
 *                        "page": "0"
 *                      }
 *                    },
 *                    "detail": [
 *                      {
 *                        "name": "TLS/443",
 *                        "value": "TLSv1.2",
 *                        "source": {
 *                          "type": "nvt",
 *                          "name": "1.3.6.1.4.1.25623.1.0.103823",
 *                          "description": "SSL/TLS: Version Detection Report"
 *                        }
 *                      }
 *                      ...
 *                    ],
 *                    "open_ports": 4,
 *                    "index": 1,
 *                    "ports": [
 *                      {
 *                        "protocol": "tcp",
 *                        "portid": "22",
 *                        "state": {
 *                          "state": "open",
 *                          "reason": "syn-ack",
 *                          "reason_ttl": "63"
 *                        },
 *                        "service": {
 *                          "name": "ssh",
 *                          "product": "OpenSSH",
 *                          "extrainfo": "protocol 2.0",
 *                          "ostype": null,
 *                          "method": "probed",
 *                          "conf": "10",
 *                          "cpe": "cpe:/a:openbsd:openssh:7.4p1"
 *                        },
 *                        "script": null
 *                      },
 *                      ...
 *                    ],
 *                    "hostnames": "{'hostname': {'name': 'ip-192-168-10-240.eu-central-1.compute.internal', 'type': 'PTR'}}",
 *                    "os": {
 *                      "portused": [
 *                        {
 *                          "state": "open",
 *                          "proto": "tcp",
 *                          "portid": "22"
 *                        },
 *                        ...
 *                      ],
 *                      "osmatch": [
 *                        {
 *                          "name": "Linux 3.11 - 4.1",
 *                          "accuracy": "94",
 *                          "line": "63416",
 *                          "osclass": [
 *                            {
 *                              "type": "general purpose",
 *                              "vendor": "Linux",
 *                              "osfamily": "Linux",
 *                              "accuracy": "94",
 *                              "cpe": "cpe:/o:linux:linux_kernel:3"
 *                            }, {
 *                              "type": "general purpose",
 *                              "vendor": "Linux",
 *                              "osfamily": "Linux",
 *                              "accuracy": "94",
 *                              "cpe": "cpe:/o:linux:linux_kernel:4"
 *                            }
 *                          ]
 *                        }
 *                        ...,
 *                      ]
 *                    }
 *                  },
 *                  ...
 *                ],
 *                "total": 3
 *              }
 *            }
 *      }
 */

/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report host vulnerabilities
 * @apiVersion 1.0.0
 * @apiName HostsVulnerabilities
 * @apiGroup Report Network Scan
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { vulnerabilities { items(first: 20, skip: 0) { id name owner { name } modification_time comment creation_time host { asset { asset_id } hostname value } port nvt { oid type name family cvss_base solution { type text } refs { ref { type id link } } } tags { cvss_base_vector summary insight affected impact solution vuldetect solution_type } scan_nvt_version threat severity qod { value type } description original_threat original_severity } total  } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *         "result": {
 *             "vulnerabilities": {
 *                "items": [
 *                  "id": "c18963ab-ace1-4bc4-9df1-c7a2f5e0be52",
 *                  "name": "TCP timestamps",
 *                  "owner": {
 *                    "name": "admin"
 *                  },
 *                  "modification_time": "2021-10-13T16:34:36Z",
 *                  "comment": null,
 *                  "creation_time": "2021-10-13T16:34:36Z",
 *                  "host": {
 *                    "asset": {
 *                      "asset_id": "8d9af1aa-ad32-428c-92d1-ef7574914dab"
 *                    },
 *                    "hostname": "ip-192-168-10-230.eu-central-1.compute.internal",
 *                    "value": "192.168.10.230"
 *                  },
 *                  "port": "general/tcp",
 *                  "nvt": {
 *                    "oid": "1.3.6.1.4.1.25623.1.0.80091",
 *                    "type": "nvt",
 *                    "name": "TCP timestamps",
 *                    "family": "General",
 *                    "cvss_base": "2.6",
 *                    "solution": {
 *                      "type": "Mitigation",
 *                      "text": null
 *                    },
 *                    "refs": {
 *                      "ref": [{
 *                        "type": "url",
 *                        "id": "http://www.ietf.org/rfc/rfc1323.txt"
 *                        "link": "http://www.ietf.org/rfc/rfc1323.txt"
 *                      }, {
 *                        "type": "url",
 *                        "id": "http://www.ietf.org/rfc/rfc7323.txt"
 *                        "link": "http://www.ietf.org/rfc/rfc7323.txt"
 *                      }, {
 *                        "type": "url",
 *                        "id": "https://web.archive.org/web/20151213072445/http://www.microsoft.com/en-us/download/details.aspx?id=9152"
 *                        "link": "https://web.archive.org/web/20151213072445/http://www.microsoft.com/en-us/download/details.aspx?id=9152"
 *                      }]
 *                    }
 *                  },
 *                  "tags": {
 *                    "cvss_base_vector": "AV:N/AC:H/Au:N/C:P/I:N/A:",
 *                    "summary": "The remote host implements TCP timestamps and therefore allows to compute\n  the uptime",
 *                    "insight": "The remote host implements TCP timestamps, as defined by RFC1323/RFC7323",
 *                    "affected": "TCP implementations that implement RFC1323/RFC7323",
 *                    "impact": "A side effect of this feature is that the uptime of the remote\n  host can sometimes be computed",
 *                    "solution": "To disable TCP timestamps on linux add the line 'net.ipv4.tcp_timestamps = 0' to\n  /etc/sysctl.conf. Execute 'sysctl -p' to apply the settings at runtime.\n\n  To disable TCP timestamps on Windows execute 'netsh int tcp set global timestamps=disabled'\n\n  Starting with Windows Server 2008 and Vista, the timestamp can not be completely disabled.\n\n  The default behavior of the TCP/IP stack on this Systems is to not use the\n  Timestamp options when initiating TCP connections, but use them if the TCP peer\n  that is initiating communication includes them in their synchronize (SYN) segment.\n\n  See the references for more information",
 *                    "vuldetect": "Special IP packets are forged and sent with a little delay in between to the\n  target IP. The responses are searched for a timestamps. If found, the timestamps are reported",
 *                    "solution_type": "Mitigatio"
 *                  },
 *                  "scan_nvt_version": "2020-08-24T08:40:10Z",
 *                  "threat": "Low",
 *                  "severity": "2.6",
 *                  "qod": {
 *                    "value": "80",
 *                    "type": null
 *                  },
 *                  "description": "It was detected that the host implements RFC1323/RFC7323.\n\nThe following timestamps were retrieved with a delay of 1 seconds in-between:\nPacket 1: 1813433431\nPacket 2: 1493990546",
 *                  "original_threat": "Low",
 *                  "original_severity": "2.6"
 *                ],
 *                "total": 3
 *              }
 *            }
 *      }
 */

// WEBAPP
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report test ssl summary
 * @apiVersion 1.0.0
 * @apiName TestSSLSummary
 * @apiGroup Report Web App
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { testssl_summary { _id target_host port start_time scan_time vulnerabilities_summary { total critical high medium low info ok none } } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 * {
 *   "result": {
 *     "testssl_summary": {
 *       "_id": "6315a90f18d2abe6b408ce8e",
 *       "target_host": "",
 *       "port": "",
 *       "start_time": 1662363655,
 *       "scan_time": 244,
 *       "vulnerabilities_summary": {
 *         "total": 296,
 *         "critical": 0,
 *         "high": 0,
 *         "medium": 2,
 *         "low": 12,
 *         "info": 186,
 *         "ok": 94,
 *         "none": 0
 *       }
 *     }
 *   }
 * }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report test ssl data
 * @apiVersion 1.0.0
 * @apiName TestSSLData
 * @apiGroup Report Web App
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { testssl_scan_results { items(first: 20, skip: 0, search: "CRITICAL", sort_by: "risk", order: 1) { _id id ip service severity finding cve cwe risk vulnerability_type } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 * {
 *   "result": {
 *     "testssl_scan_results": {
 *       "items": [
 *         {
 *           "_id": "6315a90f18d2abe6b408ceb8",
 *           "ip": "188.114.97.3",
 *           "service": "HTTP",
 *           "id": "cert_commonName",
 *           "severity": "OK",
 *           "finding": "sni.cloudflaressl.com",
 *           "cve": null,
 *           "cwe": null,
 *           "risk": 7,
 *           "vulnerability_type": "serverDefaults"
 *         },
 *         ...
 *       ],
 *       "total": 296
 *     }
 *   }
 * }
 *
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report what web data
 * @apiVersion 1.0.0
 * @apiName WhatWeb
 * @apiGroup Report Web App
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { whatweb { items(first: 20, skip: 0) { _id target http_status request_config plugins } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report WAF data
 * @apiVersion 1.0.0
 * @apiName WAF
 * @apiGroup Report Web App
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { waf { items(first: 20, skip: 0) { _id url detected firewall manufacturer } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 * {
 *   "result": {
 *     "waf": {
 *       "items": [
 *         {
 *           "_id": "6315a90f18d2abe6b408cfb9",
 *           "url": "https://nextcloud.dev-co.it",
 *           "detected": true,
 *           "firewall": "Cloudflare",
 *           "manufacturer": "Cloudflare Inc."
 *         }
 *       ],
 *       "total": 1
 *     }
 *   }
 * }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report WebApp summary
 * @apiVersion 1.0.0
 * @apiName WebAppSummary
 * @apiGroup Report Web App
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { webapp_summary { license_info { name api scans_max full_mode binary_edge_ip_score id user_id user_role scans_done expires_on } alerts_summary { High Medium Low Informational } alerts_summary_percentage { High Medium Low Informational } alerts_summary_grouped { High Medium Low Informational } alerts_summary_grouped_percentage { High Medium Low Informational } impacts_summary { confidentiality integrity availability non_repudiation access_control other } impacts_summary_percentage { confidentiality integrity availability non_repudiation access_control other } likelihood_of_exploit hosts urls alert_threshold attack_strength target start_date end_date } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 * {
 *   "result": {
 *     "webapp_summary": {
 *       "license_info": {
 *         "name": "Web App Scan Staff - Super",
 *         "api": "webapp/1.0",
 *         "scans_max": 9999.0,
 *         "full_mode": true,
 *         "binary_edge_ip_score": null,
 *         "id": "9853e226-70a2-4ed0-9294-08a8c9caed3f",
 *         "user_id": "6b99fbd1-eca0-4c51-b764-77c553c6e268",
 *         "user_role": null,
 *         "scans_done": 10.0,
 *         "expires_on": "2030-06-09T07:05:51Z"
 *       },
 *       "alerts_summary": {
 *         "High": 0,
 *         "Medium": 14,
 *         "Low": 125,
 *         "Informational": 16
 *       },
 *       "alerts_summary_grouped": {
 *         "High": 0,
 *         "Medium": 5,
 *         "Low": 2,
 *         "Informational": 2
 *       },
 *       "impacts_summary": {
 *         "confidentiality": 6,
 *         "integrity": 1,
 *         "availability": 1,
 *         "non_repudiation": 1,
 *         "access_control": 4,
 *         "other": 0
 *       },
 *       "hosts": [
 *         "nextcloud.dev-co.it"
 *       ],
 *       "urls": [
 *         "https://nextcloud.dev-co.it",
 *         ...
 *       ],
 *       "alert_threshold": "DEFAULT (MEDIUM)",
 *       "attack_strength": "DEFAULT (MEDIUM)",
 *       "target": "https://nextcloud.dev-co.it",
 *       "start_date": "2022-09-05 07:46:42.842603",
 *       "end_date": "2022-09-05 07:52:29.987425"
 *     }
 *   }
 * }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report WebApp alerts
 * @apiVersion 1.0.0
 * @apiName WebAppAlerts
 * @apiGroup Report Web App
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { webapp_alerts { items(first: 20, skip: 0, search: "Medium", sort_by: "risk_value", order: 1) { _id sourceid pluginId cweid confidence wascid description tags reference solution alert name alertRef count risk risk_value confidence_value impacts { scopes impacts note } likelihood_of_exploit data { message_id method url evidence evidence_location evidence_snippet attack param other } applicable_platforms { languages { class_name name prevalence } technologies { class_name name prevalence } } cwe_name cwe_type cwe_description is_owasp owasp { id name type } alert_type } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 * {
 *   "result": {
 *     "webapp_alerts": {
 *       "items": [
 *         {
 *           "_id": "6315aaeb8376678b685de6e9",
 *           "sourceid": "3",
 *           "pluginId": "10015",
 *           "cweid": "525",
 *           "confidence": "Medium",
 *           "wascid": "13",
 *           "description": "The cache-control header has not been set properly or is missing, allowing the browser and proxies to cache content. For static assets like css, js, or image files this might be intended, however, the resources should be reviewed to ensure that no sensitive content will be cached.",
 *           "tags": {
 *             "WSTG-v42-ATHN-06": "https://owasp.org/www-project-web-security-testing-guide/v42/4-Web_Application_Security_Testing/04-Authentication_Testing/06-Testing_for_Browser_Cache_Weaknesses"
 *           },
 *           "reference": [
 *             "https://cheatsheetseries.owasp.org/cheatsheets/Session_Management_Cheat_Sheet.html#web-content-caching",
 *             "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control"
 *           ],
 *           "solution": "For secure content, ensure the cache-control HTTP header is set with \"no-cache, no-store, must-revalidate\". If an asset should be cached consider setting the directives \"public, max-age, immutable\".",
 *           "alert": "Re-examine Cache-control Directives",
 *           "name": "Re-examine Cache-control Directives",
 *           "alertRef": "10015",
 *           "count": 2,
 *           "risk": "Informational",
 *           "risk_value": 4,
 *           "confidence_value": 2,
 *           "impacts": [
 *             {
 *               "scopes": [
 *                 "Confidentiality"
 *               ],
 *               "impacts": [
 *                 "Read Application Data"
 *               ],
 *               "note": "Browsers often store information in a client-side cache, which can leave behind sensitive information for other users to find and exploit, such as passwords or credit card numbers. The locations at most risk include public terminals, such as those in libraries and Internet cafes."
 *             }
 *           ],
 *           "likelihood_of_exploit": "",
 *           "data": [
 *             {
 *               "message_id": "10",
 *               "method": "GET",
 *               "url": "https://nextcloud.dev-co.it/robots.txt",
 *               "evidence": "max-age=14400",
 *               "evidence_location": "response_headers",
 *               "evidence_snippet": [
 *                 "... bDomains\r\nCache-Control:",
 *                 "\r\nCF-Cache-Status: REVALI..."
 *               ],
 *               "attack": "",
 *               "param": "Cache-Control",
 *               "other": ""
 *             },
 *             {
 *               "message_id": "44",
 *               "method": "GET",
 *               "url": "https://nextcloud.dev-co.it/index.php/apps/theming/manifest?v=0",
 *               "evidence": "private, max-age=3600, must-revalidate",
 *               "evidence_location": "response_headers",
 *               "evidence_snippet": [
 *                 "... 45 +0000\r\nCache-Control:",
 *                 "\r\npragma: private\r\nconten..."
 *               ],
 *               "attack": "",
 *               "param": "Cache-Control",
 *               "other": ""
 *             }
 *           ],
 *           "applicable_platforms": {
 *             "languages": [],
 *             "technologies": []
 *           },
 *           "cwe_name": "Use of Web Browser Cache Containing Sensitive Information",
 *           "cwe_type": "Weakness",
 *           "cwe_description": "The web application does not use an appropriate caching policy that specifies the extent to which each web page and associated form fields should be cached.",
 *           "is_owasp": true,
 *           "owasp": {
 *             "id": "1348",
 *             "name": "OWASP Top Ten 2021 Category A04:2021 - Insecure Design",
 *             "type": "Category"
 *           },
 *           "alert_type": "Information"
 *         },
 *         ...
 *       ],
 *       "total": 9
 *     }
 *   }
 * }
 */
/**
 * @api {get} https://backend.platform.swascan.com/reports/<TASK_ID>/graphql Get report WebApp messages
 * @apiVersion 1.0.0
 * @apiName WebAppMessages
 * @apiGroup Report Web App
 *
 * @apiSampleRequest https://backend.beta.swascan.com/reports/TASK_ID/graphql
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} query         GraphQL query
 *
 * @apiParamExample {json} Query-Example:
 *      query { webapp_messages { items(first: 20, skip: 0, search: "1", exact: true) { _id id note rtt type timestamp tags request_headers response_headers request_body cookie_params response_body } total } }
 *
 * @apiSuccess {Object} result     Object containing result
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 * {
 *   "result": {
 *     "webapp_messages": {
 *       "items": [
 *         {
 *           "_id": "6315aaef8376678b685de6f1",
 *           "id": "10",
 *           "note": "",
 *           "rtt": "73",
 *           "type": "2",
 *           "timestamp": "1662364004876",
 *           "tags": [],
 *           "request_headers": {
 *             "request_url": "GET https://nextcloud.dev-co.it/robots.txt HTTP/1.1",
 *             "Host": "nextcloud.dev-co.it",
 *             "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:92.0) Gecko/20100101 Firefox/92.0",
 *             "Pragma": "no-cache",
 *             "Cache-Control": "no-cache"
 *           },
 *           "response_headers": {
 *             "status": "HTTP/1.1 200 OK",
 *             "Date": "Mon, 05 Sep 2022 07:46:44 GMT",
 *             "Content-Type": "text/plain; charset=utf-8",
 *             "Content-Length": "26",
 *             "Connection": "keep-alive",
 *             "referrer-policy": "no-referrer",
 *             "x-content-type-options": "nosniff",
 *             "x-frame-options": "SAMEORIGIN",
 *             "x-permitted-cross-domain-policies": "none",
 *             "x-robots-tag": "none",
 *             "x-xss-protection": "1; mode=block",
 *             "last-modified": "Sat, 27 Aug 2022 07:20:35 GMT",
 *             "etag": "\"1a-5e733dddb3451\"",
 *             "strict-transport-security": "max-age=15552000; includeSubDomains",
 *             "Cache-Control": "max-age=14400",
 *             "CF-Cache-Status": "REVALIDATED",
 *             "Accept-Ranges": "bytes",
 *             "Report-To": "{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v3?s=h%2FHQ8emAFfn12HmV7fFr4KkjY1NKpG4W5BAHjwqeeIMhtPKVEXo4gWoYUJc%2BjQRgaVvzntUME5NTetXI4tqFM33JKaXuaUopqzAruFNTNje2740brd2VMkX8Ys25qbwPTE0FYDL4\"}],\"group\":\"cf-nel\",\"max_age\":604800}",
 *             "NEL": "{\"success_fraction\":0,\"report_to\":\"cf-nel\",\"max_age\":604800}",
 *             "Server": "cloudflare",
 *             "CF-RAY": "745d5a56af5b9bdd-FRA",
 *             "alt-svc": "h3=\":443\"; ma=86400, h3-29=\":443\"; ma=86400"
 *           },
 *           "request_body": "",
 *           "cookie_params": "",
 *           "response_body": ""
 *         }
 *       ],
 *       "total": 1
 *     }
 *   }
 * }
 */
