<template>
    <div :class="containerClasses" v-if="value">
        <div class="card p-shadow-7 full-height flex-column"
            :class="cardClasses">
            <div class="p-text-center p-mb-4">
                <strong>
                    {{ $t("general.ransomware_attack_index") }}
                </strong>
            </div>
            <div v-if="!hasLeftText"
                class="card-value flex-centered p-text-center flex1"
                style="padding-left: 1rem; padding-right: 1rem">
                <vue-gauge
                    :refid="'ransomware_attack_index'"
                    :options="chartOptions"
                >
                    {{ `${value}` }}
                </vue-gauge>
            </div>
            <div v-else
                class="p-grid">
                <div class="p-col-12 p-md-5">
                    <p v-html="$t('ransomware_attack_index.description')"></p>
                    <ul>
                        <li><span v-html="$t('ransomware_attack_index.bullet1')"></span></li>
                        <li><span v-html="$t('ransomware_attack_index.bullet2')"></span></li>
                        <li><span v-html="$t('ransomware_attack_index.bullet3')"></span></li>
                    </ul>
                    <p v-html="$t('ransomware_attack_index.footer')"></p>
                </div>
                <div class="p-col-12 p-md-7">
                    <div class="card-value flex-centered p-text-center flex1"
                        style="padding-left: 1rem; padding-right: 1rem">
                        <vue-gauge
                            :refid="'ransomware_attack_index'"
                            :options="chartOptions">
                            {{ `${value}` }}
                        </vue-gauge>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "RansomwareChart",
    props: {
        containerClasses: {
            type: String,
            default: 'p-grid p-ai-stretch vertical-container',
        },
        cardClasses: {
            type: String,
            default: '',
        },
        value: {
            type: Number,
            default: 0
        },
        needleColor: {
            type: String,
            default: '#b2b2b2',
        },
        arcDelimiters: {
            type: Array,
            default: () => [15, 50, 80],
        },
        arcLabels: {
            type: Array,
            default: () => ['15', '50', '80'],
        },
        arcColors: {
            type: Array,
            default: () => []
        },
        hasNeedle: {
            type: Boolean,
            default: true,
        },
        chartWidth: {
            type: Number,
            default: 400,
        },
        hasLeftText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
        };
    },
    taskService: null,
    reportService: null,
    computed: {
        colors() {
            if (this.arcColors.length <= 0) {
                return [
                    this.$appColors.blue,
                    this.$appColors.yellow,
                    this.$appColors.red,
                    this.$appColors.darkRed,
                ]
            }
            return this.arcColors
        },
        chartOptions() {
            return {
                needleValue: this.value,
                needleColor: this.needleColor,
                arcDelimiters: this.arcDelimiters,
                arcLabels: this.arcLabels,
                arcColors: this.colors,
                hasNeedle: this.hasNeedle,
                centralLabel: `${this.value}`,
                chartWidth: this.chartWidth,
            }
        },
    },
    watch: {},
    created() {
    },
    mounted() {
        this.$nextTick(() => {
        });
    },
    methods: {
    },
};
</script>
