import { baseOptions, baseSlide, colors, swascanSite, swascanMail, convertCanvasToImage } from "../constants";

export default (pptx, obj) => {
    var slide = baseSlide(pptx, colors.white, colors.blue);

    slide.addImage({
        path: "assets/images/tinexta_swascan.png",
        x: "4%",
        y: "7%",
        w: 0.6,
        h: 0.9,
        type: "contain",
    });

    slide.addText(obj.target, {
        ...baseOptions.title,
        x: "10%",
        y: "10%",
        w: 6,
        bold: true,
        fontSize: 20,
        color: colors.blue,
    });

    if (obj.panes.CRITICAL_SEVERITY) {
        slide.addText(
            [
                { text: "Total potential vulnerabilities", options: { breakLine: true, fontSize: 9 } },
                { text: obj.panes.TOTAL_POTENTIAL_VULNERABILITIES, options: { fontSize: 16, bold: true } },
            ],
            {
                ...baseOptions.text,
                x: "4%",
                y: "25%",
                w: 2.2,
                h: 0.6,
                color: colors.black,
                line: { pt: "1", color: colors.black },
                margin: 1,
                lineSpacing: 18,
                shape: pptx.ShapeType.roundRect,
                fill: { color: colors.white },
                rectRadius: 0.08,
                align: "right",
            }
        );
        slide.addText(
            [
                { text: "Critical Severity", options: { breakLine: true, fontSize: 9 } },
                { text: obj.panes.CRITICAL_SEVERITY, options: { fontSize: 16, bold: true } },
            ],
            {
                ...baseOptions.text,
                x: "27%",
                y: "25%",
                w: 1.6,
                h: 0.6,
                color: colors.white,
                line: { pt: "1", color: colors.darkRed },
                margin: 1,
                lineSpacing: 18,
                shape: pptx.ShapeType.roundRect,
                fill: { color: colors.darkRed },
                rectRadius: 0.08,
                align: "right",
            }
        );
        slide.addText(
            [
                { text: "High Severity", options: { breakLine: true, fontSize: 9 } },
                { text: obj.panes.HIGH_SEVERITY, options: { fontSize: 16, bold: true } },
            ],
            {
                ...baseOptions.text,
                x: "44%",
                y: "25%",
                w: 1.6,
                h: 0.6,
                color: colors.white,
                line: { pt: "1", color: colors.red },
                margin: 1,
                lineSpacing: 18,
                shape: pptx.ShapeType.roundRect,
                fill: { color: colors.red },
                rectRadius: 0.08,
                align: "right",
            }
        );
        slide.addText(
            [
                { text: "Medium Severity", options: { breakLine: true, fontSize: 9 } },
                { text: obj.panes.MEDIUM_SEVERITY, options: { fontSize: 16, bold: true } },
            ],
            {
                ...baseOptions.text,
                x: "61%",
                y: "25%",
                w: 1.6,
                h: 0.6,
                color: colors.white,
                line: { pt: "1", color: colors.yellow },
                margin: 1,
                lineSpacing: 18,
                shape: pptx.ShapeType.roundRect,
                fill: { color: colors.yellow },
                rectRadius: 0.08,
                align: "right",
            }
        );
        slide.addText(
            [
                { text: "Low Severity", options: { breakLine: true, fontSize: 9 } },
                { text: obj.panes.LOW_SEVERITY, options: { fontSize: 16, bold: true } },
            ],
            {
                ...baseOptions.text,
                x: "78%",
                y: "25%",
                w: 1.6,
                h: 0.6,
                color: colors.white,
                line: { pt: "1", color: colors.blue },
                margin: 1,
                lineSpacing: 18,
                shape: pptx.ShapeType.roundRect,
                fill: { color: colors.blue },
                rectRadius: 0.08,
                align: "right",
            }
        );
    } else {
        slide.addText(
            [
                { text: "Total potential vulnerabilities", options: { breakLine: true, fontSize: 9 } },
                { text: obj.panes.TOTAL_POTENTIAL_VULNERABILITIES, options: { fontSize: 16, bold: true } },
            ],
            {
                ...baseOptions.text,
                x: "4%",
                y: "25%",
                w: 2.2,
                h: 0.6,
                color: colors.black,
                line: { pt: "1", color: colors.black },
                margin: 1,
                lineSpacing: 18,
                shape: pptx.ShapeType.roundRect,
                fill: { color: colors.white },
                rectRadius: 0.08,
                align: "right",
            }
        );
        slide.addText(
            [
                { text: "High Severity", options: { breakLine: true, fontSize: 9 } },
                { text: obj.panes.HIGH_SEVERITY, options: { fontSize: 16, bold: true } },
            ],
            {
                ...baseOptions.text,
                x: "27%",
                y: "25%",
                w: 2.2,
                h: 0.6,
                color: colors.white,
                line: { pt: "1", color: colors.red },
                margin: 1,
                lineSpacing: 18,
                shape: pptx.ShapeType.roundRect,
                fill: { color: colors.red },
                rectRadius: 0.08,
                align: "right",
            }
        );
        slide.addText(
            [
                { text: "Medium Severity", options: { breakLine: true, fontSize: 9 } },
                { text: obj.panes.MEDIUM_SEVERITY, options: { fontSize: 16, bold: true } },
            ],
            {
                ...baseOptions.text,
                x: "50%",
                y: "25%",
                w: 2.2,
                h: 0.6,
                color: colors.white,
                line: { pt: "1", color: colors.yellow },
                margin: 1,
                lineSpacing: 18,
                shape: pptx.ShapeType.roundRect,
                fill: { color: colors.yellow },
                rectRadius: 0.08,
                align: "right",
            }
        );
        slide.addText(
            [
                { text: "Low Severity", options: { breakLine: true, fontSize: 9 } },
                { text: obj.panes.LOW_SEVERITY, options: { fontSize: 16, bold: true } },
            ],
            {
                ...baseOptions.text,
                x: "73%",
                y: "25%",
                w: 2.2,
                h: 0.6,
                color: colors.white,
                line: { pt: "1", color: colors.blue },
                margin: 1,
                lineSpacing: 18,
                shape: pptx.ShapeType.roundRect,
                fill: { color: colors.blue },
                rectRadius: 0.08,
                align: "right",
            }
        );
    }

    slide.addText("Subdomains found", {
        ...baseOptions.text,
        x: "9%",
        y: "36%",
        color: colors.blue,
        bold: true,
    });
    slide.addText(obj.circles.SUBDOMAINS_FOUND, {
        ...baseOptions.text,
        x: "11%",
        y: "41%",
        w: 0.8,
        h: 0.8,
        color: colors.black,
        line: { pt: "2", color: colors.black },
        margin: 1,
        lineSpacing: 18,
        shape: pptx.ShapeType.roundRect,
        fill: { color: colors.white },
        rectRadius: 0.4,
        fontSize: 16,
        bold: true,
        align: "center",
        valign: "middle",
    });


    slide.addText("IP found", {
        ...baseOptions.text,
        x: "34.5%",
        y: "36%",
        color: colors.blue,
        bold: true,
    });
    slide.addText(obj.circles.IP_FOUND, {
        ...baseOptions.text,
        x: "34%",
        y: "41%",
        w: 0.8,
        h: 0.8,
        color: colors.black,
        line: { pt: "2", color: colors.black },
        margin: 1,
        lineSpacing: 18,
        shape: pptx.ShapeType.roundRect,
        fill: { color: colors.white },
        rectRadius: 0.4,
        fontSize: 16,
        bold: true,
        align: "center",
        valign: "middle",
    });

    slide.addText("Compromised Emails", {
        ...baseOptions.text,
        x: "54.5%",
        y: "36%",
        color: colors.blue,
        bold: true,
    });
    slide.addText(obj.circles.COMPROMISED_EMAILS, {
        ...baseOptions.text,
        x: "57.5%",
        y: "41%",
        w: 0.8,
        h: 0.8,
        color: colors.black,
        line: { pt: "2", color: colors.black },
        margin: 1,
        lineSpacing: 18,
        shape: pptx.ShapeType.roundRect,
        fill: { color: colors.white },
        rectRadius: 0.4,
        fontSize: 16,
        bold: true,
        align: "center",
        valign: "middle",
    });

    slide.addText("Compromised Emails Sources", {
        ...baseOptions.text,
        x: "76%",
        y: "36%",
        color: colors.blue,
        bold: true,
    });
    slide.addText(obj.circles.COMPROMISED_EMAILS_SOURCES, {
        ...baseOptions.text,
        x: "81.5%",
        y: "41%",
        w: 0.8,
        h: 0.8,
        color: colors.black,
        line: { pt: "2", color: colors.black },
        margin: 1,
        lineSpacing: 18,
        shape: pptx.ShapeType.roundRect,
        fill: { color: colors.white },
        rectRadius: 0.4,
        fontSize: 16,
        bold: true,
        align: "center",
        valign: "middle",
    });

    // * CHARTS
    slide.addText("Potential vulnerabilities by risk (%)", {
        ...baseOptions.text,
        x: "3%",
        y: "55.5%",
        w: 2.5,
        align: "center",
        color: colors.blue,
        bold: true,
    });
    slide.addImage({
        data: convertCanvasToImage(obj.charts.TECHNOLOGY_RISK).src,
        x: "7%",
        y: "60.5%",
        w: 1.7,
        h: 1.8,
        type: "contain",
    });

    slide.addText("GDPR vulnerabilities by risk (%)", {
        ...baseOptions.text,
        x: "27%",
        y: "55.5%",
        w: 2.2,
        align: "center",
        color: colors.blue,
        bold: true,
    });
    slide.addImage({
        data: convertCanvasToImage(obj.charts.HUMAN_RISK).src,
        x: "30%",
        y: "60.5%",
        w: 1.7,
        h: 1.8,
        type: "contain",
    });

    slide.addText("GDPR Impact", {
        ...baseOptions.text,
        x: "50%",
        y: "55.5%",
        w: 2.2,
        align: "center",
        color: colors.blue,
        bold: true,
    });
    slide.addImage({
        data: convertCanvasToImage(obj.charts.GDPR_RISK).src,
        x: "50%",
        y: "60.5%",
        w: 2.2,
        h: 1.8,
        type: "contain",
    });

    slide.addImage({
        path: "assets/images/tinexta_swascan.png",
        x: "81.5%",
        y: "63%",
        w: 0.9,
        h: 1.35,
        type: "contain",
    });

    slide.addText("swascan.com", swascanSite);
    slide.addText("info@swascan.com", swascanMail);
};