import { baseOptions, baseSlide, colors, swascanSite, swascanMail } from "../constants";

export default (pptx) => {
    var slide = baseSlide(pptx, colors.white, colors.blue);
    
    slide.addImage({
        path: "assets/images/tinexta_swascan.png",
        x: "4%",
        y: "7%",
        w: 0.6,
        h: 0.9,
        type: "contain",
    });

    slide.addImage({
        path: "assets/images/sicurezza_funzionale_e_operativa.png",
        w: "80%",
        h: 4.1,
        type: "contain",
        x: "10%",
        y: "25%",
    });

    slide.addText("Cyber Security Framework:", {
        ...baseOptions.title,
        x: "10%",
        y: "10%",
        fontSize: 20,
        color: colors.blue,
    });
    slide.addText("Sicurezza Funzionale e Operativa", {
        ...baseOptions.title,
        bold: true,
        x: "10%",
        y: "17%",
        fontSize: 20,
        color: colors.blue,
    });

    slide.addText("swascan.com", swascanSite);
    slide.addText("info@swascan.com", swascanMail);
};