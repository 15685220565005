<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-shadow-7 http-404">
                    <h1>404: Not Found</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.http-404 {
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>