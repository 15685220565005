import { ENV_VARS } from "@/env_vars";
import { BaseService } from "./BaseService";
import { Routes } from "./RouterService/Routes";

export class TaskService extends BaseService {
  constructor() {
    super();
    this.BASE_URL = ENV_VARS.VUE_APP_API_BASE_URL;
    this.BASE_API = Routes.TASKS;
  }

  async post(api, body, args) {
    const newArgs = {
      ...args,
      addSlash: true,
      baseUrl: ENV_VARS.VUE_APP_BACKEND_BASE_URL + "/core",
    };
    return super.post(api, body, newArgs);
  }
}

/**
 * @api {get} https://core.platform.swascan.com/core/tasks/ Get Tasks list
 * @apiVersion 1.0.0
 * @apiName TasksList
 * @apiGroup Tasks
 *
 * @apiSampleRequest https://core.beta.swascan.com/core/tasks/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiParam {String} [engine]  Optional api filter (example: engine=dti/1.0)
 * @apiParam {Number} [page]        page
 * @apiParam {Number} [page_size]   Number of records to return
 *
 * @apiSuccess {Number} count       Total targets count.
 * @apiSuccess {Object[]} tasks     Tasks list
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *          "count": 2,
 *          "tasks": [{
 *              "creation_time": "Fri, 23 Apr 2021 06:02:28 GMT",
 *              "engine": "dti/1.0",
 *              "name": "Test DTI",
 *              "percentage": 0.32000000000000006,
 *              "status": "RUNNING",
 *              "task_id": "0e589bb1-f057-4c23-bf98-51761e24885a"
 *           }, {
 *              "creation_time": "Mon, 12 Apr 2021 14:09:51 GMT",
 *              "engine": "dti/1.0",
 *              "name": "Test DTI",
 *              "result": {
 *                  "output_files": "/reports/11c13870-6e4f-4cb2-9a50-331f97415689/45e3968f-bdfc-42ea-8532-afad57883884/full_report.pdf, /reports/11c13870-6e4f-4cb2-9a50-331f97415689/45e3968f-bdfc-42ea-8532-afad57883884/executive_report.pdf",
 *                  "summary": {
 *                      "date": "",
 *                      "executive_pdf_link": "/reports/11c13870-6e4f-4cb2-9a50-331f97415689/45e3968f-bdfc-42ea-8532-afad57883884/executive_report.pdf",
 *                      "full_pdf_link": "/reports/11c13870-6e4f-4cb2-9a50-331f97415689/45e3968f-bdfc-42ea-8532-afad57883884/full_report.pdf",
 *                      "remediation_pdf_link_eng": "/reports/11c13870-6e4f-4cb2-9a50-331f97415689/45e3968f-bdfc-42ea-8532-afad57883884/remediation_report_eng.pdf",
 *                      "remediation_pdf_link_ita": "/reports/11c13870-6e4f-4cb2-9a50-331f97415689/45e3968f-bdfc-42ea-8532-afad57883884/remediation_report_ita.pdf",
 *                      "vulnerabilities_pdf_link": "/reports/11c13870-6e4f-4cb2-9a50-331f97415689/45e3968f-bdfc-42ea-8532-afad57883884/vulnerabilities_report.pdf"
 *                  }
 *              },
 *              "status": "SUCCESS",
 *              "task_id": "45e3968f-bdfc-42ea-8532-afad57883884"
 *           }]
 *     }
 */
/**
 * @api {get} https://core.platform.swascan.com/core/tasks/<TASK_ID>/ Get Task Details
 * @apiVersion 1.0.0
 * @apiName Task
 * @apiGroup Tasks
 *
 * @apiSampleRequest https://core.beta.swascan.com/core/tasks/TASK_ID/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiSuccess {String} creation_time       Task creation time.
 * @apiSuccess {Object} result              Object containing result message
 * @apiSuccess {String} status              Task status
 * @apiSuccess {String} task_id             Task ID
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *    {
 *      "creation_time": "Thu, 14 Oct 2021 14:15:42 GMT",
 *      "result": {
 *        "message": "Task succesfully completed"
 *      },
 *      "status": "SUCCESS",
 *      "task_id": "eb681fbc-f0d6-45e5-a0f0-743862c72c3a"
 *    }
 */
/**
 * @api {post} https://backend.platform.swascan.com/core/tasks/dti/1.0/  Create a new task
 * @apiVersion 1.0.0
 * @apiName CreateTask
 * @apiGroup Tasks
 *
 * @apiSampleRequest https://backend.beta.swascan.com/core/tasks/:api
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 * @apiHeader {String} Content-Type     application/json
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A",
 *          "Content-Type": "application/json"
 *      }
 *
 * @apiParam {String[]} targets          Targets list
 * @apiParam {String} [task_name]        Task name
 * @apiParam {String} target_id          Target id
 * @apiParamExample {json} Body-Example:
 *      {
 *          "": "",
 *          "task_name": "Test DTI",
 *          "target_id": "27236159-5085-4c79-99a2-d1c742da467b"
 *     }
 *
 * @apiSuccess {String} msg              Message
 * @apiSuccess {String} task_id          Target id
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *          "msg": "Job queued",
 *          "task_id": "0e589bb1-f057-4c23-bf98-51761e24885a"
 *     }
 */
/**
 * @api {delete} https://core.beta.swascan.com/core/tasks/?target_id=<TARGET_ID> Delete all tasks related to target
 * @apiVersion 1.0.0
 * @apiName DeleteTasks
 * @apiGroup Tasks
 *
 * @apiSampleRequest https://core.beta.swascan.com/core/tasks/?target_id=e491205c-fd6b-4c8e-84a8-4918e7b8fec9
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiSuccess {String} status        Status message
 * @apiSuccess {String[]} tasks_ids   Related tasks ids
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *      {
 *          "status": "Tasks deleted",
 *          "tasks_ids": ["2fcbf6eb-8f32-4cee-8066-56ef17b38f87"]
 *     }
 */
/**
 * @api {delete} https://core.beta.swascan.com/core/tasks/<TASK_ID>/ Delete task
 * @apiVersion 1.0.0
 * @apiName DeleteTask
 * @apiGroup Tasks
 *
 * @apiSampleRequest https://core.beta.swascan.com/core/tasks/5b4b20e3-7dce-4e15-af8f-954cbe2bd5f4/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 */
/**
 * @api {delete} https://core.beta.swascan.com/core/tasks/stop/<TASK_ID>/ Stop specific scan
 * @apiVersion 1.0.0
 * @apiName ReportsStopScan
 * @apiGroup Tasks
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 *
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 *
 * @apiSampleRequest https://core.beta.swascan.com/core/tasks/stop/5b4b20e3-7dce-4e15-af8f-954cbe2bd5f4/
 *
 */
