import { BaseService } from "./BaseService";
import { Routes } from "./RouterService/Routes";
import { ENV_VARS } from "@/env_vars";

export class LicenseService extends BaseService {
  constructor() {
    super();
    this.BASE_URL = ENV_VARS.VUE_APP_LICENSE_BASE_URL;
    this.BASE_API = Routes.LICENSES;
  }
}

/**
 * @api {get} https://licensing.beta.swascan.com/licensing/licenses/  Get user licenses
 * @apiVersion 1.0.0
 * @apiName licensesList
 * @apiGroup Licenses
 *
 * @apiSampleRequest https://licensing.beta.swascan.com/licensing/licenses/
 *
 * @apiHeader {String} Authorization   Bearer <AUTH_TOKEN>
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxMTg3ODQsImlhdCI6MTYxOTExODQ4NCwiYXV0aF90aW1lIjoxNjE5MTE3OTk2LCJqdGkiOiIzZTQ4NzI2Zi1hZDUwLTRkNzEtYjY1My00YjcxNjRhMjc1NzIiLCJpc3MiOiJodHRwOi8vYmV0YS5zd2FzY2FuLmNvbS9hdXRoL3JlYWxtcy9zd2EiLCJzdWIiOiIxMWMxMzg3MC02ZTRmLTRjYjItOWE1MC0zMzFmOTc0MTU2ODkiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJmcm9udGVuZCIsIm5vbmNlIjoiMDUwNjQ5M2YtZGNkOS00OTAzLTk2NjQtMzdlYWI2MDExNjQwIiwic2Vzc2lvbl9zdGF0ZSI6IjY3YzcyNDg2LTExNTktNGQzNC1hODA2LWNmZmY3OTg1NTUxOCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJzdGFmZiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTHVjYSBEJ0lzYW50byIsInByZWZlcnJlZF91c2VybmFtZSI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSIsImdpdmVuX25hbWUiOiJMdWNhIiwiZmFtaWx5X25hbWUiOiJEJ0lzYW50byIsImVtYWlsIjoibC5kaXNhbnRvQHN3YXNjYW4uY29tIn0.AcKasGIc4mEnry-G3bNHIaV7j8hyfrwfFqZkjEqO6JKiE__nZC9ptPFFP9yiq9GFDbjf3mD_iwtuF7C2EzSvLMaTyYe8XpPdZ1gizj8l5dXsjXDfo6RfnKuJsYQsJNb97SVIXsu-YGkRxKQc2fOZJ_21eNdCJ_1BRUrT3kOBtE2bk4WhdpZ5N4y_rBEeFD96XnQlSbaoueI-SvavMmxBBu-w7zAd8VPOnzG7QgvZl_jRB8KcMpDH7VJ34OZx7dHHsknCv7kf4ThFDTBDqICaEHGoGLRxYcMTKZkFD6iVkHqyRqYJE4JJRy5SSSmDKW6Y-wj8Uo_U_1FZPDEgafOv4A"
 *      }
 *
 * @apiSuccess {String} name                    License name
 * @apiSuccess {String} api                     API
 * @apiSuccess {Number} scans_max               Max scan
 * @apiSuccess {Boolean} full_mode              Scan is full mode visualization or not
 * @apiSuccess {Boolean} binary_edge_ip_score
 * @apiSuccess {String} id                      License id
 * @apiSuccess {String} user_id                 User id
 * @apiSuccess {String} user_role               User role
 * @apiSuccess {Number} scans_done              Scan done
 * @apiSuccess {String} expires_on              License expiration date
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     [{
 *          "name": "DTI Staff",
 *          "api": "dti/1.0",
 *          "scans_max": 999,
 *          "full_mode": true,
 *          "binary_edge_ip_score": false,
 *          "id": "bc2da29a-9222-45ce-afa2-28c66bcd4182",
 *          "user_id": "11c13870-6e4f-4cb2-9a50-331f97415689",
 *          "user_role": null,
 *          "scans_done": 14,
 *          "expires_on": "2022-03-16T11:09:21Z"
 *     }]
 */
