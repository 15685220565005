export class CVSSBaseVectorParser {
    values = {};

    AV = "AV";
    AC = "AC";
    Au = "Au";
    C = "C";
    I = "I";
    A = "A";

    descriptions = {
        AV: "pages.report.access_vector",
        AC: "pages.report.access_complexity",
        Au: "pages.report.authentication",
        C: "general.confidentiality",
        I: "general.integrity",
        A: "general.availability",
    };

    valueDescriptions = {
        AV: {
            L: "pages.report.local",
            A: "pages.report.adjacent_network",
            N: "pages.report.network",
        },
        AC: {
            H: "general.high",
            M: "general.medium",
            L: "general.low",
        },
        Au: {
            M: "pages.report.multiple",
            S: "pages.report.signle",
            N: "pages.report.none",
        },
        C: {
            C: "general.high",
            P: "general.medium",
            N: "general.low",
        },
        I: {
            C: "general.high",
            P: "general.medium",
            N: "general.low",
        },
        A: {
            C: "general.high",
            P: "general.medium",
            N: "general.low",
        },
    };

    constructor(value) {
        if (value) {
            const list = value.split("/");
            for (const item of list) {
                const parse = item.split(":");
                this.values[parse[0]] = parse[1];
            }
        }
    }

    getDescription(key) {
        if (!this.descriptions[key]) {
            return key;
        }
        return this.descriptions[key];
    }

    getValueDescription(key, value) {
        if (!value) {
            return "general.low";
        }
        if (!this.valueDescriptions[key]) {
            return value;
        }
        return this.valueDescriptions[key][value];
    }

    getImpacts() {
        const impacts = [];
        if (this.values.C !== "N") {
            impacts.push(this.descriptions.C);
        }
        if (this.values.I !== "N") {
            impacts.push(this.descriptions.I);
        }
        if (this.values.A !== "N") {
            impacts.push(this.descriptions.A);
        }

        return impacts;
    }
}
