<template>
    <div class="p-grid">
        <div class="p-col-12">
            <p v-html="$t('pages.new_target.open_vpn_description')"></p>
            <ul>
                <li>{{ $t('pages.new_target.virtual_appliance') }}</li>
                <li>{{ $t('pages.new_target.windows_vmware_player') }}</li>
                <li>{{ $t('pages.new_target.open_von_confg_files') }}</li>
            </ul>
        </div>
        <div class="p-col-12 p-mb-3">
            <p>{{ $t('pages.new_target.open_vpn_config_file') }}</p>
            <DevcoUpload
                class="p-mb-2"
                ref="configFile"
                :accept="'.ovpn'"
                :maxFileSize="1000000"
                :fileLimit="1"
                :chooseLabel="$t('general.choose_file')"
                :cancelLabel="$t('general.delete_file')"
                :showUploadButton="false"
                :showCancelButton="false"
                @change="configFileUploaded"
                @remove="configFileRemoved"
            />
            <small class="p-text-italic">{{ $t('pages.new_target.only_ovpn_file') }}</small>
        </div>
        <div class="p-col-12">
            <p>{{ $t('pages.new_target.open_vpn_credentials_file') }}</p>
            <DevcoUpload
                class="p-mb-2"
                ref="credentialsFile"
                :accept="'.txt'"
                :maxFileSize="100000"
                :fileLimit="1"
                :chooseLabel="$t('general.choose_file')"
                :cancelLabel="$t('general.delete_file')"
                :showUploadButton="false"
                :showCancelButton="false"
                @change="credentialsFileUploaded"
                @remove="credentialsFileRemoved"
            />
            <small class="p-text-italic">{{ $t('pages.new_target.only_txt_file') }}</small>
        </div>
    </div>
</template>

<script>

export default {
    emits: [
        'uploadedConfigFile',
        'removedConfigFile',
        'uploadedCredentialsFile',
        'removedCredentialsFile'
    ],
    data() {
        return {
            configFile: null,
            credentialsFile: null
        }
    },
    methods: {
        configFileUploaded() {
            if (this.$refs.configFile && this.$refs.configFile.files) {
                try {
                    let file = this.$refs.configFile.files[0];

                    let extension = file.name.split('.').pop();

                    if (extension != 'ovpn') {
                        this.$refs.configFile.files = [];
                        return;
                    }

                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.configFile = reader.result.split('base64,') && reader.result.split('base64,')[1];
                        this.$emit('uploadedConfigFile', this.configFile);
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        },
        configFileRemoved() {
            this.configFile = null;
            this.$emit('removedConfigFile');
        },
        credentialsFileUploaded() {
            if (this.$refs.credentialsFile && this.$refs.credentialsFile.files) {
                try {
                    let file = this.$refs.credentialsFile.files[0];

                    let extension = file.name.split('.').pop();

                    if (extension != 'txt') {
                        this.$refs.credentialsFile.files = [];
                        return;
                    }

                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.credentialsFile = reader.result.split('base64,') && reader.result.split('base64,')[1];
                        this.$emit('uploadedCredentialsFile', this.credentialsFile);
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        },
        credentialsFileRemoved() {
            this.credentialsFile = null;
            this.$emit('removedCredentialsFile');
        },
    }
}
</script>