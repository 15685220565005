<template>
    <DataTable
        id="vulnerabilities"
        class="p-datatable-sm p-datatable-responsive"
        :lazy="true"
        :paginator="true"
        :rowHover="true"
        :loading="loading"
        :rows="page_size"
        :sortField="sortField"
        :sortOrder="sortOrder"
        :totalRecords="vulnerabilities.count"
        :value="vulnerabilities.records"
        expandedRowIcon="pi pi-search-minus"
        collapsedRowIcon="pi pi-search-plus"
        @page="onPageChange($event)"
        @sort="onPageChange($event)"
        v-model:expandedRows="expandedRows"
        @row-expand="onRowExpand"
    >
        <template #header>
            <div>
                <div class="table-header p-mb-3">
                    <div class="swa-header">
                        <h3>
                            {{ $t("general.vulnerabilities") }}
                        </h3>
                    </div>
                </div>
            </div>

            <div class="p-grid p-ai-stretch vertical-container">
                <!-- TOTAL VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            white
                            p-shadow-24
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.vulnerabilities") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ vulnerabilitiesSummary.total }}
                        </div>
                    </div>
                </div>
                <!-- TOTAL VULNERABILITIES -->

                <!-- HIGH VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            danger
                            p-shadow-7
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.high_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ vulnerabilitiesSummary.HIGH }}
                        </div>
                    </div>
                </div>
                <!-- HIGH VULNERABILITIES -->

                <!-- MEDIUM VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            warning
                            p-shadow-7
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.medium_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ vulnerabilitiesSummary.MEDIUM }}
                        </div>
                    </div>
                </div>
                <!-- MEDIUM VULNERABILITIES -->

                <!-- LOW VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            primary
                            p-shadow-7
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.low_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ vulnerabilitiesSummary.LOW }}
                        </div>
                    </div>
                </div>
                <!-- LOW VULNERABILITIES -->
            </div>
        </template>

        <Column
            field="host.hostname"
            :header="$t('pages.report.hostname')"
            headerClass="inner-head"
        />
        <Column
            field="threat"
            :header="$t('general.severity')"
            headerClass="inner-head column-max120"
            bodyClass="column-max120"
        >
            <template #body="slotProps">
                <div>
                    <Tag
                        :severity="getSeverity(slotProps.data.threat)"
                        :value="
                            $t('general.' + slotProps.data.threat.toLowerCase())
                        "
                    />
                </div>
            </template>
        </Column>
        <Column
            field="severity"
            :header="$t('pages.report.cvss')"
            headerClass="inner-head column-max120"
            bodyClass="column-max120"
            :sortable="true"
        />
        <Column
            field="impacts"
            :header="$t('pages.report.impact')"
            headerClass="inner-head"
        >
            <template #body="slotProps">
                <div>
                    <div v-for="impact in slotProps.data.impacts" :key="impact">
                        {{ $t(impact) }}
                    </div>
                </div>
            </template>
        </Column>
        <Column
            field="name"
            :header="$t('general.name')"
            headerClass="inner-head"
        />
        <Column
            field="port"
            :header="$t('general.port')"
            headerClass="inner-head column-max120"
            bodyClass="column-max120"
        />
        <Column
            :expander="true"
            :header="$tc('general.detail', 1)"
            headerClass="inner-head column-max120"
            bodyClass="column-max120"
        />

        <template #expansion="VulnSlotProps">
            <div>
                <div class="p-mb-2">
                    <div class="title">
                        {{ $t("pages.report.description") }}:
                    </div>
                    {{ VulnSlotProps.data.description }}
                </div>
                <div class="p-mb-2">
                    <div class="title">{{ $t("pages.report.family") }}:</div>
                    {{ VulnSlotProps.data.nvt.family }}
                </div>
                <div class="p-mb-2">
                    <div class="title">{{ $t("pages.report.summary") }}:</div>
                    {{ VulnSlotProps.data.tags.summary }}
                </div>
                <div
                    class="p-mb-2"
                    v-for="item in VulnSlotProps.data.cvss_data"
                    :key="item.key"
                >
                    <div v-if="translateExists(item.value)">
                        <div class="title">
                            {{ translateWithoutSuffix(item.key) }}:
                        </div>
                        {{ translateWithoutSuffix(item.value) }}
                    </div>
                </div>
                <div class="p-mb-2">
                    <div class="title">{{ $t("pages.report.solution") }}:</div>
                    {{ VulnSlotProps.data.tags.solution }}
                </div>
                <div
                    class="p-mb-2"
                    v-if="
                        VulnSlotProps.data.nvt &&
                        VulnSlotProps.data.nvt.refs &&
                        VulnSlotProps.data.nvt.refs.ref &&
                        VulnSlotProps.data.nvt.refs.ref.length > 0
                    "
                >
                    <div class="title">
                        {{ $t("pages.report.references") }}:
                    </div>
                    <ul>
                        <li
                            v-for="ref in VulnSlotProps.data.nvt.refs.ref"
                            :key="ref.id"
                        >
                            <a
                                v-if="
                                    (ref.link && ref.link.length > 0) ||
                                    ref.type === 'url'
                                "
                                :href="ref.link || ref.url"
                                >{{ ref.id }}</a
                            >
                            <span
                                v-if="
                                    (!ref.link || ref.link.length === 0) &&
                                    !ref.type === 'url'
                                "
                                >{{ ref.id }}</span
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </DataTable>
</template>

<script>
import { translateMethod } from "../../../commons";

export default {
    name: "NScanVulnerabilitiesTable",
    props: {
        loading: {
            type: Boolean,
            default: () => true,
        },
        isVisible: {
            type: Boolean,
            default: () => true,
        },
        vulnerabilities: {
            type: Object,
            default: () => {
                return {
                    records: [],
                    count: 0,
                };
            },
        },
        vulnerabilitiesSummary: {
            type: Object,
            default: () => {
                return {};
            },
        },
        ip: {
            type: String,
            default: () => "",
        },
    },
    data() {
        return {
            expandedRows: [],
            page_size: 10,
            sortField: "severity",
            sortOrder: -1,
            page: 0,
        };
    },
    emits: ["onPageChange"],
    methods: {
        getSeverity(severity) {
            switch (severity) {
                case "High":
                    return "danger";
                case "Medium":
                    return "warning";
                case "Low":
                    return "primary";
                default:
                    return "primary";
            }
        },
        onPageChange(event) {
            this.page = event.page !== undefined ? event.page : this.page;
            this.sortField = event.sortField || this.sortField;
            this.sortOrder = event.sortOrder || this.sortOrder;

            this.$emit("onPageChange", {
                ip: this.ip,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
                page: this.page,
                page_size: this.page_size,
            });
        },
        onRowExpand(event) {
            this.expandedRows = [event.data];
        },
        ...translateMethod,
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-weight: bold;
}
</style>