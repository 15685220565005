<template>
    <div>
        <div class="p-col-12">
            <h3>
                {{
                    $t("pages.new_target.vpn_settings", {
                        suggestion: suggestion,
                    })
                }}
            </h3>
            <p>{{ $t("pages.new_target.vpn_description") }}</p>
            <p v-if="hasVpn" style="color: var(--red-400)" v-html="$t('pages.new_target.has_vpn')"></p>
        </div>
        <div class="p-col-12">
            <Dropdown
                class="p-mt-3 full-width"
                optionLabel="name"
                optionValue="value"
                v-model="vpnType"
                :options="vpnTypes"
                :placeholder="vpnSelectLabel"
                @change="resetExtra"
            />
        </div>
        <!-- se pubblico, nascondere tutto sotto, altrimenti deve essere presente. nel caso in cui viene mandato con un privato, allora dare alert se vpn è vuoto -->
        <div class="p-col-12">
            <OpenVpnSettings
                v-if="isOpenVpn"
                @uploadedConfigFile="onConfigFileUploaded"
                @removedConfigFile="onConfigFileRemoved"
                @uploadedCredentialsFile="
                    onCredentialsFileUploaded
                "
                @removedCredentialsFile="
                    onCredentialsFileRemoved
                "
            />
            <NetExtenderSettings v-if="isNetExtender" />
        </div>
    </div>
</template>

<script>
import OpenVpnSettings from "./OpenVpnSettings.vue";
import NetExtenderSettings from "./NetExtenderSettings.vue";
import { TargetExtra } from "@/models";

export default {
    components: {
        OpenVpnSettings,
        NetExtenderSettings,
    },
    props: {
        extra: Object,
        target_id: String,
        suggestion: String,
        hasChanged: Boolean,
        hasVpn: Boolean,
    },
    data () {
        return {
            vpnType: null,
        }
    },
    computed: {
        isOpenVpn() {
            return this.vpnType == "open_vpn";
        },
        isNetExtender() {
            return this.vpnType == "net_extender";
        },
        vpnTypes () {
            if (this.target_id) {
                return [
                    { name: this.$t("pages.new_target.unchanged"), value: null },
                    { name: this.$t("pages.new_target.open_vpn"), value: "open_vpn" },
                    {
                        name: this.$t("pages.new_target.net_extender"),
                        value: "net_extender",
                    },
                ]
            }
            return [
                { name: this.$t("pages.new_target.no_auth"), value: null },
                { name: this.$t("pages.new_target.open_vpn"), value: "open_vpn" },
                {
                    name: this.$t("pages.new_target.net_extender"),
                    value: "net_extender",
                },
            ]
        },
        vpnSelectLabel() {
            if (this.target_id) {
                return this.$t("pages.new_target.unchanged")
            }
            return this.$t("pages.new_target.no_auth")
        },
        hasVpnInfos() {
            if (!this.extra) return false;
            return (
                !this.isEmpty(this.extra.user) &&
                !this.isEmpty(this.extra.password) &&
                !this.isEmpty(this.extra.address) &&
                !this.isEmpty(this.extra.domain)
            );
        },
    },
    watch: {
    },
    created () {
    },
    methods: {
        isEmpty(str) {
            return !str || str.length === 0;
        },
        onConfigFileUploaded(file) {
            this.$emit('input', 'config_file', file)
        },
        onConfigFileRemoved() {
            this.$emit('input', 'config_file', null)
        },
        onCredentialsFileUploaded(file) {
            this.$emit('input', 'credentials_file', file)
        },
        onCredentialsFileRemoved() {
            this.$emit('input', 'credentials_file', null)
        },
        resetExtra() {
            this.$emit('reset', new TargetExtra())
        },
    }
}
</script>
