export const BaseNewTargetRoute = (engine, base_route = "targets") => {
    return {
        label: "Target Info",
        to: `/${base_route}/${engine}/baseInfo`,
    };
};

export class IMPACT_TYPE {
    static NONE = "NONE";
    static PARTIAL = "PARTIAL";
    static COMPLETE = "COMPLETE";
    static LOW = "LOW";
    static HIGH = "HIGH";
}
