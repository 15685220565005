import { DateParser, ENGINE_DESCRIPTIONS, parseEngineByUrl } from "../utilities";
export class Task {
  task_id;
  name;
  status;
  percentage;
  traceback;
  result;
  creation_time;
  engine;
  stop_requested;

  constructor(data = {}) {
    this.task_id = data.task_id;
    this.name = data.name;
    this.status = data.status;
    this.percentage = Math.round(data.percentage * 100);
    this.traceback = data.traceback;
    this.creation_time = data.creation_time;
    this.engine = data.engine;
    if (!data.result) {
      data.result = {
        message: { output: "" },
      };
    }
    this.result = data.result;
    this.stop_requested = data.stop_requested || false;
  }

  get engine_abbreviation() {
    const array = parseEngineByUrl(this.engine);
    return array[1];
  }

  get engine_description() {
    const array = parseEngineByUrl(this.engine);
    return ENGINE_DESCRIPTIONS[array[1]];
  }

  static get STATUSES() {
    return {
      SUCCESS: "SUCCESS",
      FAILURE: "FAILURE",
      RUNNING: "RUNNING",
      PENDING: "PENDING",
      DELETED: "DELETED",
      STOPPED: "STOPPED",
      COMPILING: "COMPILING",
      COMPLETED: "COMPLETED"
    };
  }

  static get ALL() {
    return ["SUCCESS", "FAILURE", "RUNNING", "PENDING", "DELETED", "STOPPED", "COMPILING", "COMPLETED"];
  }

  static get RUNNING() {
    return ["RUNNING", "PENDING"];
  }

  static get COMPLETED() {
    return ["SUCCESS", "FAILURE", "DELETED", "STOPPED", "COMPLETED"];
  }

  static get STOPPABLE() {
    return ["RUNNING"];
  }

  get creation_date() {
    if (!this.creation_time) return "";

    return DateParser.parseDate(this.creation_time, "DD.MM.YYYY HH:mm");
  }

  toJSON() {
    return {
      task_id: this.task_id,
      name: this.name,
      status: this.status,
      percentage: this.percentage,
      traceback: this.traceback,
      result: this.result,
      creation_time: this.creation_time,
      engine: this.engine,
      stop_requested: this.stop_requested,
    };
  }
}
