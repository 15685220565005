import { baseOptions, baseSlide, colors, swascanSite, swascanMail } from "../constants";

export default (pptx) => {
    var slide = baseSlide(pptx, colors.white, colors.blue);
    slide.addImage({
        path: "assets/images/tinexta_swascan.png",
        x: "4%",
        y: "7%",
        w: 0.6,
        h: 0.9,
        type: "contain",
    });

    slide.addText("Domain Threat Intelligence", {
        ...baseOptions.title,
        x: "10%",
        y: "10%",
        bold: true,
        fontSize: 20,
        color: colors.blue,
    });

    slide.addText(
        [
            {
                text: "Il Domain Threat Intelligence",
                options: { bold: true, breakLine: false },
            },
            {
                text:
                    " ha lo scopo e l'obiettivo di individuare le eventuali informazioni pubblice disponibili a livello di OSINT e CLOSINT relative ad un determinato target.\n\nL'attività di Threat Intelligence viene effettuata attraverso un processo di ricerca, individuazione e selezione delle informazioni disponibili pubblicamente relative al dominio di interesse.",
            },
        ],
        {
            x: "10%",
            y: "24%",
            w: 3.5,
            h: 2,
            valign: "top",
            color: colors.blue,
            fontSize: 9,
            fontFace: "Segoe UI",
        }
    );
    slide.addImage({
        path: "assets/images/dti.png",
        w: 3.5,
        h: 1.96,
        type: "contain",
        x: "10%",
        y: "55%",
    });

    slide.addImage({
        path: "assets/images/line_point.png",
        w: 0.7,
        h: 0.1,
        type: "contain",
        x: "50%",
        y: "25%",
    });
    slide.addText("Threat Intelligence", {
        ...baseOptions.text,
        x: "58%",
        y: "23.5%",
        bold: true,
        color: colors.red,
        fontSize: 12,
    });
    slide.addText(
        "L'attività di Domain Threat Intelligence viene effettuata su target e identificavi digitali relativi agli Asset e alle email compromesse. L'attività è condotta attraverso la ricerca, individuazione e selezione delle informazioni disponibili pubblicamente relativa a Dominio, sottodominio ed email compromesse.",
        {
            ...baseOptions.text,
            x: "58%",
            y: "28%",
            w: 3.5,
            color: colors.blue,
            fontSize: 8,
            lineSpacing: 11,
        }
    );

    slide.addImage({
        path: "assets/images/line_point.png",
        w: 0.7,
        h: 0.1,
        type: "contain",
        x: "50%",
        y: "46%",
    });
    slide.addText("Osint & Closint", {
        ...baseOptions.text,
        x: "58%",
        y: "44.5%",
        bold: true,
        color: colors.red,
        fontSize: 12,
    });
    slide.addText(
        [
            {
                text:
                    "Il servizio non effettua alcun test di sicurezza sul target, opera unicamente sulle informazioni raccolte a livello OSINT e CLOSINT e disponibili sul Dark Web.\n\n",
                options: { breakLine: false },
            },
            {
                text: "OSINT:",
                options: { breakLine: false, bold: true },
            },
            {
                text:
                    " Acronimo di Open Source Intelligence, si fa riferimento al processo di raccolta d'informazioni attraverso la consultazione di fonti di pubblico dominio definite anche «fonti aperte» impatti.\n\n",
                options: { breakLine: false },
            },
            {
                text: "CLOSINT:",
                options: { breakLine: false, bold: true },
            },
            {
                text:
                    " Close Source Intelligence, processo di raccolta d'informazioni attraverso consultazione di «fonti chiuse», non accessibili al pubblico o aree «riservate».",
                options: { breakLine: false },
            },
        ],
        {
            x: "58%",
            y: "49%",
            w: 3.5,
            h: 3,
            valign: "top",
            color: colors.blue,
            fontSize: 8,
            lineSpacing: 11,
        }
    );

    slide.addImage({
        path: "assets/images/line_point.png",
        w: 0.7,
        h: 0.1,
        type: "contain",
        x: "50%",
        y: "84%",
    });
    slide.addText("Reporting", {
        ...baseOptions.text,
        x: "58%",
        y: "82.5%",
        bold: true,
        color: colors.red,
        fontSize: 12,
    });
    slide.addText("Reportistica dettagliata delle attività in formato PDF", {
        ...baseOptions.text,
        x: "58%",
        y: "87%",
        w: 3.5,
        color: colors.blue,
        fontSize: 8,
        lineSpacing: 11,
    });

    slide.addText("swascan.com", swascanSite);
    slide.addText("info@swascan.com", swascanMail);
};