<template>
    <div class="full-width">
        <!-- Summary -->
        <div v-if="loading" class="p-col-12">
            <div class="card p-shadow-7">
                <AppLoader :shortLines="[1, 2]" :longLines="[1, 2, 3, 4]" />
            </div>
        </div>
        <div v-if="!loading" class="p-col-12">
            <!-- RANSOMWARE ATTACK INDEX CHART -->
            <RansomwareChart
                v-if="ransomware"
                :cardClasses="'p-col-12'"
                :value="summary.ransomware_attack_index"
                :hasLeftText="true" />

            <!-- RANSOMWARE ATTACK INDEX CHART -->
            <div class="p-grid p-ai-stretch vertical-container">
                <!-- TOTAL VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            white
                            p-shadow-24
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{
                                $t("general.total_potential_vulnerabilities")
                            }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ summary.cve_total }}
                        </div>
                    </div>
                </div>
                <!-- TOTAL VULNERABILITIES -->

                <!-- HIGH VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            danger
                            p-shadow-7
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.high_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ summary.cve_high_total }}
                        </div>
                    </div>
                </div>
                <!-- HIGH VULNERABILITIES -->

                <!-- MEDIUM VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            warning
                            p-shadow-7
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.medium_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ summary.cve_medium_total }}
                        </div>
                    </div>
                </div>
                <!-- MEDIUM VULNERABILITIES -->

                <!-- LOW VULNERABILITIES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div
                        class="
                            card
                            primary
                            p-shadow-7
                            card-rounded
                            box-stretched
                        "
                    >
                        <div class="p-text-right">
                            <strong>{{ $t("general.low_severity") }}</strong>
                        </div>
                        <div class="card-value p-text-right">
                            {{ summary.cve_low_total }}
                        </div>
                    </div>
                </div>
                <!-- LOW VULNERABILITIES -->
            </div>
        </div>

        <div v-if="!loading" class="p-col-12">
            <div class="p-grid">
                <!-- TOTAL IP -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div class="card p-shadow-7 full-height flex-column">
                        <div class="p-text-center p-mb-4" style="height: 35px">
                            <strong>{{
                                $t("pages.report.total_ip_found")
                            }}</strong>
                        </div>
                        <div
                            class="card-value flex-centered p-text-center flex1"
                        >
                            <RounderContainer :value="summary.total_ip_found" />
                        </div>
                    </div>
                </div>
                <!-- TOTAL IP -->

                <!-- TOTAL SUBDOMAINS -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div class="card p-shadow-7 full-height flex-column">
                        <div class="p-text-center p-mb-4" style="height: 35px">
                            <strong>{{
                                $t("pages.report.total_subdomains_found")
                            }}</strong>
                        </div>
                        <div
                            class="card-value flex-centered p-text-center flex1"
                        >
                            <RounderContainer
                                :value="summary.total_subdomain_found"
                            />
                        </div>
                    </div>
                </div>
                <!-- TOTAL SUBDOMAINS -->

                <!-- BREACHES -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div class="card p-shadow-7 full-height flex-column">
                        <div class="p-text-center p-mb-4" style="height: 35px">
                            <strong>{{ $t("general.total_breach") }}</strong>
                        </div>
                        <div
                            class="card-value flex-centered p-text-center flex1"
                        >
                            <RounderContainer :value="(summary.breach_total)" />
                        </div>
                    </div>
                </div>
                <!-- BREACHES -->

                <!-- SOURCE OF BREACH -->
                <div class="p-col-12 p-xl-3 p-md-6">
                    <div class="card p-shadow-7 full-height flex-column">
                        <div class="p-text-center p-mb-4" style="height: 35px">
                            <strong>{{
                                $t("general.total_breach_source")
                            }}</strong>
                        </div>
                        <div
                            class="card-value flex-centered p-text-center flex1"
                        >
                            <RounderContainer
                                :value="summary.breach_source_total"
                            />
                        </div>
                    </div>
                </div>
                <!-- SOURCE OF BREACH -->

                <!-- VULNERABILITIES CHART -->
                <div :class="chartBox">
                    <div class="card p-shadow-7 full-height flex-column">
                        <div class="p-text-center p-mb-4">
                            <strong>
                                {{ $t("general.technology_risk") }}&nbsp;
                            </strong>
                            <small>
                                ({{
                                    $t("pages.report.chart_potential_by_risk")
                                }})
                            </small>
                        </div>
                        <div
                            class="card-value flex-centered p-text-center flex1"
                            style="padding-left: 1rem; padding-right: 1rem"
                        >
                            <Chart
                                id="chartTechnologyRisk"
                                type="doughnut"
                                :width="charts.vulnerabilities.width"
                                :height="300"
                                :data="charts.vulnerabilities.data"
                                :options="charts.vulnerabilities.options"
                            />
                        </div>
                    </div>
                </div>
                <!-- VULNERABILITIES CHART -->

                <!-- AVERAGE CHART -->
                <div :class="chartBox">
                    <div class="card p-shadow-7 full-height flex-column">
                        <div class="p-text-center p-mb-4">
                            <strong>
                                {{ $t("general.human_risk") }}&nbsp;
                            </strong>
                            <small>
                                ({{ $t("pages.report.breaches_by_risk") }})
                            </small>
                        </div>
                        <div
                            class="card-value flex-centered p-text-center flex1"
                            style="padding-left: 1rem; padding-right: 1rem"
                        >
                            <Chart
                                id="chartHumanRisk"
                                type="doughnut"
                                :width="charts.breaches.width"
                                :height="300"
                                :data="charts.breaches.data"
                                :options="charts.breaches.options"
                            />
                        </div>
                    </div>
                    <!-- <div class="card p-shadow-7 full-height flex-column">
                        <div class="p-text-center p-mb-4">
                            <strong>{{
                                $t(
                                    "pages.report.chart_average_potential_vulnerabilities"
                                )
                            }}</strong>
                        </div>
                        <div
                            class="card-value flex-centered p-text-center flex1"
                            style="padding-left: 1rem; padding-right: 1rem"
                        >
                            <Chart
                                type="bar"
                                :width="charts.average.width"
                                :height="300"
                                :data="charts.average.data"
                                :options="charts.average.options"
                            />
                        </div>
                    </div> -->
                </div>
                <!-- AVERAGE CHART -->

                <!-- IMPACT CHART -->
                <div :class="chartBox">
                    <div class="card p-shadow-7 full-height flex-column">
                        <div class="p-text-center p-mb-4">
                            <strong>
                                {{ $t("general.gdpr_risk") }}&nbsp;
                            </strong>
                            <small>
                                ({{ $t("pages.report.chart_full_impact") }})
                            </small>
                        </div>
                        <div
                            class="card-value flex-centered p-text-center flex1"
                            style="padding-left: 1rem; padding-right: 1rem"
                        >
                            <Chart
                                id="chartGdprRisk"
                                type="bar"
                                :width="charts.impact.width"
                                :height="300"
                                :data="charts.impact.data"
                                :options="charts.impact.options"
                            />
                        </div>
                    </div>
                </div>
                <!-- IMPACT CHART -->
            </div>
        </div>
        <!-- / Summary -->
    </div>
</template>

<script>
import { ReportService } from "../../../services";

const getChartWidth = () => {
    let width;
    if (window.innerWidth < 992) {
        width = Math.round((window.innerWidth * 80) / 100);
    } else if (window.innerWidth < 1200) {
        width = Math.round((window.innerWidth * 24) / 100);
    } else {
        width = Math.round((window.innerWidth * 22) / 100);
    }
    // } else {
    //     width = Math.round((window.innerWidth * 30) / 100);
    // }

    return width;
};

export default {
    name: "Summary",
    reportService: null,
    created() {
        this.reportService = new ReportService();
    },
    props: {
        loading: {
            type: Boolean,
            default: () => true,
        },
        isVisible: {
            type: Boolean,
            default: () => true,
        },
        summary: {
            type: Object,
            default: () => {
                return {};
            },
        },
        ransomware: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            charts: {
                impact: {
                    data: {
                        labels: [
                            this.$t("general.confidentiality"),
                            this.$t("general.integrity"),
                            this.$t("general.availability"),
                        ],
                        datasets: [],
                        legend: false,
                    },
                    options: {
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        legend: {
                            display: false,
                            position: "bottom",
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                        },
                    },
                    width: getChartWidth(),
                },
                vulnerabilities: {
                    data: {
                        labels: [
                            this.$t("general.high"),
                            this.$t("general.medium"),
                            this.$t("general.low"),
                        ],
                        datasets: [],
                        legend: false,
                    },
                    options: {
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                    },
                    width: getChartWidth(),
                },
                breaches: {
                    data: {
                        labels: [
                            this.$t("general.high"),
                            this.$t("general.medium"),
                            this.$t("general.low"),
                        ],
                        datasets: [],
                        legend: false,
                    },
                    options: {
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                    },
                    width: getChartWidth(),
                },
                average: {
                    data: {
                        labels: [
                            this.$t("pages.report.average_total"),
                            this.$t("pages.report.average_high"),
                            this.$t("pages.report.average_medium"),
                            this.$t("pages.report.average_low"),
                        ],
                        datasets: [],
                        legend: false,
                    },
                    options: {
                        legend: {
                            display: false,
                            position: "bottom",
                        },
                    },
                    width: getChartWidth(),
                },
            },
        };
    },
    computed: {
        chartBox() {
            // if (this.ransomware) return "p-col-12 p-lg-6";
            return "p-col-12 p-lg-4";
        },
    },
    updated() {},
    methods: {
        setChartsData() {
            if (this.summary) {
                this.charts.impact.data.datasets = [
                    {
                        label: "",
                        backgroundColor: [
                            this.$appColors.confidentiality,
                            this.$appColors.integrity,
                            this.$appColors.availability,
                        ],
                        data: [
                            this.summary.impact_confidentiality_total,
                            this.summary.impact_integrity_total,
                            this.summary.impact_availability_total,
                        ],
                    },
                ];

                this.charts.average.data.datasets = [
                    {
                        label: "",
                        backgroundColor: [
                            "#FFFFFF",
                            this.$appColors.red,
                            this.$appColors.yellow,
                            this.$appColors.blue,
                        ],
                        data: [
                            this.summary.average.total,
                            this.summary.average.high,
                            this.summary.average.medium,
                            this.summary.average.low,
                        ],
                    },
                ];

                this.charts.vulnerabilities.data.datasets = [
                    {
                        label: "",
                        backgroundColor: [
                            this.$appColors.red,
                            this.$appColors.yellow,
                            this.$appColors.blue,
                        ],
                        data: [
                            this.summary.cve_high_total,
                            this.summary.cve_medium_total,
                            this.summary.cve_low_total,
                        ],
                    },
                ];

                this.charts.breaches.data.datasets = [
                    {
                        label: "",
                        backgroundColor: [
                            this.$appColors.red,
                            this.$appColors.yellow,
                            this.$appColors.blue,
                        ],
                        data: [
                            this.summary.breach_chart_risk.breach_total_high,
                            this.summary.breach_chart_risk.breach_total_medium,
                            this.summary.breach_chart_risk.breach_total_low,
                        ],
                    },
                ];

                setTimeout(() => {
                    const risk = document.getElementById("chartTechnologyRisk");
                    const human = document.getElementById("chartHumanRisk");
                    const gdpr = document.getElementById("chartGdprRisk");
                    const canvasRisk = risk.children[0];
                    const canvasHuman = human.children[0];
                    const canvasGDPR = gdpr.children[0];
                    canvasRisk.style.maxHeight = "500px";
                    canvasHuman.style.maxHeight = "500px";
                    canvasGDPR.style.maxHeight = "500px";
                }, 500);
            }
        },
    },
    watch: {
        summary: function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.setChartsData();
            }
        },
    },
    mounted() {
        this.setChartsData();
    },
};
</script>

<style lang="scss" scoped>
@import "./Report";
::v-deep(#ransomware_attack_index) {
    svg {
        text {
            fill: #fff;
        }
    }
}
</style>