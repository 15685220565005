import { RouterService } from "./RouterService/RouterService";
import { ENV_VARS } from "@/env_vars";

export class BaseService {
  BASE_URL = ENV_VARS.VUE_APP_BACKEND_BASE_URL;
  BASE_API = "/";

  /**
   *
   * @param {string} api optional - additional api link
   * @param {object} args  optional - api params, body, additional headers, request timeout, responseType:
   * {
   *  params: {foo: 'bar'}, //* This will be parsed to ?foo=bar
   *  headers: {'Content-Type': 'application/json'},
   *  timeout: 30000, //* in milliseconds
   *  responseType: 'blob',
   * }
   */
  async get(api, args) {
    args = this.getArgs(args);
    api = this.getApi(api, args);

    return RouterService.get(api, args);
  }

  /**
   *
   * @param {string} api optional - additional api link
   * @param {object} body  optional - Request body:
   * {
   *  foo: 'bar',
   * }
   * @param {object} args  optional - api params, body, additional headers, request timeout, responseType:
   * {
   *  params: {foo: 'bar'}, //* This will be parsed to ?foo=bar
   *  headers: {'Content-Type': 'application/json'},
   *  timeout: 30000, //* in milliseconds
   *  responseType: 'blob',
   * }
   */
  async post(api, body, args) {
    args = this.getArgs(args);
    api = this.getApi(api, args);

    return RouterService.post(api, body, args);
  }

  /**
   *
   * @param {string} api optional - additional api link
   * @param {object} body  optional - Request body:
   * {
   *  foo: 'bar',
   * }
   * @param {object} args  optional - api params, body, additional headers, request timeout, responseType:
   * {
   *  params: {foo: 'bar'}, //* This will be parsed to ?foo=bar
   *  headers: {'Content-Type': 'application/json'},
   *  timeout: 30000, //* in milliseconds
   *  responseType: 'blob',
   * }
   */
  async put(api, body, args) {
    args = this.getArgs(args);
    api = this.getApi(api, args);

    return RouterService.put(api, body, args);
  }

  /**
   *
   * @param {string} api optional - additional api link
   * @param {object} args  optional - api params, body, additional headers, request timeout, responseType:
   * {
   *  params: {foo: 'bar'}, //* This will be parsed to ?foo=bar
   *  headers: {'Content-Type': 'application/json'},
   *  timeout: 30000, //* in milliseconds
   *  responseType: 'application/json',
   * }
   */
  async delete(api, args) {
    args = this.getArgs(args);
    api = this.getApi(api, args);

    return RouterService.delete(api, args);
  }

  /**
   *
   * @param {object} data object containing request info:
   * {
   *  method: 'get', //* required - string representing the api method to use (get, post, put, delete)
   *  api: '/dti/1.0', //* optional - additional api link
   *  body: {foo: 'bar'}, //* optional - additional api link
   *  args: {
   *    params: {foo: 'bar'}, //* This will be parsed to ?foo=bar
   *    headers: {'Content-Type': 'application/json'},
   *    timeout: 30000, //* in milliseconds
   *    responseType: 'application/json',
   *  }, //* optional - additional api link
   * }
   */
  async request({ method, api, body, args }) {
    args = this.getArgs(args);
    api = this.getApi(api, args);

    return RouterService.request({
      method,
      api,
      body,
      args,
    });
  }

  async graphql({ method, api, body, args }) {
    args = this.getArgs(args);
    api = this.getApi(api, args) + "/graphql";

    if (args.params.query) {
      args.params.query = args.params.query.replace(/\s\s+/g, " ");
    }

    return RouterService.request({
      method,
      api,
      body,
      args,
    });
  }

  getApi(api, args) {
    if (!api) {
      api = this.BASE_API;
    } else {
      //* Add slash if not present at begin of api string
      if (api.charAt(0) === "/") {
        api = api.substring(1);
      }
      api = this.BASE_API + api;
    }
    //* Add slash if not present at the end of api string
    if (api.charAt(api.length - 1) !== "/" && args.addSlash) {
      api += "/";
    }

    //* Return complete api preventing double slashes (//)
    return api.replace(/\/\//g, "/");
  }

  getArgs(args) {
    if (!args) {
      args = {
        addSlash: true,
      };
    }
    args.baseUrl = args.baseUrl || this.BASE_URL;

    return args;
  }
}

/**
 * @api {post} https://beta.swascan.com/auth/realms/swa/protocol/openid-connect/token  Login
 * @apiVersion 1.0.0
 * @apiName authenticate
 * @apiGroup Auth
 *
 * @apiSampleRequest https://beta.swascan.com/auth/realms/swa/protocol/openid-connect/token
 *
 * @apiHeader {String} content-type         application/x-www-form-urlencoded
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Content-Type": "application/x-www-form-urlencoded"
 *      }
 *
 * @apiParam {String} client_id             prod for production - preprod for pre production - staging for staging
 * @apiParam {String} grant_type            grant_type=password
 * @apiParam {String} scope                 scope=openid
 * @apiParam {String} username              Username
 * @apiParam {String} password              Password
 * @apiParamExample {json} Body-Example:
 *      {
 *          "client_id": "prod",
 *          "grant_type": "password",
 *          "scope": "openid",
 *          "username": "username",
 *          "password": "password"
 *     }
 *
 * @apiSuccess {String} access_token                Keycloak access_token
 * @apiSuccess {Number} expires_in                  Expiration time in seconds
 * @apiSuccess {Number} refresh_expires_in          Refresh token expiration time in seconds
 * @apiSuccess {String} refresh_token               Refresh token
 * @apiSuccess {String} token_type                  Token type
 * @apiSuccess {Number} not-before-policy
 * @apiSuccess {String} session_state
 * @apiSuccess {String} scope
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *          "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxODI3MjAsImlhdCI6MTYxOTE4MjQyMCwianRpIjoiMTY0ZTAyNDMtY2JiNy00MDY4LWE3YTktNjMwMGM0MDNiOTNkIiwiaXNzIjoiaHR0cDovL2JldGEuc3dhc2Nhbi5jb20vYXV0aC9yZWFsbXMvc3dhIiwic3ViIjoiMTFjMTM4NzAtNmU0Zi00Y2IyLTlhNTAtMzMxZjk3NDE1Njg5IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiZnJvbnRlbmQiLCJzZXNzaW9uX3N0YXRlIjoiZjBjZGUwOTMtNzkwMC00OGE1LWE0OWYtYmRkZjM0MDE1OTgwIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyIqIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsInN0YWZmIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInNjb3BlIjoiZW1haWwgcHJvZmlsZSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6Ikx1Y2EgRCdJc2FudG8iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJsLmRpc2FudG9Ac3dhc2Nhbi5jb20iLCJnaXZlbl9uYW1lIjoiTHVjYSIsImZhbWlseV9uYW1lIjoiRCdJc2FudG8iLCJlbWFpbCI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSJ9.BMMLeGAP2b6y4L-Pu9uWaJEoGW_4NV9Syhg1dDLxQzsyJg-_1n4v_qnndAV7JVAZvr4mZcDdR8BAjrpbcSdDTT43lv4zfFJev0qGF1dQB_JS2lBaIevCQ6m9PFKC0EuoayX6qYX0u4rJ7vtun2cjNFLEjXxBhGXgTaQMb9RkAzd7m5lPRijTDwPj6J5G7c4F32SuaNeusqDuEoxQJhbvN-Ah0uGoK7BgC3rTs1hSz5RWd4Jjq9HeaDmm4-bwfana4P-WiE6pgZA9hfAZLd9WFAsQ_mZwWOMsWpbNSav-Lzmzo7nL1UfjPi-ZS1lE47Bpl72qcflVVDRNBJyA6y5BhQ",
 *          "expires_in": 300,
 *          "refresh_expires_in": 1800,
 *          "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJlMDdkODEzMS1hZDhlLTRkMTMtOWE4My02YWMyODAyY2ZhYzgifQ.eyJleHAiOjE2MTkxODQyMjAsImlhdCI6MTYxOTE4MjQyMCwianRpIjoiZjNiYWYwZTAtZWMxMy00YjQ2LTkyNDctZDQyOTJmOGE2OWY1IiwiaXNzIjoiaHR0cDovL2JldGEuc3dhc2Nhbi5jb20vYXV0aC9yZWFsbXMvc3dhIiwiYXVkIjoiaHR0cDovL2JldGEuc3dhc2Nhbi5jb20vYXV0aC9yZWFsbXMvc3dhIiwic3ViIjoiMTFjMTM4NzAtNmU0Zi00Y2IyLTlhNTAtMzMxZjk3NDE1Njg5IiwidHlwIjoiUmVmcmVzaCIsImF6cCI6ImZyb250ZW5kIiwic2Vzc2lvbl9zdGF0ZSI6ImYwY2RlMDkzLTc5MDAtNDhhNS1hNDlmLWJkZGYzNDAxNTk4MCIsInNjb3BlIjoiZW1haWwgcHJvZmlsZSJ9.Eb8jYfGkuO8N2F9EyNCbN3YDZK-akeOQntnR7dPG6TQ",
 *          "token_type": "Bearer",
 *          "not-before-policy": 0,
 *          "session_state": "f0cde093-7900-48a5-a49f-bddf34015980",
 *          "scope": "email profile"
 *     }
 */

/**
 * @api {post} https://beta.swascan.com/auth/realms/swa/protocol/openid-connect/token  Refresh token
 * @apiVersion 1.0.0
 * @apiName Refresh token
 * @apiGroup Auth
 *
 * @apiSampleRequest off
 *
 * @apiHeader {String} Content-Type         application/x-www-form-urlencoded
 * @apiHeaderExample {json} Header-Example:
 *      {
 *          "Content-Type": "application/x-www-form-urlencoded"
 *      }
 *
 * @apiParam {String} client_id             prod for production - preprod for pre production - staging for staging
 * @apiParam {String} grant_type            grant_type=refresh_token
 * @apiParam {String} scope                 scope=openid
 * @apiParam {String} refresh_token                 refresh_token
 * @apiParamExample {json} Body-Example:
 *      {
 *          "client_id": "prod",
 *          "grant_type": "refresh_token",
 *          "scope": "openid",
 *          "refresh_token": "refresh_token"
 *     }
 *
 * @apiSuccess {String} access_token                Keycloak access_token
 * @apiSuccess {Number} expires_in                  Expiration time in seconds
 * @apiSuccess {Number} refresh_expires_in          Refresh token expiration time in seconds
 * @apiSuccess {String} refresh_token               Refresh token
 * @apiSuccess {String} token_type                  Token type
 * @apiSuccess {Number} not-before-policy
 * @apiSuccess {String} session_state
 * @apiSuccess {String} scope
 * @apiSuccessExample {json} Success-Response:
 *     HTTP/1.1 200 OK
 *
 *     {
 *          "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJucGdhZ2oxdklHRE5JTmM4UWVKVm5Xc2NwT3BVRm1FbHdlQmxvU0FtWG13In0.eyJleHAiOjE2MTkxODI3MjAsImlhdCI6MTYxOTE4MjQyMCwianRpIjoiMTY0ZTAyNDMtY2JiNy00MDY4LWE3YTktNjMwMGM0MDNiOTNkIiwiaXNzIjoiaHR0cDovL2JldGEuc3dhc2Nhbi5jb20vYXV0aC9yZWFsbXMvc3dhIiwic3ViIjoiMTFjMTM4NzAtNmU0Zi00Y2IyLTlhNTAtMzMxZjk3NDE1Njg5IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiZnJvbnRlbmQiLCJzZXNzaW9uX3N0YXRlIjoiZjBjZGUwOTMtNzkwMC00OGE1LWE0OWYtYmRkZjM0MDE1OTgwIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyIqIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsInN0YWZmIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInNjb3BlIjoiZW1haWwgcHJvZmlsZSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6Ikx1Y2EgRCdJc2FudG8iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJsLmRpc2FudG9Ac3dhc2Nhbi5jb20iLCJnaXZlbl9uYW1lIjoiTHVjYSIsImZhbWlseV9uYW1lIjoiRCdJc2FudG8iLCJlbWFpbCI6ImwuZGlzYW50b0Bzd2FzY2FuLmNvbSJ9.BMMLeGAP2b6y4L-Pu9uWaJEoGW_4NV9Syhg1dDLxQzsyJg-_1n4v_qnndAV7JVAZvr4mZcDdR8BAjrpbcSdDTT43lv4zfFJev0qGF1dQB_JS2lBaIevCQ6m9PFKC0EuoayX6qYX0u4rJ7vtun2cjNFLEjXxBhGXgTaQMb9RkAzd7m5lPRijTDwPj6J5G7c4F32SuaNeusqDuEoxQJhbvN-Ah0uGoK7BgC3rTs1hSz5RWd4Jjq9HeaDmm4-bwfana4P-WiE6pgZA9hfAZLd9WFAsQ_mZwWOMsWpbNSav-Lzmzo7nL1UfjPi-ZS1lE47Bpl72qcflVVDRNBJyA6y5BhQ",
 *          "expires_in": 300,
 *          "refresh_expires_in": 1800,
 *          "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJlMDdkODEzMS1hZDhlLTRkMTMtOWE4My02YWMyODAyY2ZhYzgifQ.eyJleHAiOjE2MTkxODQyMjAsImlhdCI6MTYxOTE4MjQyMCwianRpIjoiZjNiYWYwZTAtZWMxMy00YjQ2LTkyNDctZDQyOTJmOGE2OWY1IiwiaXNzIjoiaHR0cDovL2JldGEuc3dhc2Nhbi5jb20vYXV0aC9yZWFsbXMvc3dhIiwiYXVkIjoiaHR0cDovL2JldGEuc3dhc2Nhbi5jb20vYXV0aC9yZWFsbXMvc3dhIiwic3ViIjoiMTFjMTM4NzAtNmU0Zi00Y2IyLTlhNTAtMzMxZjk3NDE1Njg5IiwidHlwIjoiUmVmcmVzaCIsImF6cCI6ImZyb250ZW5kIiwic2Vzc2lvbl9zdGF0ZSI6ImYwY2RlMDkzLTc5MDAtNDhhNS1hNDlmLWJkZGYzNDAxNTk4MCIsInNjb3BlIjoiZW1haWwgcHJvZmlsZSJ9.Eb8jYfGkuO8N2F9EyNCbN3YDZK-akeOQntnR7dPG6TQ",
 *          "token_type": "Bearer",
 *          "not-before-policy": 0,
 *          "session_state": "f0cde093-7900-48a5-a49f-bddf34015980",
 *          "scope": "email profile"
 *     }
 */
