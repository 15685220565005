import { baseOptions, baseSlide, colors, swascanMail, swascanSite } from "../constants";

export default (pptx) => {
    var slide = baseSlide(pptx, colors.white, colors.blue);

    slide.addImage({
        path: "assets/images/tinexta_swascan.png",
        x: "4%",
        y: "7%",
        w: 0.6,
        h: 0.9,
        type: "contain",
    });

    slide.addText("Misura l'esposizione del Rischio Cyber!", {
        ...baseOptions.title,
        x: "10%",
        y: "10%",
        w: 6,
        bold: true,
        fontSize: 20,
        color: colors.blue,
    });

    //* DTI
    slide.addImage({
        path: "assets/images/search.png",
        w: 0.4,
        h: 0.4,
        type: "contain",
        x: "10%",
        y: "27%",
    });
    slide.addText("Domain Threat", {
        ...baseOptions.text,
        x: "15%",
        y: "27%",
        bold: true,
        color: colors.red,
        fontSize: 12,
    });
    slide.addText("Intelligence", {
        ...baseOptions.text,
        x: "15%",
        y: "30%",
        color: colors.red,
        fontSize: 12,
    });
    slide.addText(
        [
            {
                text: "Scopri quali informazioni sono disponibili a livello di OSINT e CLOSINT",
                options: { bold: true, breakLine: false },
            },
            {
                text: " per un determinato target a livello di dominio, sottodominio e",
                options: { breakLine: false },
            },
            {
                text: " email compromesse",
                options: { bold: true },
            },
        ],
        {
            x: "10%",
            y: "38%",
            w: 3.5,
            h: 3,
            valign: "top",
            color: colors.blue,
            fontSize: 9,
            fontFace: "Segoe UI",
        }
    );
    slide.addImage({
        path: "assets/images/dti.png",
        w: 3.5,
        h: 1.96,
        type: "contain",
        x: "10%",
        y: "54%",
    });

    //* CTI
    slide.addImage({
        path: "assets/images/world_red.png",
        w: 0.4,
        h: 0.4,
        type: "contain",
        x: "55%",
        y: "27%",
    });
    slide.addText("Cyber Threat", {
        ...baseOptions.text,
        x: "60%",
        y: "27%",
        bold: true,
        color: colors.red,
        fontSize: 12,
    });
    slide.addText("Intelligence", {
        ...baseOptions.text,
        x: "60%",
        y: "30%",
        color: colors.red,
        fontSize: 12,
    });
    slide.addText(
        [
            {
                text: "Raccolta e analisi di informazioni",
                options: { bold: true, breakLine: false },
            },
            {
                text: " (da Data Breach all'attività Botnet) a livello di OSINT e CLOSINT riguardanti le",
                options: { breakLine: false },
            },
            {
                text: " minacce informatiche",
                options: { bold: true, breakLine: false },
            },
            {
                text: " che mettono a rischio la tua azienda",
            },
        ],
        {
            x: "55%",
            y: "38%",
            w: 3.5,
            h: 3,
            valign: "top",
            color: colors.blue,
            fontSize: 9,
            fontFace: "Segoe UI",
        }
    );
    slide.addImage({
        path: "assets/images/cti.png",
        w: 3.5,
        h: 1.96,
        type: "contain",
        x: "55%",
        y: "54%",
    });

    slide.addText("swascan.com", swascanSite);
    slide.addText("info@swascan.com", swascanMail);
};