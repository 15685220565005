import { getQuery } from "./get_query";

export class RRIQuery {
    static summary = `query {
summary {
  version
  target
  target_cleaned
  license_info {
    name
    api
    scans_max
    full_mode
    binary_edge_ip_score
    id
    user_id
    user_role
    scans_done
    expires_on
  }
  email_total
  domain_specific_emails
  other_emails
  breach_source_total
  breach_total
  breach_chart { name total }
  breach_chart_risk { breach_total_high breach_total_medium breach_total_low }
  cve_total
  cve_unique_total
  cve_high_total
  cve_medium_total
  cve_low_total
  cve_high_unique_total
  cve_medium_unique_total
  cve_low_unique_total
  cve_counter { critical high medium low }
  cve_unique_counter { critical high medium low }
  impact2 { availability_total confidentiality_total integrity_total availability_unique_total confidentiality_unique_total integrity_unique_total }
  impact3 { availability_total confidentiality_total integrity_total availability_unique_total confidentiality_unique_total integrity_unique_total }
  impact_overall { availability_total confidentiality_total integrity_total availability_unique_total confidentiality_unique_total integrity_unique_total }
  impact_availability_total
  impact_confidentiality_total
  impact_integrity_total
  impact_availability_unique_total
  impact_confidentiality_unique_total
  impact_integrity_unique_total
  impact3_availability_total
  impact3_confidentiality_total
  impact3_integrity_total
  impact3_availability_unique_total
  impact3_confidentiality_unique_total
  impact3_integrity_unique_total
  total_ip_found
  total_subdomain_found
  ransomware_attack_index
}
}`;
}
