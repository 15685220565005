import { baseOptions, baseSlide, colors } from "../constants";

export default (pptx) => {
    var slide = baseSlide(pptx, colors.white, colors.blue);
    slide.addShape(pptx.ShapeType.rect, {
        fill: colors.blue,
        x: "50%",
        y: 0,
        w: "50%",
        h: "100%",
    });

    slide.addImage({
        path: "assets/images/tinexta_swascan.png",
        x: "4%",
        y: "7%",
        w: 0.6,
        h: 0.9,
        type: "contain",
    });

    slide.addText("I Cyber Risk Aziendali", {
        ...baseOptions.title,
        bold: true,
        x: "10%",
        y: "10%",
        fontSize: 20,
        color: colors.blue,
    });

    slide.addText("• Ransomware Attack", {
        ...baseOptions.title,
        x: "10%",
        y: "25%",
        fontSize: 13,
        color: colors.blue,
    });
    slide.addText("• External Treats", {
        ...baseOptions.title,
        x: "10%",
        y: "30%",
        fontSize: 13,
        color: colors.blue,
    });
    slide.addText("• Hacking", {
        ...baseOptions.title,
        x: "10%",
        y: "35%",
        fontSize: 13,
        color: colors.blue,
    });
    slide.addText("• Data Breach", {
        ...baseOptions.title,
        x: "10%",
        y: "40%",
        fontSize: 13,
        color: colors.blue,
    });
    slide.addText("• Insider Treats", {
        ...baseOptions.title,
        x: "10%",
        y: "45%",
        fontSize: 13,
        color: colors.blue,
    });
    slide.addText("• ...", {
        ...baseOptions.title,
        x: "10%",
        y: "50%",
        fontSize: 13,
        color: colors.blue,
    });

    slide.addImage({
        path: "assets/images/soc_as_a_service.png",
        w: 3.7,
        h: 3.63,
        type: "contain",
        x: "58%",
        y: "20%",
    });
};