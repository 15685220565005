import pptxgen from "pptxgenjs";
import generateSlide1 from './generate-slide-1';
import generateSlide2 from './generate-slide-2';
import generateSlide3 from './generate-slide-3';
import generateSlide4 from './generate-slide-4';
import generateSlide5 from './generate-slide-5';
import generateSlide6 from './generate-slide-6';
import generateSlide7 from './generate-slide-7';
import generateSlide8 from './generate-slide-8';
import generateSlide9 from './generate-slide-9';
import generateSlide10 from './generate-slide-10';
import generateSlide11 from './generate-slide-11';

export const generateDTI_PPTX = (reportId, obj) => {
    
    const pptx = new pptxgen();

    var generatePPTX = () => {

        // 1. Create a new Presentation
        pptx.author = "Swascan";
        pptx.company = "Swascan srl";
        pptx.revision = "1";
        pptx.subject = "DTI Report";
        pptx.title = "DTI Report Presentation";

        // 2. Add Slides
        generateSlide1(pptx);
        generateSlide2(pptx, obj.slide6.target);
        generateSlide3(pptx);
        generateSlide4(pptx);
        generateSlide5(pptx);
        generateSlide6(pptx, obj.slide6);
        generateSlide7(pptx);
        generateSlide8(pptx);
        generateSlide9(pptx, obj.slide9);
        generateSlide10(pptx);
        generateSlide11(pptx);

        // 4. Save the Presentation
        pptx.writeFile(`swascan-executive_report.pptx`);
    };

    setTimeout(() => {
        generatePPTX();
    }, 1000);
};
