<template>
    <div class="swa-rounder-container">
        <div class="swa-small-rounder-container">
            {{ val1 }}
        </div>
        {{ val2 }}
    </div>
</template>

<script>
import { Formatter } from "../../utilities";
export default {
    name: "DoubleRounderContainer",
    props: {
        value1: null,
        value2: null,
    },
    computed: {
        val1() {
            return Formatter(this.value1);
        },
        val2() {
            return Formatter(this.value2);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./DoubleRounderContainer";
</style>