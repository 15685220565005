import { baseOptions, baseSlide, colors } from "../constants";

export default (pptx, target) => {
    var slide = baseSlide(pptx, colors.white, colors.blue);
    slide.addShape(pptx.ShapeType.rect, {
        fill: colors.darkBlue,
        x: 3,
        y: 0,
        w: "100%",
        h: "100%",
    });

    slide.addImage({
        path: "assets/images/tinexta_swascan.png",
        x: 1.1,
        y: "10%",
        w: 0.6,
        h: 0.9,
        type: "contain",
    });

    slide.addText("Indice", {
        ...baseOptions.title,
        bold: true,
        x: "37%",
        y: "35%",
        fontSize: 34,
    });
    slide.addText(
        `1. Cyber Security Framework\n2. Misura la tua Esposizione Cyber: ${target}\n3. Remediation Plan`, {
            ...baseOptions.title,
            x: "37%",
            y: "46%",
            fontSize: 13,
            lineSpacing: 24
        }
    )
    /*slide.addText("1. Cyber Security Framework", {
        ...baseOptions.title,
        x: "37%",
        y: "46%",
        fontSize: 13,
    });
    slide.addText(`2. Misura la tua Esposizione Cyber: ${target}`, {
        ...baseOptions.title,
        x: "37%",
        y: "51%",
        fontSize: 13,
    });
    slide.addText("3. Remediation Plan", {
        ...baseOptions.title,
        x: "37%",
        y: "56%",
        fontSize: 13,
    });*/
};