<template>
    <ul :class="containerClass" :role="root ? 'menubar' : 'menu'">
        <template v-for="(item, i) of model">
            <li
                role="none"
                :class="getItemClass(item)"
                :style="item.style"
                v-if="visible(item) && !item.separator"
                :key="item.label + i"
                @mouseenter="onItemMouseEnter($event, item)"
            >
                <router-link
                    v-if="item.to && !item.disabled"
                    :to="item.to"
                    custom
                    v-slot="{ navigate, href }"
                >
                    <a
                        :href="href"
                        @click="onItemClick($event, item, navigate)"
                        :class="getLinkClass(item)"
                        v-ripple
                        @keydown="onItemKeyDown($event, item)"
                        role="menuitem"
                    >
                        <span :class="['p-menuitem-icon', item.icon]"></span>
                        <span class="p-menuitem-text">{{ item.label }}</span>
                    </a>
                </router-link>
                <a
                    v-else
                    :href="item.url"
                    :class="getLinkClass(item)"
                    :target="item.target"
                    :aria-haspopup="item.items != null"
                    :aria-expanded="item === activeItem"
                    @click="onItemClick($event, item)"
                    @keydown="onItemKeyDown($event, item)"
                    role="menuitem"
                    :tabindex="item.disabled ? null : '0'"
                    v-ripple
                >
                    <span :class="['p-menuitem-icon', item.icon]"></span>
                    <span class="p-menuitem-text">{{ item.label }}</span>
                    <span :class="getSubmenuIcon()" v-if="item.items"></span>
                </a>
                <sub-menu
                    :model="item.items"
                    v-if="visible(item) && item.items"
                    :key="item.label + '_sub_'"
                    :mobileActive="mobileActive"
                    @leaf-click="onLeafClick"
                    @keydown-item="onChildItemKeyDown"
                    :parentActive="item === activeItem"
                />
            </li>
            <li
                class="p-menu-separator"
                :style="item.style"
                v-if="visible(item) && item.separator"
                :key="'separator' + i"
                role="separator"
            ></li>
        </template>
    </ul>
</template>

<script>
import DomHandler from "./DomHandler";
import Ripple from "primevue/ripple";

export default {
    name: "sub-menu",
    emits: ["keydown-item", "leaf-click"],
    props: {
        model: {
            type: Array,
            default: null,
        },
        root: {
            type: Boolean,
            default: false,
        },
        popup: {
            type: Boolean,
            default: false,
        },
        parentActive: {
            type: Boolean,
            default: false,
        },
        mobileActive: {
            type: Boolean,
            default: false,
        },
    },
    documentClickListener: null,
    watch: {
        parentActive(newValue) {
            if (!newValue) {
                this.activeItem = null;
            }
        },
    },
    data() {
        return {
            activeItem: null,
        };
    },
    updated() {
        if (this.root && this.activeItem) {
            this.bindDocumentClickListener();
        }
    },
    beforeUnmount() {
        this.unbindDocumentClickListener();
    },
    methods: {
        onItemMouseEnter(event, item) {
            if (item.disabled || this.mobileActive) {
                event.preventDefault();
                return;
            }

            if (this.root) {
                if (this.activeItem || this.popup) {
                    this.activeItem = item;
                }
            } else {
                this.activeItem = item;
            }
        },
        onItemClick(event, item, navigate) {
            if (item.disabled) {
                event.preventDefault();
                return;
            }

            if (item.command) {
                item.command({
                    originalEvent: event,
                    item: item,
                });
            }

            if (item.items) {
                if (this.activeItem && item === this.activeItem) {
                    this.activeItem = null;
                } else {
                    this.activeItem = item;
                }
            }

            if (!item.items) {
                this.onLeafClick();
            }

            if (item.to && navigate) {
                navigate(event);
            }
        },
        onLeafClick() {
            this.activeItem = null;
            this.$emit("leaf-click");
        },
        onItemKeyDown(event, item) {
            let listItem = event.currentTarget.parentElement;

            switch (event.which) {
                //down
                case 40:
                    if (this.root) {
                        if (item.items) {
                            this.expandSubmenu(item, listItem);
                        }
                    } else {
                        this.navigateToNextItem(listItem);
                    }

                    event.preventDefault();
                    break;

                //up
                case 38:
                    if (!this.root) {
                        this.navigateToPrevItem(listItem);
                    }

                    event.preventDefault();
                    break;

                //right
                case 39:
                    if (this.root) {
                        var nextItem = this.findNextItem(listItem);
                        if (nextItem) {
                            nextItem.children[0].focus();
                        }
                    } else {
                        if (item.items) {
                            this.expandSubmenu(item, listItem);
                        }
                    }

                    event.preventDefault();
                    break;

                //left
                case 37:
                    if (this.root) {
                        this.navigateToPrevItem(listItem);
                    }

                    event.preventDefault();
                    break;

                default:
                    break;
            }

            this.$emit("keydown-item", {
                originalEvent: event,
                element: listItem,
            });
        },
        onChildItemKeyDown(event) {
            if (this.root) {
                //up
                if (
                    event.originalEvent.which === 38 &&
                    event.element.previousElementSibling == null
                ) {
                    this.collapseMenu(event.element);
                }
            } else {
                //left
                if (event.originalEvent.which === 37) {
                    this.collapseMenu(event.element);
                }
            }
        },
        findNextItem(item) {
            let nextItem = item.nextElementSibling;

            if (nextItem)
                return DomHandler.hasClass(nextItem, "p-disabled") ||
                    !DomHandler.hasClass(nextItem, "p-menuitem")
                    ? this.findNextItem(nextItem)
                    : nextItem;
            else return null;
        },
        findPrevItem(item) {
            let prevItem = item.previousElementSibling;

            if (prevItem)
                return DomHandler.hasClass(prevItem, "p-disabled") ||
                    !DomHandler.hasClass(prevItem, "p-menuitem")
                    ? this.findPrevItem(prevItem)
                    : prevItem;
            else return null;
        },
        expandSubmenu(item, listItem) {
            this.activeItem = item;

            setTimeout(() => {
                listItem.children[1].children[0].children[0].focus();
            }, 50);
        },
        collapseMenu(listItem) {
            this.activeItem = null;
            listItem.parentElement.previousElementSibling.focus();
        },
        navigateToNextItem(listItem) {
            var nextItem = this.findNextItem(listItem);
            if (nextItem) {
                nextItem.children[0].focus();
            }
        },
        navigateToPrevItem(listItem) {
            var prevItem = this.findPrevItem(listItem);
            if (prevItem) {
                prevItem.children[0].focus();
            }
        },
        getItemClass(item) {
            return [
                "p-menuitem",
                item.class,
                {
                    "p-menuitem-active": this.activeItem
                        ? this.activeItem.id === item.id
                        : false,
                },
            ];
        },
        getLinkClass(item) {
            return ["p-menuitem-link", { "p-disabled": item.disabled }];
        },
        bindDocumentClickListener() {
            if (!this.documentClickListener) {
                this.documentClickListener = (event) => {
                    if (this.$el && !this.$el.contains(event.target)) {
                        this.activeItem = null;
                        this.unbindDocumentClickListener();
                    }
                };

                document.addEventListener("click", this.documentClickListener);
            }
        },
        unbindDocumentClickListener() {
            if (this.documentClickListener) {
                document.removeEventListener(
                    "click",
                    this.documentClickListener
                );
                this.documentClickListener = null;
            }
        },
        getSubmenuIcon() {
            return [
                "p-submenu-icon pi",
                { "pi-angle-right": !this.root, "pi-angle-down": this.root },
            ];
        },
        visible(item) {
            return typeof item.visible === "function"
                ? item.visible()
                : item.visible !== false;
        },
    },
    computed: {
        containerClass() {
            return {
                "p-submenu-list": !this.root,
                "p-menubar-root-list": this.root,
            };
        },
    },
    directives: {
        ripple: Ripple,
    },
};
</script>