export const getQuery = (first = 20, skip = 0, { sort_by = null, order = 1, search = null, exactly = false, filters = {}, use_aggregation = false, sorting = null } = {}) => {
  let query = `first: ${first}, skip: ${skip}`;
  if (sort_by && !sorting) {
    query += `, sort_by: "${sort_by}", order: ${order}`;
  } else if (!sort_by && sorting) {
    let arr = []
    query += ", sorting: {"
    for (const [key, value] of Object.entries(sorting)) {
      arr.push(`${key}: ${value}`)
    }
    query += `${arr.join(', ')}}`
  }
  if (search && search.length > 0) {
    query += `, search: "${search}"`;
  } else if (!search && exactly) {
    query += `, search: ""`;
  }
  if (exactly) {
    query += `, exact: true`;
  }
  if (use_aggregation) {
    query += ", use_aggregation: true";
  }
  for (var key in filters) {
    var value = filters[key];
    if (typeof value === "string") {
      value = `"${value}"`;
    }
    query += `, ${key}: ${value}`;
  }

  return query;
};
