<template>
    <div class="login-page p-grid">
        <Toast position="top-right" />
        <div class="p-col-12 p-md-4 p-mr-3">
            <form @submit.prevent="submit">
                <div class="card p-grid p-shadow-7">
                    <div class="p-col-12 p-text-center">
                        <img
                            width="160"
                            alt="Logo"
                            class="p-mb-4"
                            src="assets/layout/images/logo_swascan_tinexta.svg"
                        />
                    </div>
                    <div class="p-col-12">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>

                            <InputText
                                id="username"
                                type="text"
                                v-model="username"
                                :placeholder="$t('general.name')"
                                @keyup="() => validateKeys()"
                            />
                        </div>
                    </div>
                    <div class="p-col-12 p-mb-2" style="margin-top: -20px">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-key"></i>
                            </span>

                            <InputText
                                id="password"
                                type="password"
                                v-model="password"
                                placeholder="password"
                                @keyup="() => validateKeys()"
                            />
                        </div>
                    </div>
                    <div class="p-col-12 p-text-right">
                        <a :href="resetUrl">{{ $t("login.reset_password") }}</a>
                    </div>
                    <div class="p-col-12 p-text-center">
                        <Button
                            class="p-mr-2 p-mb-2 p-button-primary button-block"
                            @click="submit()"
                            type="submit"
                            :disabled="!canSubmit || loading"
                        >
                            <i
                                class="pi pi-spin pi-spinner"
                                style="margin-right: 10px"
                                v-if="loading"
                            ></i>
                            {{ $t("login.sign_in") }}
                            <span style="margin-left: 10px" v-if="loading"
                                >&nbsp;&nbsp;&nbsp;</span
                            >
                        </Button>
                    </div>
                </div>
            </form>
        </div>
        <div class="p-col-12 p-md-4 p-ml-3">
            <div class="card p-grid p-shadow-7 centered">
                <div class="p-col-12 p-text-center">
                    <h2 class="p-text-bold">{{ $t("login.no_account") }}</h2>
                    <div class="p-mb-5">{{ $t("login.get_started") }}</div>
                    <Button
                        class="p-mr-2 p-mb-2 p-button-primary button-block"
                        @click="signUp()"
                    >
                        {{ $t("login.register") }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "../../store";
import { RouterService } from "../../services";
import { ENV_VARS } from "@/env_vars";

export default {
    setup() {
        return {
            state: useStore().state,
            setState: useStore().setState,
        };
    },
    data() {
        return {
            loading: false,
            username: null,
            password: null,
            canSubmit: false,

            keys: ["username", "password"],
        };
    },
    computed: {
        resetUrl() {
            return ENV_VARS.VUE_APP_RESET_PASSWORD;
        },
    },
    methods: {
        validateKey(key) {
            return this[key] && this[key].length > 0;
        },
        validateKeys() {
            for (const key of this.keys) {
                if (!this.validateKey(key)) {
                    this.canSubmit = false;
                    return;
                }
            }

            this.canSubmit = true;
            return;
        },
        async submit() {
            this.loading = true;
            try {
                await RouterService.login(this.username, this.password);
                this.loading = false;
                this.$router.replace({ path: "/dashboard" });
            } catch (e) {
                this.loading = false;
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("general.warning"),
                    detail: this.$t("login.credentials_error"),
                    life: 3000,
                });
            }
        },
        signUp() {
            window.location = ENV_VARS.VUE_APP_SIGN_UP;
        },
    },
};
</script>

<style lang="scss" scoped>
.login-page {
    width: 100vw;
    height: 100vh;
    margin-top: 0px !important;
    margin-left: 0px !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 3.5em;
    background-color: #f1f1f1;
    background-image: url("../../assets/layout/whitey.jpg");
    background-repeat: repeat;

    .p-md-4 {
        max-width: 420px;
    }

    .card {
        border-radius: 1em;
        padding: 2em;
        height: 450px;

        .fixed-height {
            height: 45px;
            overflow: hidden;
        }

        .button-block {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .card.centered {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>