import { baseOptions, baseSlide, colors, swascanMail, swascanSite } from "../constants";

export default (pptx) => {
    var slide = baseSlide(pptx, colors.white, colors.blue);
    slide.addShape(pptx.ShapeType.rect, {
        fill: colors.blue,
        x: "50%",
        y: 0,
        w: "50%",
        h: "100%",
    });

    slide.addImage({
        path: "assets/images/tinexta_swascan.png",
        x: "4%",
        y: "7%",
        w: 0.6,
        h: 0.9,
        type: "contain",
    });

    slide.addText("Remediation Plan", {
        ...baseOptions.title,
        bold: true,
        x: "10%",
        y: "10%",
        fontSize: 20,
        color: colors.blue,
    });

    slide.addImage({
        path: "assets/images/remediation.png",
        w: 3.8,
        h: 3.36,
        type: "contain",
        x: "6%",
        y: "23%",
    });

    slide.addImage({
        path: "assets/images/eye.png",
        w: 0.4,
        h: 0.28,
        type: "contain",
        x: "55%",
        y: "23%",
    });
    slide.addText(
        [
            { text: "Sicurezza ", options: { breakLine: false } },
            { text: "Predittiva", options: { bold: true } },
        ],
        {
            ...baseOptions.text,
            x: "60%",
            y: "23%",
            color: colors.white,
            fontSize: 12,
            align: "left",
        }
    );
    slide.addText("1.", {
        ...baseOptions.text,
        x: "60%",
        y: "28.5%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText(
        [
            { text: "Prevedere un'attività periodica mensile di ", options: { breakLine: false } },
            { text: "Domain Threat Intelligence", options: { bold: true } },
        ],
        {
            ...baseOptions.text,
            x: "62%",
            y: "29%",
            w: 3.5,
            color: colors.white,
            fontSize: 8,
            align: "left",
            lineSpacing: 10,
        }
    );
    slide.addText("2.", {
        ...baseOptions.text,
        x: "60%",
        y: "34%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText(
        [
            { text: "Pianificare attività trimestrale di ", options: { breakLine: false } },
            { text: "Cyber Threat Intelligence", options: { bold: true } },
        ],
        {
            ...baseOptions.text,
            x: "62%",
            y: "34.5%",
            w: 3.5,
            color: colors.white,
            fontSize: 8,
            align: "left",
            lineSpacing: 10,
        }
    );

    slide.addImage({
        path: "assets/images/security.png",
        w: 0.4,
        h: 0.4,
        type: "contain",
        x: "55%",
        y: "42%",
    });
    slide.addText(
        [
            { text: "Sicurezza ", options: { breakLine: false } },
            { text: "Preventiva", options: { bold: true } },
        ],
        {
            ...baseOptions.text,
            x: "60%",
            y: "43%",
            color: colors.white,
            fontSize: 12,
            align: "left",
        }
    );
    slide.addText("1.", {
        ...baseOptions.text,
        x: "60%",
        y: "48.6%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText(
        [
            { text: "Security testing ", options: { breakLine: false, bold: true } },
            { text: "del perimetro esterno", options: { breakLine: false } },
        ],
        {
            x: "62%",
            y: "49%",
            w: 3.5,
            h: 2,
            color: colors.white,
            fontSize: 8,
            align: "left",
            valign: "top",
            lineSpacing: 10,
        }
    );
    slide.addText("2.", {
        ...baseOptions.text,
        x: "60%",
        y: "51.6%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText(
        [
            { text: "Network Scan ", options: { breakLine: false, bold: true } },
            { text: "del perimetro interno", options: { breakLine: false } },
        ],
        {
            x: "62%",
            y: "52%",
            w: 3.5,
            h: 2,
            color: colors.white,
            fontSize: 8,
            align: "left",
            valign: "top",
            lineSpacing: 10,
        }
    );
    slide.addText("3.", {
        ...baseOptions.text,
        x: "60%",
        y: "54.6%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText(
        [
            { text: "Bonifica delle credenziali oggetto di ", options: { breakLine: false } },
            { text: "Data Leaks", options: { breakLine: false, bold: true } },
        ],
        {
            x: "62%",
            y: "55%",
            w: 3.5,
            h: 2,
            color: colors.white,
            fontSize: 8,
            align: "left",
            valign: "top",
            lineSpacing: 10,
        }
    );
    slide.addText("4.", {
        ...baseOptions.text,
        x: "60%",
        y: "57.6%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText(
        [
            { text: "Formazione ", options: { breakLine: false, bold: true } },
            { text: "e Awareness", options: { breakLine: false } },
        ],
        {
            x: "62%",
            y: "58%",
            w: 3.5,
            h: 2,
            color: colors.white,
            fontSize: 8,
            align: "left",
            valign: "top",
            lineSpacing: 10,
        }
    );
    slide.addText("5.", {
        ...baseOptions.text,
        x: "60%",
        y: "60.6%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText(
        [
            { text: "Phishing e ", options: { breakLine: false } },
            { text: "Simulation Attack", options: { breakLine: false, bold: true } },
        ],
        {
            x: "62%",
            y: "61%",
            w: 3.5,
            h: 2,
            color: colors.white,
            fontSize: 8,
            align: "left",
            valign: "top",
            lineSpacing: 10,
        }
    );
    slide.addText("6.", {
        ...baseOptions.text,
        x: "60%",
        y: "63.6%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText([{ text: "Cyber Security Checkup", options: { breakLine: false, bold: true } }], {
        x: "62%",
        y: "64%",
        w: 3.5,
        h: 2,
        color: colors.white,
        fontSize: 8,
        align: "left",
        valign: "top",
        lineSpacing: 10,
    });

    slide.addImage({
        path: "assets/images/proactive.png",
        w: 0.27,
        h: 0.4,
        type: "contain",
        x: "55%",
        y: "70%",
    });
    slide.addText(
        [
            { text: "Sicurezza ", options: { breakLine: false } },
            { text: "Proattiva", options: { bold: true } },
        ],
        {
            ...baseOptions.text,
            x: "60%",
            y: "72%",
            color: colors.white,
            fontSize: 12,
            align: "left",
        }
    );
    slide.addText("1.", {
        ...baseOptions.text,
        x: "60%",
        y: "77.6%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText(
        [
            { text: "Attività di ", options: { breakLine: false } },
            { text: "SoC as a Service", options: { breakLine: false, bold: true } },
        ],
        {
            x: "62%",
            y: "78%",
            w: 3.5,
            h: 2,
            color: colors.white,
            fontSize: 8,
            align: "left",
            valign: "top",
            lineSpacing: 10,
        }
    );
    slide.addText("2.", {
        ...baseOptions.text,
        x: "60%",
        y: "80.6%",
        color: colors.white,
        fontSize: 8,
        align: "left",
    });
    slide.addText(
        [
            { text: "Servizio di ", options: { breakLine: false } },
            { text: "SOS Attacco Informatico", options: { breakLine: false, bold: true } },
        ],
        {
            x: "62%",
            y: "81%",
            w: 3.5,
            h: 2,
            color: colors.white,
            fontSize: 8,
            align: "left",
            valign: "top",
            lineSpacing: 10,
        }
    );

    slide.addText("swascan.com", swascanSite);
    slide.addText("info@swascan.com", swascanMail);
};