<template>
    <div class="p-grid">
        <div class="p-col-12 p-d-flex p-jc-center p-pt-3">
            <img
                :alt="$t('pages.new_target.sonic_wall_logo')"
                src="assets/images/sonic_wall_logo.png"
            />
        </div>
        <div class="p-col-12">
            <p>{{ $t('pages.new_target.sonic_wall_description') }}</p>
            <ul>
                <li>{{ $t('pages.new_target.sonic_wall_user') }}</li>
                <li>{{ $t('pages.new_target.sonic_wall_password') }}</li>
                <li>{{ $t('pages.new_target.sonic_wall_domain') }}</li>
                <li>{{ $t('pages.new_target.sonic_wall_address') }}</li>
            </ul>
            <p>{{ $t('pages.new_target.sonic_wall_tunneling') }}</p>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="p-fluid p-mt-3">
                <div class="p-field">
                    <label for="user">{{ $t('pages.new_target.user') }}</label>
                    <InputText
                        id="user"
                        type="text"
                        v-model="$parent.extra.user"
                        :placeholder="$t('pages.new_target.user')"
                    />
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="p-fluid p-mt-3">
                <div class="p-field">
                    <label for="password">{{ $t('pages.new_target.password') }}</label>
                    <InputText
                        id="password"
                        type="password"
                        v-model="$parent.extra.password"
                        :placeholder="$t('pages.new_target.password')"
                    />
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="p-fluid p-mt-3">
                <div class="p-field">
                    <label for="address">{{ $t('pages.new_target.address') }}</label>
                    <InputText
                        id="address"
                        type="text"
                        v-model="$parent.extra.address"
                        :placeholder="$t('pages.new_target.address')"
                    />
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="p-fluid p-mt-3">
                <div class="p-field">
                    <label for="domain">{{ $t('pages.new_target.domain') }}</label>
                    <InputText
                        id="domain"
                        type="text"
                        v-model="$parent.extra.domain"
                        :placeholder="$t('pages.new_target.domain')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
