import moment from "moment";

export class DateParser {
    static setLocale(locale) {
        moment.locale(locale);
    }

    static parseDate(date, format) {
        const d = new Date(date);
        if (!format) {
            format = "DD.MM.YYYY - HH:mm";
        }
        return moment(d).format(format);
    }
}
