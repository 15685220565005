import { DateParser, ENGINE_DESCRIPTIONS, Engine, parseEngineByUrl } from "../utilities";

export class License {
    id;
    api;
    expires_on;
    scans_done;
    scans_max;
    user_id;
    user_role;
    binary_edge_ip_score;
    full_mode;
    name;
    available;

    constructor(data = {}) {
        this.id = data.id;
        this.api = data.api;
        if (this.api && this.api.charAt(0) != "/") this.api = "/" + this.api;
        this.expires_on = data.expires_on;
        this.user_id = data.user_id;
        this.scans_done = data.scans_done;
        this.scans_max = data.scans_max;
        this.user_role = data.user_role;
        this.full_mode = data.full_mode;
        this.binary_edge_ip_score = data.binary_edge_ip_score;
        this.name = data.name;
        this.available = data.available;
    }

    toJSON() {
        return {
            id: this.id,
            api: this.api,
            expires_on: this.expires_on,
            scans_done: this.scans_done,
            scans_max: this.scans_max,
            user_id: this.user_id,
            user_role: this.user_role,
            binary_edge_ip_score: this.binary_edge_ip_score,
            full_mode: this.full_mode,
            engine: this.engine,
            engine_description: this.engine_description,
            expiration: this.expiration,
            remaining_scans: this.remaining_scans,
            icon: this.icon,
            color: this.color,
        };
    }

    get engine() {
        const array = parseEngineByUrl(this.api);
        return array[1].toLowerCase();
    }

    get engine_description() {
        const array = parseEngineByUrl(this.api);
        return ENGINE_DESCRIPTIONS[array[1]];
    }

    get expiration() {
        if (!this.expires_on) return "-";

        return DateParser.parseDate(this.expires_on, "DD.MM.YYYY HH:mm");
    }

    get remaining_scans() {
        if (this.scans_max === undefined || this.scans_max === null || this.scans_done === undefined || this.scans_done === null) {
            return 0;
        }
        return this.scans_max - this.scans_done;
    }

    get chart_percentage_usage() {
        if (this.engine === "ir") return [0];
        const value = Math.round((this.scans_done * 100) / this.scans_max);
        if (isNaN(value)) {
            return [100];
        }
        return [value];
    }

    get icon() {
        let icon;
        switch (this.engine) {
            case Engine.DTI:
            case Engine.DTI_LITE:
            case Engine.RRI:
                icon = "search";
                break;
            case Engine.NETWORK_SCAN:
                icon = "eye";
                break;
            case Engine.WEB_APP_SCAN:
                icon = "bug";
                break;
            case Engine.CODE_REVIEW:
                icon = "file-code";
                break;
            case Engine.GDPR_ASSESSMENT:
                icon = "check-square";
                break;
            case Engine.ICT_ASSESSMENT:
                icon = "tasks";
                break;
            case Engine.CARD_PROTECTION:
                icon = "credit-card";
                break;
            default:
                break;
        }

        return icon;
    }

    get color() {
        let color;
        switch (this.engine) {
            case Engine.DTI:
            case Engine.DTI_LITE:
            case Engine.RRI:
                color = "dark-blue";
                break;
            case Engine.NETWORK_SCAN:
                color = "yellow";
                break;
            case Engine.WEB_APP_SCAN:
                color = "red";
                break;
            case Engine.CODE_REVIEW:
                color = "green";
                break;
            case Engine.GDPR_ASSESSMENT:
                color = "blue";
                break;
            case Engine.ICT_ASSESSMENT:
                color = "light-blue";
                break;
            case Engine.CARD_PROTECTION:
                color = "red";
                break;
            default:
                break;
        }

        return color;
    }
}
