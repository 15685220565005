<template>
    <Dialog
        :visible="showModal"
        v-if="target != null"
        :modal="true"
        :closable="false"
        :style="{ width: '90vw' }"
        ref="taskTestModal"
        @show="onShow"
    >
        <template #header>
            <h3>{{ target.name }}</h3>
        </template>

        <div class="p-grid">
            <div v-if="loading" class="p-col-12 p-text-center">
                <ProgressSpinner style="width: 50px; height: 50px" />
            </div>

            <div class="p-col-12">
                <Terminal
                    ref="processTerminal"
                    welcomeMessage="Process execution..."
                    prompt=""
                    :disabled="true"
                />
            </div>
        </div>

        <template #footer>
            <Button
                :label="$t('general.close')"
                icon="pi pi-times"
                class="p-button-secondary"
                :disabled="loading"
                @click="onHide"
            />
        </template>
    </Dialog>
</template>


<script>
import { useStore } from "../../store";
import { TaskService } from "../../services";

export default {
    name: "TargetTestModal",
    setup() {
        return {
            state: useStore().state,
            setState: useStore().setState,
        };
    },
    taskService: null,
    created() {
        this.taskService = new TaskService();
    },
    props: {
        target: {
            type: Object,
            default: null,
        },
        showModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: true,
            promptMessage: "",
        };
    },
    mounted() {},
    computed: {},
    methods: {
        onShow() {
            setTimeout(() => {
                // this.executeTest();
            }, 500);
        },
        onHide() {
            this.$parent.hideExecuteTest();
        },
        executeTest() {
            // INIT
            let urls = this.target.target
                .replace("http://", "")
                .replace("https://", "")
                .split(",");
            let body = {
                targets: urls,
            };

            // FIRST
            this.$refs.processTerminal.responseListener({
                text: "POST:",
                response: this.target.engine,
            });
            this.$refs.processTerminal.responseListener({
                text: "BODY:",
                response: JSON.stringify(body),
            });

            // EXEC
            this.taskService
                .post(this.target.engine, body)
                .then((response) => {
                    let data = response.data;
                    this.$refs.processTerminal.responseListener({
                        text: "STATUS:",
                        response: `${response.status} ${response.statusText}`,
                    });
                    this.$refs.processTerminal.responseListener({
                        text: "HEADERS:",
                        response: JSON.stringify(response.headers),
                    });
                    this.$refs.processTerminal.responseListener({
                        text: "DATA:",
                        response: JSON.stringify(data),
                    });
                    this.$refs.processTerminal.responseListener({
                        text: "MESSAGE:",
                        response: data.msg,
                    });
                    this.$refs.processTerminal.responseListener({
                        text: "TASK ID:",
                        response: data.task_id,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        this.$refs.processTerminal.responseListener({
                            text: "STATUS:",
                            response: `${error.response.status} ${error.response.statusText}`,
                        });
                        this.$refs.processTerminal.responseListener({
                            text: "HEADERS:",
                            response: JSON.stringify(error.response.headers),
                        });
                        this.$refs.processTerminal.responseListener({
                            text: "DATA:",
                            response: JSON.stringify(error.response.data),
                        });
                    } else if (error.request) {
                        this.$refs.processTerminal.responseListener({
                            text: "REQUEST:",
                            response: JSON.stringify(error.request),
                        });
                    } else {
                        this.$refs.processTerminal.responseListener({
                            text: "ERROR:",
                            response: error.message,
                        });
                    }
                })
                .then(() => {
                    this.loading = false;
                });
        },
    },
};
</script>