import { getQuery } from "./get_query";

export class WebAppQuery {
  static summary = `query { webapp_summary {
        license_info {
            name
            api
            scans_max
            full_mode
            binary_edge_ip_score
            id
            user_id
            user_role
            scans_done
            expires_on
        }
        alerts_summary { High Medium Low Informational }
        alerts_summary_percentage { High Medium Low Informational }
        alerts_summary_grouped { High Medium Low Informational }
        alerts_summary_grouped_percentage { High Medium Low Informational }
        impacts_summary { confidentiality integrity availability non_repudiation access_control other }
        impacts_summary_percentage { confidentiality integrity availability non_repudiation access_control other }
        likelihood_of_exploit
        hosts
        urls
        alert_threshold
        attack_strength
        target
        start_date
        end_date
        authenticated
    }
}`;

static sslSummary = `query {
  testssl_summary {
      _id
      target_host
      port
      start_time
      scan_time
      vulnerabilities_summary { total critical high medium low info ok none }
  } 
}`;

  static alerts(first = 20, skip = 0, { sort_by = "risk_value", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              webapp_alerts {
                items(${query}) {
                  _id
                  sourceid
                  pluginId
                  cweid
                  confidence
                  wascid
                  description
                  tags
                  reference
                  solution
                  alert
                  name
                  alertRef
                  count
                  risk
                  risk_value
                  confidence_value
                  impacts { scopes impacts note }
                  likelihood_of_exploit
                  data { _id guid message_id method url evidence evidence_location evidence_snippet attack param other }
                  applicable_platforms {
                      languages { class_name name prevalence }
                      technologies { class_name name prevalence }
                  }
                  cwe_name
                  cwe_type
                  cwe_description
                  is_owasp
                  owasp { id name type }
                  alert_type
                }
                total
              }
            }`;
  }

  static messages(first = 20, skip = 0, { sort_by = "timestamp", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              webapp_messages {
                items(${query}) {
                  _id
                  id
                  note
                  rtt
                  type
                  timestamp
                  tags
                  request_headers
                  response_headers
                  request_body
                  cookie_params
                  response_body
                }
                total
              }
            }`;
  }

  static messageById(id) {
    let query = getQuery(20, 0, { search: id, exactly: true });
    return `query {
              webapp_messages {
                items(${query}) {
                  _id
                  id
                  note
                  rtt
                  type
                  timestamp
                  tags
                  request_headers
                  response_headers
                  request_body
                  cookie_params
                  response_body
                }
                total
              }
            }`;
  }

  static waf(first = 20, skip = 0, { sort_by = "url", order = 1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              waf {
                items(${query}) {
                  _id
                  url
                  detected
                  firewall
                  manufacturer
                }
                total
              }
            }`;
  }

  static sslScans(first = 20, skip = 0, { sort_by = "risk", order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              testssl_scan_results {
                items(${query}) {
                  _id
                  ip
                  service
                  id
                  severity
                  finding
                  cve
                  cwe
                  risk
                  vulnerability_type
                }
                total
              }
            }`;
  }

  static whatweb(first = 20, skip = 0, { sort_by = null, order = -1, search = null } = {}) {
    let query = getQuery(first, skip, { sort_by, order, search });
    return `query {
              whatweb {
                items(${query}) {
                  _id
                  target
                  http_status
                  request_config
                  plugins
                }
                total
              }
            }`;
  }
}
