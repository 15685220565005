<template>
    <div class="layout-topbar">
        <div class="left-container">
            <div class="row-container">
                <button class="p-link layout-menu-button" @click="onMenuToggle">
                    <span class="pi pi-bars"></span>
                </button>
                <div class="info-message">
                    <strong>
                        The First Cloud Cyber Security & GDPR Platform
                    </strong>
                    <span class="p-text-italic"
                        >&nbsp; in collaboration with &nbsp;
                    </span>
                    <strong>Cisco</strong>
                </div>
            </div>
        </div>
        <div class="right-container">
            <Menubar :model="menuItems" />
        </div>
    </div>
</template>

<script>
import { RouterService } from "./services";
import { setAuthenticated } from "./store";
import { useStore } from "./store";
import { ENV_VARS } from "./env_vars";
import { Engine } from "./utilities";

export default {
    data() {
        return {
            state: useStore().state,
            setState: useStore().setState,
            username: RouterService.keycloak
                ? RouterService.keycloak.tokenParsed.preferred_username
                : RouterService.decoded &&
                  RouterService.decoded.preferred_username,
        };
    },
    computed: {
        langs() {
            const list = [];
            for (const lang of this.$i18n.availableLocales) {
                list.push({
                    id: lang,
                    label: lang,
                    command: () => this.setLocale(lang),
                });
            }

            return list;
        },
        currentLang() {
            return this.$i18n.locale || "en";
        },
        menuItems() {
            const items = [
                {
                    id: 1,
                    label: this.currentLang,
                    icon: "pi pi-fw pi-flag",
                    items: [...this.langs],
                },
                {
                    id: 2,
                    label: this.username,
                    icon: "pi pi-fw pi-user",
                    items: [
                        {
                            id: "logout",
                            label: "Logout",
                            icon: "pi pi-fw pi-power-off",
                            class: "no-background",
                            command: (event, item) => {
                                this.logout();
                            },
                        },
                    ],
                },
            ];
            if (this.permittedIr && this.hasIrLicense) {
                items.push({
                    id: 3,
                    label: this.$t('general.under_attack'),
                    class: "red-menu",
                    command: (event, item) => {
                        this.$router.push({ name: 'ir-form' })
                    },
                });
            }

            return items;
        },
        permittedIr () {
            const permittedLicenses = ENV_VARS.VUE_APP_PERMITTED_LICENSES.split(",");
            const permittedIr = permittedLicenses.indexOf('ir') > -1;
            return permittedIr;
        },
        irLicense() {
            return this.state.licenses.find(
                (el) => el.engine === Engine.INCIDENT_RESPONSE
            )
        },
        hasIrLicense() {
            return !!this.irLicense
        },
    },
    methods: {
        onMenuToggle(event) {
            this.$emit("menu-toggle", event);
        },
        setLocale(lang) {
            this.$i18n.locale = lang;
        },
        goToUserInfo() {},
        async logout() {
            localStorage.clear();
            sessionStorage.clear();
            await RouterService.logout();

            const standalone = window.standalone;
            const forceKeycloak = window.forceKeycloak;
            const redirectUrl = forceKeycloak
                ? window.location.origin + "?forceKeycloak=true"
                : `${ENV_VARS.VUE_APP_OLD_PLATFORM}accounts/login/?next=/`;

            if (forceKeycloak) {
                return;
            } else if (!standalone) {
                return (window.location.href = redirectUrl);
            } else {
                setTimeout(() => {
                    setAuthenticated(false);
                    if (standalone) {
                        this.$router.replace({ path: "/" });
                    }
                }, 1000);
            }
        },
    },
};
</script>

<style lang="scss">
.no-background {
    &:hover {
        background-color: #1d1d1d;
    }
    .p-menuitem-link {
        background-color: transparent !important;
    }
}
.red-menu {
    a.p-menuitem-link {
        background-color: #cd1619;
        border-radius: 8px !important;

        &:hover {
            background-color: #8c182a !important;
        }

        .p-menuitem-icon {
            margin-right: 0px !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.user-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.left-container {
    flex: 1;

    .row-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .info-message {
            padding-left: 2em;
            padding-right: 2em;
            flex: 1;
            display: flex;
            justify-content: center;
            justify-content: center;
        }
    }
}

.layout-topbar {
    display: flex;
    padding: 0 0.7em;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .layout-topbar-item-text {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .layout-topbar {
        height: 80px;
    }
}
</style>