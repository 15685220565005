import { ENV_VARS } from "@/env_vars";
import { BaseService } from "./BaseService";
import { Routes } from "./RouterService/Routes";

export class CardProtectionService extends BaseService {
  constructor() {
    super();
    this.BASE_URL = ENV_VARS.VUE_APP_REST_BASE_URL;
    this.BASE_API = Routes.CARD_PROTECTION;
  }
}
