<template>
    <div class="p-terminal p-component" @click="onClick">
        <div v-if="welcomeMessage">{{welcomeMessage}}</div>
        <div class="p-terminal-content">
            <div v-for="(command,i) of commands" :key="command.response + '_' + i">
                <span class="p-terminal-prompt" v-if="command.text">{{command.text}}</span>&nbsp;&nbsp;
                <div class="p-terminal-response" v-if="command.response">{{command.response}}</div>
            </div>
        </div>
        <div class="p-terminal-prompt-container">
            <span class="p-terminal-prompt">{{prompt}}</span>
            <input ref="input" type="text" 
                v-model="commandText" 
                class="p-terminal-input" 
                autocomplete="off" 
                @keydown="onKeydown" 
                :disabled="disabled">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        welcomeMessage: {
            type: String,
            default: null
        },
        prompt: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fakeCommands: {
            type: Array,
            default: function() {
                return [];
            },
        },
    },
    data() {
        return {
            commandText: null,
            commands: []
        }
    },
    mounted() {
        this.$refs.input.focus();
        this.fakeCommands.forEach(el => {
            this.commands.push({text: el.text, response: el.response});
        })
    },
    updated() {
        this.$el.scrollTop = this.$el.scrollHeight;
    },
    beforeUnmount() {
    },
    methods: {
        onClick() {
            this.$refs.input.focus();
        },
        onKeydown(event) {
            if (event.keyCode === 13 && this.commandText) {
                this.commands.push({text: this.commandText});
                this.commandText = '';
            }
        },
        responseListener(response) {
            this.commands.push(response);
        }
    }
}
</script>

<style>
.p-terminal {
    min-height: 18rem;
    overflow: auto;
}

.p-terminal .p-terminal-prompt {
    color: #ffd54f;
    align-self: flex-start;
}

.p-terminal-content > div {
    display: flex;
    align-items: center;
}

.p-terminal-prompt-container {
    display: flex;
    align-items: center;
}

.p-terminal-input {
    flex: 1 1 auto;
    border: 0 none;
    background-color: transparent;
    color: inherit;
    padding: 0;
    outline: 0 none;
}

.p-terminal-input::-ms-clear {
    display: none;
}
</style>